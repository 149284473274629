import {Component, inject} from '@angular/core';
import {ImportFlowService} from "@services/imports/import-flow.service";
import {TableColumnsSetting} from "@/interfaces/table-setting";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {CrossF8DataSource} from "@components/new-table/data-sources/cross-f8.dataSource";
import {CrossF8SettingsTable} from "@/table-settings/imports/cross-f8-settings-table";

@Component({
  selector: 'app-cross-f8-table',
  templateUrl: './cross-f8-table.component.html',
  styleUrls: ['./cross-f8-table.component.scss']
})
export class CrossF8TableComponent {

  displayedColumns: TableColumnsSetting<any>

  dataSource = new CrossF8DataSource(this.importFlowService);

  toastr = inject(ToastrService);
  dialog = inject(MatDialog);

  reloadTrigger$ = new Subject<any>();

  constructor(private importFlowService: ImportFlowService) {
    this.displayedColumns = CrossF8SettingsTable.getColumnsSetting();
  }
}
