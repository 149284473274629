import {OptionSelect} from "@/models/option-select.model";

export class StaticsDataComponents {
  private static categories: OptionSelect[] = [];

  public static setCategories(categories: OptionSelect[]) {
    this.categories = categories;
  }
  
  public static getCategories(): OptionSelect[] {
    return this.categories;
  }
}
