import {FormSetting} from "@/form-settings/form-setting";

export class AccountDataPracticeFormSettings extends FormSetting {

  protected static classToCall: string

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static getMeta(): Array<any> {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "nrContoCLPropAcq",
            "controlType": "textinput",
            "label": "Nr Conto CL prop. d'acq.",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true,
            "default": ""
          },
          {
            "classToUse": this.classToCall,
            "key": "nrContoProprPropAcq",
            "controlType": "textinput",
            "label": "Nr Conto proprietario prop. d'acq.",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true,
            "default": ""
          }
        ]
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "numeroFattura",
            "controlType": "textinput",
            "label": "Numero fattura",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true,
            "default": ""
          },
          {
            "classToUse": this.classToCall,
            "key": "dataFattura",
            "controlType": "date",
            "label": "Data fattura",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true,
            "default": ""
          },
          {
            "classToUse": this.classToCall,
            "key": "importoFattura",
            "controlType": "number",
            "label": "Importo fattura",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true,
            "default": ""
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "destinatarioFattura",
            "controlType": "textinput",
            "label": "Intestazione fattura",
            "order": 1,
            "class": "lg:w-12/12 w-full",
            "disabled": true,
            "default": ""
          }
        ],
        "order": 2
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "prezzoAcquistoTotaleVetturaNetto",
            "controlType": "number",
            "label": "Prezzo di acquisto netto",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true,
            "default": ""
          },
          {
            "classToUse": this.classToCall,
            "key": "speseAcquistoTrasporto",
            "controlType": "number",
            "label": "Spese di acquisto e trasporto",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true,
            "default": ""
          },
          {
            "classToUse": this.classToCall,
            "key": "speseVariePubblicita",
            "controlType": "number",
            "label": "Spese varie pubblicita",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true,
            "default": ""
          },
          {
            "classToUse": this.classToCall,
            "key": "totaleCostoVenduto",
            "controlType": "number",
            "label": "Totale costo del venduto",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true,
            "default": ""
          },
          {
            "classToUse": this.classToCall,
            "key": "prezzoListino",
            "controlType": "number",
            "label": "Prezzo di listino",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true,
            "default": ""
          },
          {
            "classToUse": this.classToCall,
            "key": "percCostoVenduto",
            "controlType": "number",
            "label": "% Costo del venduto",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true,
            "default": ""
          },
        ],
        "order": 1
      },
    ]
  }
}
