import {FormSetting} from "@/form-settings/form-setting";

export class RentalPracticeFormSetting extends FormSetting {

  protected static classToCall: string

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static getMeta(): Array<any> {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "codiceNoleggio",
            "label": "Codice Noleggio",
            "controlType": "textinput",
            "class": "lg:w-4/12 w-full",
            "order": 1,
            "required": true
          },
          {
            "classToUse": this.classToCall,
            "key": "descrizione",
            "label": "Descrizione",
            "controlType": "textinput",
            "class": "lg:w-4/12 w-full",
            "order": 1,
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "enabled",
            "controlType": "slide-toggle",
            "label": "Abilitato",
            "default": true,
            "order": 1,
            "class": "lg:w-4/12 w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "id",
            "controlType": "hidden",
            "label": "",
            "order": 1,
          },
          {
            "classToUse": this.classToCall,
            "key": "creator",
            "controlType": "hidden",
            "label": "",
            "order": 1,
          },
        ],
        "order": 1
      },
    ]
  }
}
