import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {VehicleAccessoryModel} from "@/models/tables/vehicle-accessory.model";

@Injectable({
  providedIn: 'root'
})
export class VehicleAccessoryService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/vehicleAccessories`);
  }

  deleteVehicleAccessory(vehicleAccessoryId: number) {
    return this.http.delete(this.baseUrl + `/tables/vehicleAccessories/${vehicleAccessoryId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteVehicleAccessory')

      return response;
    }));
  }

  saveVehicleAccessory(vehicleAccessory: VehicleAccessoryModel) {
    return this.http.post(this.baseUrl + `/tables/vehicleAccessories/save`, vehicleAccessory).pipe(map((response: any) => {
      LoggerService.log(response, 'saveVehicleAccessory')

      return response;
    }));
  }

  getAllModels(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/vehicleAccessories/models`);
  }
}
