<div [formGroup]="form" [class]="class">
  <mat-label class="label-form">{{meta.label}}:</mat-label>
  <mat-form-field appearance="fill" class="w-full additional-border-input" [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
    <mat-label>Inserisci la tua password</mat-label>
    <input matInput
           [formControlName]="meta.key"
           (change)="customActionInput($event)"
           [type]="hide ? 'password' : 'text'">
    <button mat-icon-button matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
    </button>
  </mat-form-field>
  <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>
</div>
