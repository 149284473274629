import {Component, Input} from '@angular/core';
import {ControlBase} from "@components/dynamic-form/control-base";
import {AbstractControl, FormControl} from "@angular/forms";
import {Registry} from "@/registry.service";

@Component({
  selector: 'app-box-errors',
  templateUrl: './box-errors.component.html',
  styleUrls: ['./box-errors.component.scss']
})
export class BoxErrorsComponent {
  @Input() meta!: ControlBase
  @Input() control: AbstractControl<any>
  @Input() submitted: boolean
  registry: Registry = Registry.getInstance();

  constructor() {
    this.control = new FormControl('')

    this.registry.set('submittedForm', false)
  }

}
