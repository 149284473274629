import {
  ButtonDetailVehicleAccessoryComponent
} from "@components/button/tables/vehicle-accessory/button-modify-vehicle-accessory.component";
import {
  ButtonDeleteVehicleAccessoryComponent
} from "@components/button/tables/vehicle-accessory/button-delete-vehicle-accessory.component";
import {ModelBrandRendererComponent} from "@components/table-column-renderer/model-brand-renderer.component";

export class VehicleAccessorySettings {

  public static gridSettings() {
    return {
      actions: false,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager:
        {
          display: true,
          perPage: 25
        },
      columns: {
        detailButton: {
          type: 'custom',
          renderComponent: ButtonDetailVehicleAccessoryComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        deleteButton: {
          type: 'custom',
          renderComponent: ButtonDeleteVehicleAccessoryComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        id: {
          title: 'Id',
          width: '70px'
        },
        anagSiglaModello: {
          title: 'Modello',
          type: 'custom',
          renderComponent: ModelBrandRendererComponent,
          width: '120px',
          filter: {
            type: 'list',
            config: {
              list: [],
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            if (cell.id == search) {
              return true;
            } else {
              return false;
            }
          },
        },
        codiceAccessorio: {
          title: 'Codice'
        },
        descrizioneAccessorio: {
          title: 'Descrizione accessorio'
        },
        prezzo: {
          title: 'Importo',
          type: 'html',
          valuePrepareFunction: (data) => {
            return '<p class="cell_right">' + Intl.NumberFormat('it-IT', {
              style: 'currency',
              currency: 'EUR'
            }).format(data) + '</p>';
          },
          filter: false
        },
        provvigione: {
          title: 'Provvigione',
          type: 'html',
          valuePrepareFunction: (data) => {
            if(data != null) {
              return '<p class="cell_right">' + Intl.NumberFormat('it-IT', {
                style: 'currency',
                currency: 'EUR'
              }).format(data) + '</p>';
            }
            else {
              return null;
            }
          },
          filter: false
        },
        enabled: {
          title: 'Abilitato',
          width: '130px',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
      },
      delete: null
    };
  }
}
