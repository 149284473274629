import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {DialogBigBuyersComponent} from "@pages/tables/big-buyers/dialog-big-buyers/dialog-big-buyers.component";
import {BigBuyersModel} from "@/models/tables/big-buyers.model";

@Component({
  selector: 'app-button-new-big-buyers',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonNewBigBuyersComponent extends ButtonComponent {
  private dialogOpened: MatDialogRef<any>
  faIcon = faPenToSquare;

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'ml-2'
    this.content = 'Nuovo Grande Acquirente'
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogBigBuyersComponent, {
      data: new BigBuyersModel(),
    });

    Registry.getInstance().set('DialogBigBuyersComponentNew', this.dialogOpened)
  }

}
