import {Component, inject} from '@angular/core';
import {TableColumnsSetting} from "@/interfaces/table-setting";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {ImportFlowService} from "@services/imports/import-flow.service";
import {ImportStatusTableSettings} from "@/table-settings/imports/import-status-table-settings";
import {ImportsStatusDataSource} from "@components/new-table/data-sources/imports-status.dataSource";

@Component({
  selector: 'app-import-status-table',
  templateUrl: './import-status-table.component.html',
  styleUrls: ['./import-status-table.component.scss']
})
export class ImportStatusTableComponent {

  displayedColumns: TableColumnsSetting<any>
  dataSource = new ImportsStatusDataSource(this.importFlowService);
  toastr = inject(ToastrService);
  dialog = inject(MatDialog);
  reloadTrigger$ = new Subject<any>();

  constructor(private importFlowService: ImportFlowService) {
    this.displayedColumns = ImportStatusTableSettings.getColumnsSetting();
  }

}
