import {Component, Input} from '@angular/core';
import {ControlBase} from "@components/dynamic-form/control-base";
import {FormGroup} from "@angular/forms";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";

@Component({
  selector: 'app-hidden-input',
  templateUrl: './hidden-input.component.html',
  styleUrls: ['./hidden-input.component.scss']
})
export class HiddenInputComponent extends InputFieldComponent {
  @Input() meta!: ControlBase
  @Input() form!: FormGroup
}
