<div [formGroup]="form">
    <div *ngIf="indexRow !== null" [formArrayName]="keyformArrayName">
        <div [formGroupName]="indexRow">
            <mat-label class="label-form">{{meta.label}}:</mat-label>
            <mat-form-field appearance="outline" class="w-full"
                            [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
                <mat-label>{{meta.label}}</mat-label>
                <input type="text" placeholder="Inserisci {{meta.label}}"
                       matInput currencyMask
                       [required]="isControlRequired($any(form.get(keyformArrayName)).at(indexRow).get(meta.key))"
                       [disabled]="is_disabled()"
                       (input)="dispatchChangeEvent(hiddenElemet)"
                >
                <input #hiddenElemet type="hidden" [formControlName]="meta.key"
                       (change)="customActionInput($event, indexRow)"/>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="indexRow === null">
        <mat-label class="label-form">{{meta.label}}:</mat-label>
        <mat-form-field appearance="outline" class="w-full"
                        [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
            <mat-label>{{meta.label}}</mat-label>
            <input type="text" placeholder="Inserisci {{meta.label}}"
                   matInput currencyMask
                   [required]="isControlRequired(form.get(meta.key))"
                   [disabled]="is_disabled()"
                   (input)="dispatchChangeEvent(hiddenElemet)"
            >
            <input #hiddenElemet type="hidden" [formControlName]="meta.key"
                   (change)="customActionInput($event, indexRow)"/>
        </mat-form-field>
    </div>
    <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>
</div>
