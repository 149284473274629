import {DataSourceBaseNew} from "@components/new-table/data-source-base-new.dataSource";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {TableDataApiParams} from "@services/practices.service";
import {Injectable} from "@angular/core";
import {CashMovementsAnalisisService} from "@services/cash-movements.analisis.service";

@Injectable({
  providedIn: 'root'
})
export class CashMovementsAnalisisDataSource extends DataSourceBaseNew<TablePracticeModel> {
  constructor(private cashMovementsAnalisisService: CashMovementsAnalisisService) {
    super();
  }

  loadData(params?: TableDataApiParams): void {
    this._isLoading$.next(true);

    this.cashMovementsAnalisisService.getDataForTable(params).subscribe(data => {
      this.elements$.next(data.content);
      this._totalElements$.next(data.totalElements);
      this._isLoading$.next(false);
    })
  }
}
