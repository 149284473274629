import {Component, inject, Input, OnInit} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {PracticesService} from "@services/practices.service";
import {Subject} from "rxjs";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";

@Component({
  selector: 'app-button-recalculate-progression-month',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss'],
})
export class ButtonRecalculateProgressionMonthComponent extends ButtonComponent implements OnInit {

  @Input() triggerDate$ = new Subject<any>();
  @Input() reloadTrigger$ = new Subject<any>();
  private dialog = inject(MatDialog);
  private year: string;
  private month: string;

  constructor(protected router: Router, protected toastr: ToastrService, private practiceService: PracticesService) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'ml-2'
    this.content = 'Ricalcola progressivi mese'
  }

  ngOnInit(): void {
    this.triggerDate$.subscribe({
      next: (objectDate) => {
        this.month = objectDate.month
        this.year = objectDate.year
      }
    });
  }

  public actionClick(): void {
    this.loading = true

    this.practiceService.recalculateDeliveryCardboard(this.year, this.month).subscribe(() => {
      let dialogRef = this.dialog.open(DialogPopUpComponent, {
        width: '400px',
        panelClass: 'custom-modalbox',
      });

      this.setupPopup(dialogRef)
      this.reloadTrigger$.next(1)
      this.loading = false
    });
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Ricalcolo completato!'
    dialogRef.componentInstance.content = "Ricalcola progressivi del mese effettuato corretamente."
    dialogRef.componentInstance.firstCTA = null
    dialogRef.componentInstance.secondCTA = null
    dialogRef.componentInstance.classTitle = 'text-center'
    dialogRef.componentInstance.classContent = 'text-center'
  }
}
