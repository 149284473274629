import {TableColumnsSetting} from "@/interfaces/table-setting";
import {TablePracticeModel} from "@/models/tables/table-practices.model";

export class BuyerPracticeInvoiceSettingsTable {
  public static getColumnsSetting(): TableColumnsSetting<keyof TablePracticeModel> {
    return [
      {
        name: "id",
        text: "Id",
      },
      {
        name: "pratica",
        text: "Pratica",
      },
      {
        name: "tipoDocumento",
        text: "Tipo Documento",

      },
      {
        name: "descrizione",
        text: "Descrizione",

      },
      {
        name: "descrizioneContoCoge",
        text: "Descrizione Conto Coge",

      },
      {
        name: "genProdPostingGroup",
        text: "genProdPostingGroup",

      },
      {
        name: "importo",
        text: "Importo",

      },
      {
        name: "importoInclusoIva",
        text: "Importo Iva Inc.",

      },
      {
        name: "motivoCode",
        text: "Codice Motivo",

      },
      {
        name: "numLinea",
        text: "Numero Linea",

      },
      {
        name: "numeroDocumento",
        text: "Numero Documento",

      },
    ];
  }
}
