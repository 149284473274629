<div class="alert {{mainClass}}" role="alert">
  <div class="flex">
    <div class="py-1">
      <fa-icon [icon]="faIcon" class="{{iconClass}}"></fa-icon>
    </div>
    <div>
      <p class="text-sm">
        {{message}}
      </p>
    </div>
  </div>
</div>
