<div class="container-box">
  <div class="flex">
    <div class="mx-4 my-2 w-full">
      <div class="table-container">
        <app-new-table
          [dataSource]="dataSource"
          [reloadTrigger$]="reloadTrigger$"
          [triggerDate$]="triggerDate$"
          [triggerSelector$]="triggerSelection$"
          [displayedColumns]="displayedColumns"
        ></app-new-table>
      </div>
  </div>
</div>
