import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {BigBuyersModel} from "@/models/tables/big-buyers.model";

@Injectable({
  providedIn: 'root'
})
export class BigBuyersService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/bigBuyers`);
  }

  getAllCustomerTypes(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/bigBuyers/customerTypes`);
  }

  getAllBrands(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/bigBuyers/brands`);
  }

  deleteBigBuyers(bigBuyersId: number) {
    return this.http.delete(this.baseUrl + `/tables/bigBuyers/${bigBuyersId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteBigBuyers')
      return response;
    }));
  }

  saveBigBuyers(bigBuyers: BigBuyersModel) {
    return this.http.post(this.baseUrl + `/tables/bigBuyers/save`, bigBuyers).pipe(map((response: any) => {
      LoggerService.log(response, 'saveBigBuyers')
      return response;
    }));
  }
}
