<h1 mat-dialog-title class="text-dark text-xl">Nuova Permuta</h1>
<div mat-dialog-content class="w-full">
  <h2 mat-dialog-title class="text-dark text-lg mb-2">
    {{data.pratica.tipoPratica}} -
    {{data.pratica.codicePratica}} -
    {{data.pratica.cliente}} -
    {{data.pratica.venditore.codiceVenditore}} -
    {{data.pratica.venditore.descrizione}}
  </h2>
  <app-exchange-form [data]="data"></app-exchange-form>
</div>
