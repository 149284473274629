import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Registry} from "@/registry.service";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {LoggerService} from "@services/logger.service";
import {Component} from "@angular/core";
import {ButtonComponent} from "@components/button/button.component";
import {ToastrService} from "ngx-toastr";
import {ContactSourceModel} from "@/models/tables/contact-source.model";
import {ContactSourceService} from "@services/tables/contact-source.service";

@Component({
    selector: 'app-button-contact-source-delete',
    templateUrl: '../../button.component.html',
    styleUrls: ['../../button.component.scss'],
})
export class ButtonDeleteContactSourceComponent extends ButtonComponent {

    private contactSourceModel: ContactSourceModel;
    private rowData: any;
    faIcon = faTrashCan;

    constructor(protected router: Router, public dialog: MatDialog, protected contactSourceService: ContactSourceService, protected toastr: ToastrService) {
        super(router, toastr);

        this.color = 'danger';
        this.additionalClass = 'py-0.5 px-2.5'
        this.showIcon = true;
        this.smallBtn = true;
    }

    ngOnInit() {
        this.contactSourceModel = <ContactSourceModel>this.rowData;
    }

    /**
     * Delete user
     */
    public actionClick(): void {
        let dialogRef = this.dialog.open(DialogPopUpComponent, {
            width: 'auto',
            panelClass: 'custom-modalbox',
        });

        this.setupPopup(dialogRef)

        dialogRef.afterClosed().subscribe(deleteSubAgent => {
            if (deleteSubAgent) {
                this.contactSourceService.deleteContactSource(this.contactSourceModel.id).subscribe({
                    next: () => {
                        this.toastr.success('Origine contatto cancellato correttamente!');

                        Registry.getInstance().get('ContactSourceComponent').loadContactSource()
                    },
                    error: (error) => {
                        LoggerService.error('deleteContactSource failed!')
                        LoggerService.error(error);

                        this.toastr.error(`Errore durante la cancellazione dell' origine contatto.`);
                    },
                    complete: () => {
                        LoggerService.log('Request done')
                    },
                });
            }
        });
    }

    private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
        dialogRef.componentInstance.title = 'Confermi di voler cancellare questa Origine Contatto?'
        dialogRef.componentInstance.content = "Attenzione una volta cancellata questa Origine Contatto non sarà più possibile recuperarla."
        dialogRef.componentInstance.firstCTA = "Si cancella"
        dialogRef.componentInstance.secondCTA = "Annulla operazione"
        dialogRef.componentInstance.closeResultFirst = true
        dialogRef.componentInstance.closeResultSecond = false
    }
}
