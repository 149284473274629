import {ButtonDetailBrandGoalsComponent} from "@components/button/brand-goals/button-modify-brand-goals.component";
import {
  BigbuyerBrandGoalsRendererComponent
} from "@components/table-column-renderer/bigbuyer-brand-goals-renderer.component";
import {BrandRendererComponent} from "@components/table-column-renderer/brand-renderer.component";

export class BrandGoalsSettings {

  public static gridSettings() {
    return {
      actions: false,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager:
        {
          display: true,
          perPage: 25
        },
      columns: {
        detailButton: {
          type: 'custom',
          renderComponent: ButtonDetailBrandGoalsComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        id: {
          title: 'Id',
          width: '70px',
          sortDirection: 'desc'
        },
        anno: {
          title: 'Anno'
        },
        mese: {
          title: 'Mese'
        },
        marca: {
          title: 'Marca',
          type: 'custom',
          renderComponent: BrandRendererComponent,
          width: '120px',
          filter: {
            type: 'list',
            config: {
              list: [],
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            return cell.id == search;
          },
        },
        obiettivoMeseRetail: {
          title: 'Obiettivo RETAIL'
        },
        obiettivoMeseNlt: {
          title: 'Obiettivo NLT'
        },
        grandiAcquirenti: {
          title: 'Grandi Acquirenti',
          type: 'custom',
          renderComponent: BigbuyerBrandGoalsRendererComponent,
          width: '120px',
          filterFunction(cell?: any, search?: string): boolean {
            if(cell.length > 0 && search)
              return cell.find(u => u.descrizione.toUpperCase().includes(search.toUpperCase()));
            else
              return false;
          },
        },
        retailIncludeNlt: {
          title: 'Retail include NLT',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
        visualizzaNltInCartoneConsegna: {
          title: 'visualizza NLT in Cartone Consegna',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
      },
      delete: null
    };
  }
}
