import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Registry} from "@/registry.service";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {LoggerService} from "@services/logger.service";
import {Component} from "@angular/core";
import {ButtonComponent} from "@components/button/button.component";
import {ToastrService} from "ngx-toastr";
import {RentalAgencyService} from "@services/tables/rental-agency.service";
import {RentalAgencyModel} from "@/models/tables/rental-agency.model";

@Component({
  selector: 'app-button-rental-agency-delete',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDeleteRentalAgencyComponent extends ButtonComponent {

  private rentalAgency: RentalAgencyModel;
  private rowData: any;
  faIcon = faTrashCan;

  constructor(protected router: Router, public dialog: MatDialog, protected rentalAgencyService: RentalAgencyService, protected toastr: ToastrService) {
    super(router, toastr);

    this.color = 'danger';
    this.additionalClass = 'py-0.5 px-2.5'
    this.showIcon = true;
    this.smallBtn = true;
  }

  ngOnInit() {
    this.rentalAgency = <RentalAgencyModel>this.rowData;
  }

  /**
   * Delete user
   */
  public actionClick(): void {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(deleteSubAgent => {
      if (deleteSubAgent) {
        this.rentalAgencyService.deleteRentalAgency(this.rentalAgency.id).subscribe({
          next: () => {
            this.toastr.success('Agenzia Noleggio cancellata correttamente!');

            Registry.getInstance().get('RentalAgencyComponent').loadRentalAgency()
          },
          error: (error) => {
            LoggerService.error('deleteRentalAgency failed!')
            LoggerService.error(error);

            this.toastr.error(`Errore durante la cancellazione dell'Agenzia Noleggio.`);
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cancellare questa Agenzia Noleggio?'
    dialogRef.componentInstance.content = "Attenzione una volta cancellata questa Agenzia Noleggio non sarà più possibile recuperarla."
    dialogRef.componentInstance.firstCTA = "Si cancella"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }
}
