import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {InsuranceServicesTypeService} from "@services/tables/insurance-services-type.service";
import {InsuranceServicesTypeSettings} from "@/table-settings/tables/insurance-services-type-settings";

@Component({
  selector: 'app-insurance-service-type',
  templateUrl: './insurance-service-type.component.html',
  styleUrls: ['./insurance-service-type.component.scss']
})
export class InsuranceServiceTypeComponent {

  source: LocalDataSource;
  settings = InsuranceServicesTypeSettings.gridSettings();

  constructor(private insuranceServicesTypeService: InsuranceServicesTypeService) {
    this.source = new LocalDataSource([]);
    const registry = Registry.getInstance()
    registry.get('userService').onUpdate().subscribe(() => {
      this.loadInsuranceServiceTypes();
    });

    Registry.getInstance().set('InsuranceServiceTypeComponent', this)

    this.loadInsuranceServiceTypes();
  }

  loadInsuranceServiceTypes() {
    this.insuranceServicesTypeService.getAll().subscribe((brands) => {
      this.source.load(brands);
    });
  }
}
