import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {ModelsModel} from "@/models/tables/models.model";
import {DialogModelsComponent} from "@pages/tables/models/dialog-models/dialog-models.component";


@Component({
  selector: 'app-button-detail-models',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDetailModelsComponent extends ButtonComponent {

  private dialogOpened: MatDialogRef<any>
  private modelsModel: ModelsModel;
  private rowData: any;
  faIcon = faPenToSquare;

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio Sigla Modello'
    this.smallBtn = true;
  }

  ngOnInit() {
    this.modelsModel = <ModelsModel>this.rowData;
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogModelsComponent, {
      data: this.modelsModel,
    });

    Registry.getInstance().set('DialogModelsComponentModify', this.dialogOpened)
  }

}
