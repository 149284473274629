import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {WarrantySettings} from "@/table-settings/tables/warranty-settings";
import {WarrantyService} from "@services/tables/warranty.service";

@Component({
  selector: 'app-warranty',
  templateUrl: './warranty.component.html',
  styleUrls: ['./warranty.component.scss']
})
export class WarrantyComponent {

  source: LocalDataSource;
  settings = WarrantySettings.gridSettings();

  constructor(private warrantyService: WarrantyService) {
    this.source = new LocalDataSource([]);
    const registry = Registry.getInstance()
    registry.get('userService').onUpdate().subscribe(() => {
      this.loadWarranties();
    });

    Registry.getInstance().set('WarrantyComponent', this)

    this.loadWarranties();
  }

  loadWarranties() {
    this.warrantyService.getAll().subscribe((warranties) => {
      this.source.load(warranties);
    });
  }
}
