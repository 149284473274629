<div>
  <div class="buttons-over-form mb-3">
    <app-back-button></app-back-button>
  </div>
  <div class="container-box">
    <mat-tab-group *ngIf="tabs.length !== 0" animationDuration="0ms">
      <mat-tab *ngFor="let tab of tabs">
        <ng-template mat-tab-label [ngIf]="faIcon">
          <span *ngIf="faIcon">
            <fa-icon [icon]="faIcon"></fa-icon>
          </span>
          {{tab.title}}
        </ng-template>
        <div class="content" [ngSwitch]="tab.title">
          <app-practice-form-new *ngSwitchCase="'Dettaglio'" [data]="data"></app-practice-form-new>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
