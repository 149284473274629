<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table w-full">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <th class="uppercase pl-2" mat-header-cell *matHeaderCellDef>
        {{ column.header }}
      </th>
      <td [ngClass]="'text-lg'" class="text-slate-300" mat-cell
          *matCellDef="let row">
        {{ column.cell(row) }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
