import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {SubAgent, UserInfo} from "@/models/sub-agent";

@Injectable({
  providedIn: 'root'
})
export class SubagentAgencyService extends ApiService {

  getAll(): Observable<SubAgent[]> {
    return this.http.get<SubAgent[]>(this.baseUrl + `/tables/subagentAgency`);
  }

  getSubAgent(subAgentId: number): Observable<SubAgent> {
    return this.http.get<SubAgent>(this.baseUrl + `/tables/subagentAgency/${subAgentId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getSubAgent')
      return response;
    }));
  }

  saveSubAgent(subAgent: SubAgent) {
    return this.http.post(this.baseUrl + `/tables/subagentAgency/save`, subAgent).pipe(map((response: any) => {
      LoggerService.log(response, 'saveSubAgent')
      return response;
    }));
  }

  deleteSubAgent(subAgentId: number) {
    return this.http.delete(this.baseUrl + `/tables/subagentAgency/${subAgentId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteSubAgent')
      return response;
    }));
  }

  getResponsibleSubagentUsers(): Observable<UserInfo[]> {
    return this.http.get<UserInfo[]>(this.baseUrl + `/tables/subagentAgency/responsibleSubagentUsers`);
  }

  getReferentsUsers(): Observable<UserInfo[]> {
    return this.http.get<UserInfo[]>(this.baseUrl + `/tables/subagentAgency/referentsUsers`);
  }
}
