import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";
import {Observable} from "rxjs";
import {AutocompleteControl} from "@components/dynamic-form/auto-complete/autocomplete-control";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent extends InputFieldComponent implements OnInit {
  @Input() meta!: AutocompleteControl
  @Input() form!: FormGroup
  @Input() indexRow: number = null
  @Input() keyformArrayName: any
  control: AbstractControl<any> = new FormControl<any>('')
  filteredOptions: Observable<any[]>

  protected getValue() {
    return 'value';
  }

  get controlArray() {
    return this.form.controls[this.keyformArrayName] as FormArray;
  }

  protected setControl() {
    if (this.indexRow !== null) {
      let controls = this.controlArray

      this.control = controls.at(this.indexRow).get(this.meta.key) ?? new FormControl('')
    } else {
      this.control = this.form.controls[this.meta.key]
    }
  }

  ngOnInit() {
    this.setControl()

    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => {
        const optionValue = typeof value === 'string' ? value : value[this.getValue()]

        return optionValue ? this._filter(optionValue as string) : this.meta.options.slice();
      }),
    )
  }

  displayFn(selectedOption: any): string {
    return selectedOption && selectedOption.label ? selectedOption.label : '';
  }

  protected _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    const nameValue = this.getValue()

    return this.meta.options.filter(option => option[nameValue].toLowerCase().includes(filterValue));
  }

  protected customActionChange(event) {
    const optionValue = event.target.value
    const results = this._filter(optionValue as string)

    if (results.length === 0) {
      this.control.setValue('')
    }
  }

  protected customActionInput(event, indexRow = null) {
    LoggerService.log('Input Changed!')

    if (this.meta.classToUse && this.meta.customAction && Registry.getInstance().get(this.meta.classToUse)[this.meta.customAction]) {
      Registry.getInstance().get(this.meta.classToUse)[this.meta.customAction](event.option.value, indexRow)
    } else {
      LoggerService.log('Any function triggered')
    }
  }
}
