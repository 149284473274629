export class UploadCircularBigDiscountFormSetting {
  protected static classToCall: string

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static getMeta(): Array<any> {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "circular",
            "controlType": "uploadFile",
            "accept": "application/pdf",
            "label": "Carica la circolare per i maggiori sconti",
            "order": 1,
            "class": "w-full",
            "required": true,
            "inputUploadText": 'Carica qui il documento'
          },
        ],
        "order": 1
      },
    ]
  }
}
