<app-dropdown class="user-menu" id="user-menu">
  <ng-container dropdown-button>
    <img
      [src]="user.picture || 'assets/img/default-profile.png'"
      class="icon-user-topbar"
      alt="User Image"
    />
  </ng-container>
  <ng-container dropdown-menu>
    <li class="user-header">
      <div class="p-2 border-gray-400 menu-dropdown-detail-user">
        <img
          [src]="user.picture || 'assets/img/default-profile.png'"
          class="img-circle elevation-2"
          alt="User Image"
        />
      </div>
      <div class="p-2 border-gray-400 text-left menu-dropdown-detail-user">
        <p class="m-0">
          User name: <span>{{ user.username }}</span>
        </p>
        <p class="m-0">
          Mail: <span>{{ user.email }}</span>
        </p>
      </div>
    </li>
    <!-- Menu Footer-->
    <li class="user-footer">
      <app-button-logout></app-button-logout>
    </li>

  </ng-container>
</app-dropdown>
