import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map, tap} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {SellerModel} from "@/models/tables/seller.model";
import {UserInfo} from "@/models/sub-agent";

@Injectable({
  providedIn: 'root'
})
export class SellersService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/sellers`);
  }

  getAllBrands(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/sellers/brands`);
  }

  getSubagentAgencies(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/sellers/subagentAgencies`);
  }

  getSellerTypes(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/sellers/sellerTypes`);
  }

  getAllUsers(): Observable<UserInfo[]> {
    return this.http.get<UserInfo[]>(this.baseUrl + `/tables/sellers/users`).pipe(tap(v => LoggerService.log(v, 'USER INFO SELLERS:',)));
  }

  deleteSeller(sellerId: number) {
    return this.http.delete(this.baseUrl + `/tables/sellers/${sellerId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteSeller')
      return response;
    }));
  }

  saveSeller(seller: SellerModel) {
    return this.http.post(this.baseUrl + `/tables/sellers/save`, seller).pipe(map((response: any) => {
      LoggerService.log(response, 'saveSeller')
      return response;
    }));
  }
}
