import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {JwtHelperService} from "@services/jwt-helper.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(protected jwtHelper: JwtHelperService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth_token = this.jwtHelper.getToken();

    if (!auth_token) {
      return next.handle(req);
    }

    const headers = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${auth_token}`)
    });

    return next.handle(headers);
  }
}
