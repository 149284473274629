import {TableColumnsSetting} from "@/interfaces/table-setting";
import {TablePracticeModel} from "@/models/tables/table-practices.model";

export class SellerPracticeInvoiceSettingsTable {
  public static getColumnsSetting(): TableColumnsSetting<keyof TablePracticeModel> {
    return [
      {
        name: "id",
        text: "Id",

      },
      {
        name: "importoIva",
        text: "Importo IVA",

      },
      {
        name: "importoNetto",
        text: "Importo Netto",

      },
      {
        name: "percentualeIva",
        text: "Percentuale IVA",

      },
      {
        name: "pratica",
        text: "Pratica",

      },
    ];
  }
}
