import {
  ButtonDetailPaymentTypeComponent
} from "@components/button/tables/payment-type/button-modify-payment-type.component";
import {
  ButtonDeletePaymentTypeComponent
} from "@components/button/tables/payment-type/button-delete-payment-type.component";

export class PaymentTypeSettings {

  public static gridSettings() {
    return {
      actions: false,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager:
        {
          display: true,
          perPage: 25
        },
      columns: {
        detailButton: {
          type: 'custom',
          renderComponent: ButtonDetailPaymentTypeComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        deleteButton: {
          type: 'custom',
          renderComponent: ButtonDeletePaymentTypeComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        id: {
          title: 'Codice',
          width: '120px'
        },
        descrizione: {
          title: 'Descrizione'
        },
        veicoloNuovo: {
          title: 'Veicolo Nuovo',
          width: '130px',
          valuePrepareFunction: (veicoloNuovo) => {
            if (veicoloNuovo) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
        veicoloUsato: {
          title: 'Veicolo Usato',
          width: '130px',
          valuePrepareFunction: (veicoloUsato) => {
            if (veicoloUsato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
        enabled: {
          title: 'Abilitato',
          width: '130px',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
      },
      delete: null
    };
  }
}
