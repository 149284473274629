import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DualListControl} from "@components/dynamic-form/dual-list/dual-list-control";
import {Registry} from "@/registry.service";

@Component({
  selector: 'app-dual-list',
  templateUrl: './dual-list.component.html',
  styleUrls: ['./dual-list.component.scss']
})
export class DualListComponent implements OnInit {
  @Input() meta!: DualListControl
  @Input() form!: FormGroup

  constructor() {
  }

  ngOnInit(): void {
    const registry = Registry.getInstance()

    registry.set(this.meta.key, this.meta.destination)
  }

  destinationChange(data) {
    const key = this.meta.key
    const registry = Registry.getInstance()

    registry.set(key, data)
  }
}
