import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from "ng2-smart-table";

@Component({
  template: `
    {{renderValue}}
  `,
})
export class BrandNewRendererComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: any;
  @Input() rowData: any;

  ngOnInit() {
    if(this.value != null)
    	this.renderValue = this.value;
    else
    	this.renderValue = null;
  }

}
