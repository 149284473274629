<div class="container-box">
  <app-table-header tableTitle="Gestione Stock" (btnUpdateClick)="reloadTrigger$.next()"></app-table-header>
  <div class="table-container">
    <app-new-table
      [dataSource]="dataSource"
      [reloadTrigger$]="reloadTrigger$"
      [displayedColumns]="displayedColumns"
      [initialSort]="{
        active: 'codicePratica',
        direction: 'desc'
      }"
      (customActionSlideToggle)="changeSubagentVisibility($event)"
      (btnDetailsClickEvent)="btnDetailsClickFunction($event)"
    ></app-new-table>
  </div>
</div>
