import {DataSourceBaseNew} from "@components/new-table/data-source-base-new.dataSource";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {TableDataApiParams} from "@services/practices.service";
import {Injectable} from "@angular/core";
import {BiggerDiscountService} from "@services/bigger-discount.service";

@Injectable({
  providedIn: 'root'
})
export class MSTableDataSource extends DataSourceBaseNew<TablePracticeModel> {
  constructor(private biggerDiscountService: BiggerDiscountService) {
    super();
  }

  loadData(params?: TableDataApiParams): void {
    this._isLoading$.next(true);

    this.biggerDiscountService.getBiggerDiscounts(params).subscribe(data => {
      this.elements$.next(data.content);
      this._totalElements$.next(data.totalElements);
      this._isLoading$.next(false);
    })
  }
}
