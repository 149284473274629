<div [formGroup]="form">
  <div *ngIf="indexRow !== null" [formArrayName]="keyformArrayName">
    <div [formGroupName]="indexRow">
      <mat-label class="label-form">{{meta.label}}:</mat-label>
      <mat-form-field class="example-full-width w-full block additional-border-input" appearance="fill">
        <mat-label>Inserisci</mat-label>
        <textarea matInput placeholder="Inserisci qui il testo" class="w-full"
                  [formControlName]="meta.key"
                  (change)="customActionInput($event, indexRow)"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="indexRow === null">
    <mat-label class="label-form">{{meta.label}}:</mat-label>
    <mat-form-field class="example-full-width w-full block additional-border-input" appearance="fill">
      <mat-label>Inserisci</mat-label>
      <textarea matInput placeholder="Inserisci qui il testo" class="w-full"
                [formControlName]="meta.key"
                (change)="customActionInput($event)"></textarea>
    </mat-form-field>
  </div>
  <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>
</div>
