import {TableColumnsSetting} from "@/interfaces/table-setting";
import {DeliveryCardboardService} from "@services/delivery-cardboard.service";
import {TableDeliveryCardBoardModel} from "@/models/tables/delivery-cardboard.models";
import {map} from "rxjs/operators";
import {of} from "rxjs";

export class DeliveryCardboardManagementSettings {
    public static getColumnsSetting(deliveryCardboardService: DeliveryCardboardService): TableColumnsSetting<keyof TableDeliveryCardBoardModel> {
        return [
            {
                name: "btnDetails",
                customComponent: true,
                unsortable: true,
            },
            {
                name: "btnDelete",
                customComponent: true,
                unsortable: true,
            },
            {
                name: "codicePratica",
                type: "autocomplete",
                text: "Numero pratica",
                anchorLink: '/practices-management/practices/details/:praticaId',
                filter: {
                    type: "input"
                }
            },
            {
                name: "dataConsegna",
                type: "date",
                text: "Data consegna",
                filter: {
                    type: 'dateRange'
                }
            },
            {
                name: "marca",
                type: "text",
                text: "Marca",
                filter: {
                    type: "select",
                    options: deliveryCardboardService.getAllBrands().pipe(map(brands => brands.map((brand) => ({
                        [brand]: brand
                    }))))
                }
            },
            {
                name: "progressivoAnnoMeseMarca",
                type: "text",
                text: "Prog. mensile",
                filter: {
                    type: "input"
                }
            },
            {
                name: "progressivoAnnoMarca",
                type: "text",
                text: "Prog. annuale",
                filter: {
                    type: "input"
                }
            },
            {
                name: "targa",
                type: "text",
                text: "targa",
                filter: {
                    type: "input"
                }
            },
            {
                name: "cliente",
                type: "text",
                text: "Cliente",
                filter: {
                    type: "input"
                }
            },
            {
                name: "venditore",
                type: "text",
                text: "Venditore",
                filter: {
                    type: "select",
                    options: deliveryCardboardService.getSellers().pipe(map(sellers => sellers.map(({
                                                                                                        id,
                                                                                                        descrizione,
                                                                                                        codiceVenditore
                                                                                                    }) => ({
                        [id]: `${codiceVenditore} - ${descrizione}`
                    }))))
                }
            },
            {
                name: "destinatarioFattura",
                type: "text",
                text: "Intestatario Fattura",
                filter: {
                    type: "input"
                }
            },
            {
                name: "consegnaTrasmessa",
                type: 'slideToggle',
                text: "Trasmessa",
                filter: {
                    type: "select",
                    options: of([
                        {true: 'SI'},
                        {false: 'NO'}
                    ])
                }
            },
            {
                name: "noteConsegna",
                type: "text",
                text: "note",
                filter: {
                    type: "input"
                }
            }
        ]
    }
}
