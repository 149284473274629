import {AppState} from '@/store/state';
import {ToggleSidebarMenu} from '@/store/ui/actions';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit, Renderer2} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AlertService} from "@services/alert.service";
import {Registry} from "@/registry.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  @HostBinding('class') class = 'wrapper';
  public ui: Observable<UiState>;
  protected showAlert: Boolean = false;
  protected typeAlert: string;
  protected alertMessage: string;
  environment: string = environment.env;

  constructor(private renderer: Renderer2, private store: Store<AppState>, private alertService: AlertService) {
    this.alertService.onUpdate().subscribe(() => {
      this.showAlert = true;
      const registry = Registry.getInstance();

      this.typeAlert = registry.get('alert').type
      this.alertMessage = registry.get('alert').message
    });
  }

  ngOnInit() {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

    this.ui = this.store.select('ui');
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page',
    );
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page',
    );
    this.renderer.addClass(
      document.querySelector('app-root'),
      'layout-fixed',
    );

    this.ui.subscribe(
      ({menuSidebarCollapsed, controlSidebarCollapsed, darkMode}) => {
        if (menuSidebarCollapsed) {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'sidebar-open',
          );
          this.renderer.addClass(
            document.querySelector('app-root'),
            'sidebar-collapse',
          );
        } else {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'sidebar-collapse',
          );
          this.renderer.addClass(
            document.querySelector('app-root'),
            'sidebar-open',
          );
        }

        if (controlSidebarCollapsed) {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'control-sidebar-slide-open',
          );
        } else {
          this.renderer.addClass(
            document.querySelector('app-root'),
            'control-sidebar-slide-open',
          );
        }

        if (darkMode) {
          this.renderer.addClass(
            document.querySelector('app-root'),
            'dark-mode',
          );
        } else {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'dark-mode',
          );
        }
      },
    );

    if (isMobile) {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open',
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse',
      );
    }
  }

  onToggleMenuSidebar() {
    this.store.dispatch(new ToggleSidebarMenu());
  }
}
