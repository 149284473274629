import {Validators} from "@angular/forms";

export class UserForm {
  private static usernameDisabled: boolean;
  private static requiredPasswords: boolean;
  private static usernameRequired: boolean;


  public static formDetailMeta() {
    this.usernameDisabled = true;
    this.usernameRequired = true;
    this.requiredPasswords = false;

    return this.getMeta();
  }

  public static formNewMeta() {
    this.usernameDisabled = false;
    this.usernameRequired = true;
    this.requiredPasswords = true;

    return this.getMeta();
  }

  private static getMeta() {
    return [
      {
        "rows": [
          {
            "key": "username",
            "controlType": "textinput",
            "label": "Username",
            "order": 2,
            "additionalMessage": "L'username non può contenere caratteri maiuscoli",
            "class": "w-full",
            "required": this.usernameRequired,
            "disabled": this.usernameDisabled,
            "additionalValidators": [
              Validators.pattern('^[A-Za-z_.][A-Za-z0-9_.]{4,29}$')
            ]
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "key": "password",
            "label": "Password",
            "controlType": "password",
            "class": " lg:w-6/12 w-full",
            "required": this.requiredPasswords,
            "additionalValidators": [
              Validators.minLength(6)
            ]
          },
          {
            "key": "confirmPassword",
            "label": "Conferma password",
            "controlType": "password",
            "class": " lg:w-6/12 w-full",
            "required": this.requiredPasswords,
            "additionalValidators": [
              Validators.minLength(6)
            ]
          }
        ],
        "order": 2
      },
      {
        "rows": [
          {
            "key": "name",
            "label": "Nome",
            "controlType": "textinput",
            "class": " lg:w-4/12 w-full",
            "required": true
          },
          {
            "key": "lastname",
            "label": "Cognome",
            "controlType": "textinput",
            "class": " lg:w-4/12 w-full",
            "required": true
          },
          {
            "key": "mobileNumber",
            "controlType": "telephone",
            "label": "Telefono",
            "class": " lg:w-4/12 w-full",
            "additionalValidators": [
              Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')
            ]
          }
        ],
        "order": 3
      },
      {
        "rows": [
          {
            "key": "email",
            "label": "Email",
            "controlType": "email",
            "class": " lg:w-6/12 w-full",
            "additionalValidators": [
              Validators.email, Validators.required,
            ]
          },
          {
            "key": "email2",
            "label": "Email 2",
            "controlType": "email",
            "class": " lg:w-6/12 w-full",
            "additionalValidators": [
              Validators.email,
            ]
          },
        ],
        "order": 4
      },
    ];
  }

}
