import {Component} from '@angular/core';
import {LocalDataSource} from "ng2-smart-table";
import {Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {CompanyService} from "@services/admin/company.service";
import {CompanySettings} from "@/table-settings/admin/company-settings";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent {
  sourceUsersTable: LocalDataSource;
  settings = CompanySettings.gridSettings();

  constructor(private router: Router, private formBuilder: FormBuilder, public http: HttpClient, private companyService: CompanyService) {
    this.sourceUsersTable = new LocalDataSource([]);

    this.loadCompany();
  }

  ngOnInit(): void {
  }

  loadCompany() {
    this.companyService.getAllCompany().subscribe((company) => {
      this.sourceUsersTable.load(company);
    });
  }

}
