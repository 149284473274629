import {Component} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {PracticesService} from "@services/practices.service";
import {ToastrService} from "ngx-toastr";
import {OptionSelect} from "@/models/option-select.model";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {PracticeDocument} from "@/models/practice-document.model";
import {DocumentForm} from "@/form-settings/document-form";
import {UserService} from "@services/admin/user.service";

@Component({
  selector: 'app-document-form',
  templateUrl: '../../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class DocumentFormComponent extends DynamicFormComponent {
  encType: string = 'multipart/form-data'
  protected category: OptionSelect[] = [];
  private file: File;

  constructor(protected router: Router, protected userService: UserService, protected practiceService: PracticesService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Carica'
    this.secondaryBottomButton = 'Chiudi'
    this.practiceService.getDocumentCategories().subscribe({
      next: categories => {
        for (const category of categories) {
          this.category.push({
            key: category.code,
            value: category.description,
          });
        }
      },
      error: error => {
        LoggerService.error(`getDocumentCategories failed: ${error}`);
      },
    });

    Registry.getInstance().set(DocumentFormComponent.name, this)
  }

  onSubmit() {
    const document = <PracticeDocument>this.form.getRawValue()
    const formData = new FormData();
    formData.append('praticaId', document.praticaId.toString());
    formData.append('file', this.file);
    formData.append('tipologiaDocumentoPratica', document.tipologiaDocumentoPratica);

    this.practiceService.uploadPracticeDocument(formData).subscribe({
      next: () => {
        this.toastr.success('Documento salvato correttamente!');

        Registry.getInstance().get('PracticeDetailsComponent').closeDocumentDialog()
      },
      error: (error) => {
        LoggerService.error('uploadPracticeDocument failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il caricamento del documento.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  onFileSelected(fileInput: any, index = null) {
    this.file = <File>fileInput.target.files[0]
  }

  protected setupMeta() {
    this.meta = DocumentForm
      .setClassToCall(DocumentFormComponent.name)
      .setCategory(this.category)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected secondaryButtonAction() {
    Registry.getInstance().get('PracticeDetailsComponent').closeDocumentDialog()
  }
}
