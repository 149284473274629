import {FormSetting} from "@/form-settings/form-setting";
import {BrandModel} from "@/models/tables/brand.model";

export class MSDiscountFormSetting extends FormSetting {

  protected static classToCall: string

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static getMeta(): Array<any> {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "id",
            "controlType": "hidden",
            "order": 1,
          },
        ],
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "anno",
            "controlType": "yearDate",
            "label": "Anno",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "mese",
            "controlType": "monthDate",
            "label": "Mese",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "marca",
            "controlType": "dropdown",
            compareFn: (o1: BrandModel, o2: BrandModel) => o1?.id === o2?.id,
            "label": "Marca",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "required": true,
            "options": this.data['brands'] ?? []
          },
          {
            "classToUse": this.classToCall,
            "key": "tipo",
            "label": "Tipo",
            "order": 1,
            compareFn: (o1: string, o2: string) => o1 === o2,
            "class": "lg:w-4/12 w-full",
            "controlType": "dropdown",
            "options": [
              {'key': 'RETAIL', 'value': 'RETAIL'},
              {'key': 'NLT', 'value': 'NLT'}
            ]
          },
          {
            "classToUse": this.classToCall,
            "key": "codiceMs",
            "controlType": "textinput",
            "label": "Codice MS",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "descrizioneMs",
            "controlType": "textinput",
            "label": "Descrizione MS",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "required": true,
          },
        ],
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "classificazioneMs",
            "label": "Classificazione MS",
            "order": 1,
            "required": true,
            compareFn: (o1: string, o2: string) => o1 === o2,
            "class": "lg:w-4/12 w-full",
            "controlType": "dropdown",
            "options": [
              {'key': 'MENSILE', 'value': 'MENSILE'},
              {'key': 'TRIMESTRALE', 'value': 'TRIMESTRALE'},
              {'key': 'SEMESTRALE', 'value': 'SEMESTRALE'},
              {'key': 'EXTRA', 'value': 'EXTRA'}
            ]
          },
          {
            "classToUse": this.classToCall,
            "key": "tipologiaMs",
            "controlType": "textinput",
            "label": "Tipologia MS",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "required": true,
            "options": [
              {'key':'Qualitativo', 'value':'Qualitativo'},
              {'key':'Quantitativo', 'value':'Quantitativo'}
            ]
          },
          {
            "classToUse": this.classToCall,
            "key": "fascia",
            "controlType": "textinput",
            "label": "Fascia",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "descRaggiungimentoObiettivo",
            "controlType": "textinput",
            "label": "Desc. Raggiungimento Obiettivo",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "percMsPrevista",
            "controlType": "number",
            "label": "% MS Prevista",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "percObiettivo",
            "controlType": "number",
            "label": "% Obiettivo",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "obiettivoRaggiunto",
            "controlType": "slide-toggle",
            "label": "Obiettivo Raggiunto",
            "order": 1,
            "default": false,
            "class": "lg:w-4/12 w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "posizioneRanking",
            "controlType": "textinput",
            "label": "Posizione Ranking",
            "order": 1,
            "class": "lg:w-4/12 w-full"
          }
        ]
      }
    ]
  }

}
