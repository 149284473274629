import {Injectable} from '@angular/core';
import {TableDataApiParams} from "@services/practices.service";
import {UsedVehiclesTableModel} from "@/models/tables/used-vehicles-table.model";
import {UsedVehicleService} from "@services/used-vehicle.service";
import {DataSourceBaseNew} from "@components/new-table/data-source-base-new.dataSource";
import {map} from "rxjs/operators";
import {UsedVehiclesModel} from "@/models/used-vehicles.model";

@Injectable({
    providedIn: 'root'
})
export class UsedVehicleDataSource extends DataSourceBaseNew<UsedVehiclesTableModel> {

    constructor(private usedVehicleService: UsedVehicleService) {
        super();
    }

    loadData(params?: TableDataApiParams): void {
        this._isLoading$.next(true);
        this.usedVehicleService.getTableData<UsedVehiclesModel>('usedVehicle', params)
            .pipe(
                map(({content, ...other}) => ({
          ...other,
          content: content.map(({ marca, ...other }) => ({
            ...other,
                        marca: marca.descrizioneMarca,
          }))
                })),
            )
            .subscribe(data => {
                this.elements$.next(data.content);
                this._totalElements$.next(data.totalElements);
                this._isLoading$.next(false);
            })
    }
}
