import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {ActionPracticesModel} from "@/models/tables/action-practices.model";

@Injectable({
  providedIn: 'root'
})
export class ActionPracticesService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/actionPractices`);
  }

  getAllBrands(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/actionPractices/brands`);
  }

  getActionPracticeById(actionPracticeId: number) {
    return this.http.get(this.baseUrl + `/tables/actionPractices/${actionPracticeId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getActionPracticeById')
      return response;
    }));
  }

  deleteActionPractice(actionPracticeId: number) {
    return this.http.delete(this.baseUrl + `/tables/actionPractices/${actionPracticeId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteActionPractice')
      return response;
    }));
  }

  saveActionPractice(actionPractice: ActionPracticesModel) {
    return this.http.post(this.baseUrl + `/tables/actionPractices/save`, actionPractice).pipe(map((response: any) => {
      LoggerService.log(response, 'saveActionPractice')
      return response;
    }));
  }
}
