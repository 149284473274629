import {ButtonDeleteDocumentComponent} from "@components/button/practices/button-delete-document.component";
import {ButtonDownloadDocumentComponent} from "@components/button/practices/button-download-document.component";

export class DocumentsPracticeSettings {

  public static gridSettings() {
    return {
      actions: false,
      hideSubHeader: true,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager: {
        display: false
      },
      columns: {
        downloadDocumentButton: {
          type: 'custom',
          renderComponent: ButtonDownloadDocumentComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        deleteButton: {
          type: 'custom',
          renderComponent: ButtonDeleteDocumentComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        tipologiaDocumentoPratica: {
          title: 'Tipologia Documento'
        },
        file: {
          title: 'Nome Documento',
          type: 'html',
          valuePrepareFunction: (file) => {
            return file.name;
          },
          sort: false
        },
        creator: {
          title: 'Caricato da'
        },
        created: {
          title: 'Caricato il',
          width: '120px',
          sortDirection: 'desc',
          valuePrepareFunction: (date) => {
            let raw = new Date(date);

            let options: Intl.DateTimeFormatOptions = {
              year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric',
            };

            return raw.toLocaleDateString('it', options);
          },
          filter: false
        }
      }
    }
  }

}
