import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from '@components/button/button.component';
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {saveAs} from 'file-saver';
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {PracticesService} from "@services/practices.service";
import {ToastrService} from "ngx-toastr";
import {PracticeDocument} from "@/models/practice-document.model";


@Component({
  selector: 'app-button-document-download',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss'],
})
export class ButtonDownloadDocumentComponent extends ButtonComponent implements OnInit {
  private document: PracticeDocument;
  private rowData: any;
  faIcon = faDownload;

  constructor(protected router: Router, public dialog: MatDialog, private practiceService: PracticesService, protected toastr: ToastrService) {
    super(router, toastr);

    this.additionalClass = 'py-0.5 px-2.5'
    this.showIcon = true;
    this.smallBtn = true;
  }

  ngOnInit() {
    this.document = this.rowData;
  }

  /**
   * Delete user
   */
  public actionClick(): void {
    this.loading = true

    this.practiceService.downloadDocument(this.document.praticaId, this.document.id).subscribe((res) => {
      saveAs(res, this.document.file.name);
      this.loading = false
    });
  }
}
