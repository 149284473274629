import {Component} from '@angular/core';
import {UsedVehicleService} from "@services/used-vehicle.service";
import {UsedVehiclesSettings} from "@/table-settings/used-vehicles-settings";
import {UsedVehicleDataSource} from "@components/new-table/data-sources/used-vehicle.dataSource";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {UsedVehicleModel} from "@/models/usedVehicleModel";
import {faIceCream} from "@fortawesome/free-solid-svg-icons/faIceCream";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {saveAs} from 'file-saver';
import {PracticesService} from "@services/practices.service";

@Component({
  selector: 'app-used-vehicle',
  templateUrl: './used-vehicle.component.html',
  styleUrls: ['./used-vehicle.component.scss']
})
export class UsedVehicleComponent {
  displayedColumns = UsedVehiclesSettings.getColumnsSetting(this.usedVehicleService);
  dataSource = new UsedVehicleDataSource(this.usedVehicleService);
  reloadTrigger$ = new Subject<any>();

  constructor(private usedVehicleService: UsedVehicleService, private router: Router, protected practiceService: PracticesService) {
  }

  btnDetailsClickFunction({id}: UsedVehicleModel) {
    this.router.navigate([`/used-vehicle-management/details/${id}`])
  }

  protected readonly faIceCream = faIceCream;
  protected readonly faDownload = faDownload;

  downloadModelWithdrawalSheetUsedVehicle($event: any) {
    this.practiceService.downloadPermutaPdf($event.id).subscribe((res) => {
      saveAs(res, 'PresaInCarico-FoglioDiRitiro-' + $event.id + '.pdf');
    });
  }
}
