import {Injectable} from '@angular/core';
import {User} from "@/models/user.model";
import {Observable, Subject} from "rxjs";
import {map} from "rxjs/operators";
import {ApiService} from "@services/api.service";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {
  public user: User = null;
  updateList = new Subject();

  onUpdate(): Observable<any> {
    return this.updateList.asObservable();
  }

  triggerUpdate() {
    this.updateList.next({});
  }

  async registerByAuth({email, password}) {
    return
  }

  getByUsername(username: string): Observable<User> {
    return this.http.get<User>(this.baseUrl + `/admin/user/${username}`, {headers: this.headers});
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/admin/user/roleCodes', {headers: this.headers});
  }

  getCompanyCodes(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/admin/user/companyCodes', {headers: this.headers});
  }

  getUserRoles(): Array<any> {
    const userData = Registry.getInstance().get('UserData')
    let userRoles = null

    if (userData) {
      userRoles = userData.roles
    }

    return userRoles ?? JSON.parse(localStorage.getItem('cassa.userRoles')) ?? [];
  }

  getUserName(): Array<any> {
    const userData = Registry.getInstance().get('UserData')
    let userName = null

    if (userData) {
      userName = userData.username
    }

    return userName ?? JSON.parse(localStorage.getItem('cassa.userName')) ?? '';
  }

  createUser(user: User) {
    return this.http.post(this.baseUrl + '/admin/user/create', user).pipe(map((response: any) => {
      LoggerService.log(response, 'createUser')
      return response;
    }));
  }

  updateUser(user: User) {
    return this.http.post(this.baseUrl + '/admin/user/update', user, {responseType: 'text'}).pipe(map((response: any) => {
      LoggerService.log(response, 'updateUser')
      return response;
    }));
  }

  deleteUser(username: string) {
    return this.http.delete(this.baseUrl + '/admin/user/' + username, {responseType: 'text'}).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteUser')
      return response;
    }));
  }

  disableUser(username: string): Observable<any> {
    return this.http.put(this.baseUrl + `/admin/user/${username}/disable`, {}, {responseType: 'text'}).pipe(map((response: any) => {
      LoggerService.log(response, 'disableUser')
      return response;
    }));
  }

  activeUser(username: string): Observable<any> {
    return this.http.put(this.baseUrl + `/admin/user/${username}/enable`, {}, {responseType: 'text'}).pipe(map((response: any) => {
      LoggerService.log(response, 'activeUser')
      return response;
    }));
  }

  searchUser(): Observable<User[]> {
    const options = {headers: this.headers}
    return this.http.get<User[]>(this.baseUrl + '/admin/user', options).pipe(map((response: any) => {
      return response;
    }));
  }
}
