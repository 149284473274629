import {OptionSelect} from "@/models/option-select.model";

export class FormSetting {
    protected static data: OptionSelect[] = [];
    protected static classToCall: string;

    public static setClassToCall(classes) {
        this.classToCall = classes

        return this
    }

    public static set(name, data) {
        this.data[name] = data

        return this
    }

    protected static get(name) {
        return this.data[name]
    }


    public static getMeta(): Array<any> {
        return []
    }
}
