import {
  ButtonDetailRentalPracticeComponent
} from "@components/button/tables/rental-practices/button-modify-rental-practice.component";
import {
  ButtonDeleteRentalPracticeComponent
} from "@components/button/tables/rental-practices/button-delete-rental-practice.component";

export class RentalPracticesSettings {

  public static gridSettings() {
    return {
      actions: false,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager:
        {
          display: true,
          perPage: 25
        },
      columns: {
        detailButton: {
          type: 'custom',
          renderComponent: ButtonDetailRentalPracticeComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        deleteButton: {
          type: 'custom',
          renderComponent: ButtonDeleteRentalPracticeComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        id: {
          title: 'Id',
          width: '70px'
        },
        codiceNoleggio: {
          title: 'Codice noleggio',
          width: '130px'
        },
        descrizione: {
          title: 'Descrizione'
        },
        enabled: {
          title: 'Abilitato',
          width: '130px',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
      },
      delete: null
    };
  }
}
