import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-rental-agency',
  templateUrl: './dialog-rental-agency.component.html',
  styleUrls: ['./dialog-rental-agency.component.scss']
})
export class DialogRentalAgencyComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }
}

