export class BigBuyersModel {
  public id: number;
  public codiceGA: string;
  public descrizione: string;
  public marca: string;
  public tipoCliente: string;
  public enabled: boolean;
  public creator: string;
  public created: Date;
  public modifier: string;
  public modified: Date;
}
