import {library} from '@fortawesome/fontawesome-svg-core'
import {faCamera, fas} from '@fortawesome/free-solid-svg-icons'

library.add(faCamera, fas)

export class ChecklistDocumentSettings {

  public static gridSettings() {
    return {
      actions: false,
      hideSubHeader: true,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager: {
        display: false
      },
      columns: {
        tipologiaDocumentoPratica: {
          title: 'Tipologia Documento'
        },
        done: {
          title: 'Completo',
          type: 'html',
          sortDirection: 'desc',
          valuePrepareFunction: (done) => {
            if (done) {
              return '<i class="fa fa-check-square"></i>'
            } else {
              return '<i class="fa fa-square"></i>'
            }
          }
        }
      }
    }
  }
}
