import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {DialogPaymentTypeComponent} from "@pages/tables/payment-type/dialog-payment-type/dialog-payment-type.component";
import {PaymentTypeModel} from "@/models/tables/payment-type.model";


@Component({
    selector: 'app-button-new-payment-type',
    templateUrl: '../../button.component.html',
    styleUrls: ['../../button.component.scss'],
})
export class ButtonNewPaymentTypeComponent extends ButtonComponent {
    private dialogOpened: MatDialogRef<any>
    faIcon = faPenToSquare;

    constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
        super(router, toastr);

        this.showIcon = false
        this.additionalClass = 'ml-2'
        this.content = 'Nuovo Tipo pagamento'
    }

    /**
     * Open modal new detail page
     */
    public actionClick(): void {
        this.dialogOpened = this.dialog.open(DialogPaymentTypeComponent, {
            data: new PaymentTypeModel(),
        });

        Registry.getInstance().set('DialogPaymentTypeComponent', this.dialogOpened)
    }

}
