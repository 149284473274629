import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Tab} from "@/interfaces/tab";
import {UsedVehicleModel} from "@/models/usedVehicleModel";

@Component({
  selector: 'app-used-vehicle-detail',
  templateUrl: './used-vehicle-detail.component.html',
  styleUrls: ['./used-vehicle-detail.component.scss']
})
export class UsedVehicleDetailComponent implements OnInit {
  data: any = {}
  tabs: Array<Tab> = []
  faIcon: any

  constructor(protected router: Router) {
    this.data = {} as UsedVehicleModel;
  }

  async ngOnInit() {
    this.tabs = [
      {
        'title': 'Dettaglio',
      },
    ]
  }

  buttonBack() {
    this.router.navigate(['used-vehicle-management']);
  }
}
