<div *ngIf="!control?.hide">
  <div [ngSwitch]="control.controlType">
    <app-label
      *ngSwitchCase="'label'"
      [meta]="control"></app-label>
    <app-text-input
      *ngSwitchCase="'textinput'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-text-input>
    <app-date-input
      *ngSwitchCase="'date'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-date-input>
    <app-year-date-picker-input
      *ngSwitchCase="'yearDate'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-year-date-picker-input>
    <app-month-date-picker-input
      *ngSwitchCase="'monthDate'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-month-date-picker-input>
    <app-dropdown-form
      *ngSwitchCase="'dropdown'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-dropdown-form>
    <app-dropdown-form
      *ngSwitchCase="'multiselect'"
      [form]="form"
      [meta]="control"
      [multi]="true"
      class="w-full"
    ></app-dropdown-form>
    <!--    <app-new-dropdown-->
    <!--      *ngSwitchCase="'multiselect'"-->
    <!--      [multi]="true"-->
    <!--      [meta]="control"-->
    <!--      [label]="control.label"-->
    <!--      [formControl]="$any(form.get(control.key))"-->
    <!--    >-->
    <!--    </app-new-dropdown>-->
    <app-repeater-inputs
      *ngSwitchCase="'repeater'"
      [form]="form"
      [meta]="control"
      [triggerMethodSubject]="triggerMethodSubject"
      class="w-full"
    ></app-repeater-inputs>
    <app-auto-complete
      *ngSwitchCase="'autocomplete'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete>
    <app-auto-complete-action-codes
      *ngSwitchCase="'autocomplete-action-codes'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete-action-codes>
    <app-auto-complete-loyalty-services
      *ngSwitchCase="'autocomplete-loyalty-services'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete-loyalty-services>
    <app-auto-complete-model
      *ngSwitchCase="'autocomplete-model'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete-model>
    <app-auto-complete-bank-payment
      *ngSwitchCase="'autocomplete-bank-payment'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete-bank-payment>
    <app-auto-complete-brand
      *ngSwitchCase="'autocomplete-brand'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete-brand>
    <app-auto-complete-financial-services
      *ngSwitchCase="'autocomplete-financial-services'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete-financial-services>
    <app-auto-complete-type-insurance-service
      *ngSwitchCase="'autocomplete-type-insurance-service'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete-type-insurance-service>
    <app-auto-complete-string
      *ngSwitchCase="'autocomplete-string'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete-string>
    <app-auto-complete-subagent-agency
      *ngSwitchCase="'autocomplete-subagent-agency'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete-subagent-agency>
    <app-auto-complete-payment-type
      *ngSwitchCase="'autocomplete-payment-type'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete-payment-type>
    <app-auto-complete-contact-source
      *ngSwitchCase="'autocomplete-contact-source'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete-contact-source>
    <app-auto-complete-warranty
      *ngSwitchCase="'autocomplete-warranty'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-auto-complete-warranty>
    <app-password
      *ngSwitchCase="'password'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-password>
    <app-text-area
      *ngSwitchCase="'textarea'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-text-area>
    <app-number-input
      *ngSwitchCase="'number'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-number-input>
    <app-currency-input
      *ngSwitchCase="'currency'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-currency-input>
    <app-slide-toggle
      *ngSwitchCase="'slide-toggle'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-slide-toggle>
    <app-telephone
      *ngSwitchCase="'telephone'"
      [form]="form"
      [meta]="control"
      class="w-full control-dropdown"
    ></app-telephone>
    <app-email
      *ngSwitchCase="'email'"
      [form]="form"
      [meta]="control"
      class="w-full "
    ></app-email>
    <app-dual-list
      *ngSwitchCase="'dual-list'"
      [form]="form"
      [meta]="control"
      class="w-full"
    ></app-dual-list>
    <app-upload-input
      *ngSwitchCase="'uploadFile'"
      [form]="form"
      [meta]="control"
      [triggerMethodSubject]="triggerMethodSubject"
      class="w-full"
    ></app-upload-input>
    <app-hidden-input
      *ngSwitchCase="'hidden'"
      [form]="form"
      [meta]="control"
      class="hidden"
    ></app-hidden-input>
  </div>
</div>
