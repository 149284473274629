import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {WarrantyModel} from "@/models/tables/warranty.model";

@Injectable({
  providedIn: 'root'
})
export class WarrantyService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/warranties`);
  }

  deleteWarranty(warrantyId: number) {
    return this.http.delete(this.baseUrl + `/tables/warranties/${warrantyId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteWarranty')

      return response;
    }));
  }

  saveWarranty(warranty: WarrantyModel) {
    return this.http.post(this.baseUrl + `/tables/warranties/save`, warranty).pipe(map((response: any) => {
      LoggerService.log(response, 'saveWarranty')

      return response;
    }));
  }
}
