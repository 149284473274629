import {Component} from '@angular/core';
import {LoggerService} from "@services/logger.service";
import {ModelsService} from "@services/tables/models-service";
import {LocalDataSource} from "ng2-smart-table";
import {ModelsSettings} from "@/table-settings/tables/models-settings";
import {Registry} from "@/registry.service";

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss']
})
export class ModelsComponent {

  source: LocalDataSource = new LocalDataSource([]);
  settings = ModelsSettings.gridSettings();

  constructor(private modelsService: ModelsService) {
    this.loadModels()

    Registry.getInstance().set('ModelsComponent', this)
  }

  loadModels() {
    this.modelsService.getAll().subscribe((models) => {
      LoggerService.log(models, 'models')

      this.source.load(models);
    });
    this.modelsService.getAllBrands().subscribe(brands => {
      const options = [];
      for (const brand of brands) {
        options.push({
          value: brand.id,
          title: brand.codiceMarca + ' - ' + brand.descrizioneMarca
        });
      }

      this.settings.columns.marca.filter.config.list = options;
      this.settings = Object.assign({}, this.settings);
    });
  }
}
