import {Component, EventEmitter, Input, Output} from '@angular/core';
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-button-delete',
  templateUrl: './button-delete.component.html',
  styleUrls: ['./button-delete.component.scss']
})
export class ButtonDeleteComponent {

  @Input() disabled = false;
  @Output() btnClick = new EventEmitter<MouseEvent>();

  protected readonly faTrashCan = faTrashCan;

  constructor() {}
}
