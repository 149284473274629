<ul class="navbar-nav">
  <li class="nav-item">
    <a (click)="onToggleMenuSidebar()" class="nav-link" role="button"
    ><i class="fas fa-bars"></i
    ></a>
  </li>
</ul>
<!--<button-->
<!--  *ngIf="router.url !== '/new-practices-management/practices'"-->
<!--  routerLink="new-practices-management/practices"-->
<!--  class="m-auto py-2 px-4 rounded-md uppercase font-bold text-md text-green-500 bg-green-200 transition-colors hover:bg-green-500 hover:text-green-200"-->
<!--&gt;-->
<!--  vai alla nuova tabella gestione pratiche-->
<!--</button>-->

<form *ngIf="false" [formGroup]="searchForm" class="form-inline ml-3">
  <div class="input-group input-group-sm">
    <input
      aria-label="Search"
      class="form-control form-control-navbar"
      placeholder="Search"
      type="search"
    />
    <div class="input-group-append">
      <button class="btn btn-navbar" type="submit">
        <i class="fas fa-search"></i>
      </button>
    </div>
  </div>
</form>
<app-button
  *ngIf="linkDocumentation"
  [color]="'warning'"
  [content]="'Guida applicativo'"
  [external]="true"
  [linkButton]="linkDocumentation" [showIcon]="false" class="navbar-nav ml-auto"></app-button>
<ul class="navbar-nav ml-auto">
  <app-user></app-user>
</ul>
