<div [formGroup]="form">
  <div *ngIf="indexRow !== null" [formArrayName]="keyformArrayName">
    <div [formGroupName]="indexRow"
         [ngClass]="meta.disabled ? 'disabled-dropdown' : 'active'">
      <mat-label class="label-form">{{meta.label}}:</mat-label>
      <div *ngIf="showAlert" class="p-1 items-center text-black leading-none lg:rounded-full flex lg:inline-flex ml-3"
           style="background-color: #ffff002e"
           role="alert">
        <fa-icon [icon]="faIcon" class="my-0 mx-2"></fa-icon>
        <span class="font-semibold mr-2 text-left flex-auto text-black">Azione non calcolata nel conteggio</span>
      </div>
      <mat-form-field class="w-full additional-border-input mt-1"
                      [ngClass]="control.invalid && submitted ? 'invalid-field' : ''"
                      appearance="fill">
        <mat-label>{{meta.label}}</mat-label>
        <input type="text" matInput [formControl]="control" [matAutocomplete]="auto"
               (change)="customActionChange($event)">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                          (optionSelected)="customActionInput($event, indexRow)">
          <mat-option *ngFor="let action of filteredOptions | async" [value]="action">
            {{getValueFormatted(action)}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="indexRow === null" [ngClass]="meta.disabled ? 'disabled-dropdown' : 'active'">
    <mat-label class="label-form">{{meta.label}}:</mat-label>
    <mat-form-field class="w-full additional-border-input mt-1" appearance="fill"
                    [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
      <mat-label>{{meta.label}}</mat-label>
      <input type="text" matInput [formControl]="control" [matAutocomplete]="auto"
             (change)="customActionChange($event)">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="customActionInput($event)">
        <mat-option *ngFor="let action of filteredOptions | async" [value]="action">
          {{getValueFormatted(action)}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>
</div>
