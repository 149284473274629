import {Injectable} from '@angular/core';
import {ApiService} from "@services/api.service";
import {of} from "rxjs";
import {catchError, map} from "rxjs/operators";

export interface TableDataApiParams {
  page?: number;
  size?: number;
  year?: number;
  month?: number;
}

@Injectable({
  providedIn: 'root'
})
export class CashMovementsAnalisisService extends ApiService {

  getDataForTable(
    params: TableDataApiParams = {
      page: 1,
      size: 30,
    }
  ) {
    return this.http.get<any>(this.baseUrl + `/cashFlowAnalisis/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }
}
