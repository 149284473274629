import {OptionSelect} from "@/models/option-select.model";
import {RepeaterControl} from "@components/dynamic-form/repeater-inputs/repeater-control";
import {ControlType} from "@components/dynamic-form/control-type";
import {ControlBase} from "@components/dynamic-form/control-base";
import {UploadControl} from "@components/dynamic-form/upload-input/upload-control";
import {DropdownControl} from "@components/dynamic-form/dropdown/dropdown-control";

export class MultipleDocumentForm {
  protected static classToCall: string
  private static category: OptionSelect[] = [];
  private static rows: ControlBase[] = [];
  private static rowsUploadFiles: ControlBase[] = []

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this;
  }

  public static setCategory(category) {
    this.category = category

    return this
  }

  public static resetRows() {
    this.rows = []

    return this;
  }

  public static getMeta(): Array<any> {
    if (this.rows.length <= 0) {
      this.addRowFilesUpload();
      this.addRowDocuments();
    }

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowFilesUpload() {
    const inputsComponent = {} as UploadControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'files';
    inputsComponent.controlType = ControlType.uploadFile
    inputsComponent.accept = "application/pdf";
    inputsComponent.label = 'Carica più documenti';
    inputsComponent.multiple = true
    inputsComponent.class = "w-full";
    inputsComponent.customAction = 'onFileMultipleSelected';
    inputsComponent.inputUploadText = 'Carica qui i documenti';

    this.rows.push(inputsComponent);
  }

  private static addRowDocuments(): void {
    const inputsComponent = {} as RepeaterControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = "documents";
    inputsComponent.title = "Lista documenti";
    inputsComponent.class = "w-full";
    inputsComponent.controlType = ControlType.repeater
    inputsComponent.repeaterRows = [];
    inputsComponent.deleteAction = 'removeDocumentUploaded'
    inputsComponent.metaArray = this.getMetaArrayUploadDocument()
    inputsComponent.hideNewRowButton = true;

    this.rows.push(inputsComponent);
  }

  private static addRowFile() {
    const inputsComponent = {} as RepeaterControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = "file";
    inputsComponent.title = "File";
    inputsComponent.label = "Nome File"
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.disabled = true;
    inputsComponent.required = true;
    inputsComponent.class = "w-6/12";

    this.rowsUploadFiles.push(inputsComponent);
  }

  private static addRowCategoryFile() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = "tipologiaDocumentoPratica";
    inputsComponent.label = "Tipologia documento";
    inputsComponent.controlType = ControlType.dropdown;
    inputsComponent.required = true;
    inputsComponent.class = "w-6/12";
    inputsComponent.options = this.category;

    this.rowsUploadFiles.push(inputsComponent);
  }

  private static getMetaArrayUploadDocument() {
    this.addRowFile()
    this.addRowCategoryFile()

    return [
      {
        "rows": this.rowsUploadFiles,
        "order": 1
      }
    ];
  }
}
