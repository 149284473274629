<div class="container-box">
  <app-table-header tableTitle="Gestione Usato" (btnUpdateClick)="reloadTrigger$.next()">
  </app-table-header>
  <div class="table-container">
    <app-new-table
      [dataSource]="dataSource"
      [reloadTrigger$]="reloadTrigger$"
      [displayedColumns]="displayedColumns"
      [initialSort]="{
          active: 'created',
          direction: 'desc'
        }"
      btnCustomToolTip="Scarica MODELLO PRESA IN CARICO FOGLIO DI RITIRO"
      [btnCustomFaIcon]="faDownload"
      (btnDetailsClickEvent)="btnDetailsClickFunction($event)"
      (btnCustomClickEvent)="downloadModelWithdrawalSheetUsedVehicle($event)"
    ></app-new-table>
  </div>
</div>
