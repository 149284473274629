<div class="container-box">
    <app-table-header tableTitle="Lista pratiche" (btnUpdateClick)="reloadTrigger$.next()">
        <app-button-new-practice action-button></app-button-new-practice>
    </app-table-header>
    <div>
        <div class="table-container">
          <app-new-table
            [dataSource]="dataSource"
            [reloadTrigger$]="reloadTrigger$"
            [displayedColumns]="displayedColumns"
            [initialSort]="{
              active: 'data',
              direction: 'desc'
            }"
            (btnDeleteClickEvent)="btnDeleteFunction($event)"
            (btnDetailsClickEvent)="btnDetailsClickFunction($event)"
          ></app-new-table>
        </div>
    </div>
</div>

