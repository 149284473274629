import {FormSetting} from "@/form-settings/form-setting";

export class DuplicatePracticeFormSetting extends FormSetting {

    protected static classToCall: string

    public static setClassToCall(classes) {
        this.classToCall = classes

        return this
    }

    public static getMeta(): Array<any> {
        return [
            {
                "rows": [
                    {
                        "classToUse": this.classToCall,
                        "key": "praticaId",
                        "controlType": "hidden",
                        "label": "Pratica ID",
                        "order": 2,
                        "required": true,
                    },
                    {
                        "classToUse": this.classToCall,
                        "key": "numeriPratiche",
                        "title": "Numeri Pratiche",
                        "controlType": "repeater",
                        "label": "",
                        "order": 2,
                        "class": "w-full",
                        "repeaterRows": [],
                        "buttonRepeater": "Aggiungi numero pratica da duplicare",
                        "metaArray": this.getMetaArrayPracticeNumbers(),
                        "formClass": this.getFormClass()
                    },
                ],
                "order": 1
            },
        ]
    }

    private static getMetaArrayPracticeNumbers() {
        return [
            {
                "rows": [
                    {
                        "classToUse": this.classToCall,
                        "key": "codicePratica",
                        "controlType": "textinput",
                        "label": "Numero Pratica",
                        "order": 2,
                        "class": "md:w-4/12 lg:w-4/12 w-full",
                        "required": true,
                        "formClass": this.getFormClass()
                    },
                    {
                        "classToUse": this.classToCall,
                        "key": "codiceModello",
                        "controlType": "textinput",
                        "label": "Codice Modello",
                        "order": 2,
                        "class": "md:w-4/12 lg:w-3/12 w-full"
                    },
                    {
                        "classToUse": this.classToCall,
                        "key": "descrizioneModello",
                        "controlType": "textinput",
                        "label": "Descrizione Modello",
                        "order": 2,
                        "class": "md:w-4/12 lg:w-5/12 w-full"
                    },
                ],
                "order": 1
            }
        ];
    }

    private static getFormClass(): string {
        return 'DuplicatePracticeFormComponent'
    }
}
