import {Component, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {BrandGoalsService} from "@services/tables/brand-goals.service";
import {BrandGoalsModel} from "@/models/brand-goals.model";
import {BrandGoalsFormSetting} from "@/form-settings/brand-goals-form-setting";
import {OptionSelect} from "@/models/option-select.model";

@Component({
  selector: 'app-brand-goals-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class BrandGoalsFormComponent extends DynamicFormComponent implements OnInit {
  private bigBuyers: OptionSelect[] = [];
  private brands: OptionSelect[] = [];

  constructor(protected router: Router, protected userService: UserService, protected brandGoalsService: BrandGoalsService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.brandGoalsService.getBigBuyers(this.data.marca.id).subscribe(bigBuyers => {
      this.bigBuyers = bigBuyers.map(bigBuyer => ({
        key: bigBuyer,
        value: bigBuyer.codiceGA + ' - ' + bigBuyer.descrizione
      }))
      this.setupMeta()
    });

    this.brandGoalsService.getAllBrands().subscribe(brands => {
      this.brands = brands.map(brand => ({
        key: brand,
        value: brand.descrizioneMarca
      }))
      this.setupMeta()
    });
  }

  onSubmit() {
    let brandGoalModel = <BrandGoalsModel>this.form.getRawValue()

    this.brandGoalsService.saveBrandGoal(brandGoalModel).subscribe({
      next: () => {
        this.toastr.success('Obiettivo marca salvato correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveBrandGoal failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio dell'obiettivo marca.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = BrandGoalsFormSetting
      .set('bigBuyers', this.bigBuyers)
      .set('brands', this.brands)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    Registry.getInstance().get('DialogBrandGoalsComponentModify').close()
    Registry.getInstance().get('BrandGoalsComponent').loadBrandGoals()
  }

  protected secondaryButtonAction() {
    Registry.getInstance().get('DialogBrandGoalsComponentModify').close()
  }

}
