import {Component, Input, OnChanges} from '@angular/core';
import {PracticeModel} from "@/models/practice.model";
import {TableColumnsSetting} from "@/interfaces/table-setting";
import {SellerPracticeInvoiceDataSource} from "@components/new-table/data-sources/seller-practice-invoice.dataSource";
import {BuyerPracticeInvoiceDataSource} from "@components/new-table/data-sources/buyer-practice-invoice.dataSource";
import {ImportFlowService} from "@services/imports/import-flow.service";
import {BuyerPracticeInvoiceSettingsTable} from "@/table-settings/imports/buyer-practice-invoice-settings-table";
import {SellerPracticeInvoiceSettingsTable} from "@/table-settings/imports/seller-practice-invoice-settings-table";
import {Subject} from "rxjs";

@Component({
  selector: 'app-accounting-tab-component',
  templateUrl: './accounting-tab-component.component.html',
  styleUrls: ['./accounting-tab-component.component.scss']
})
export class AccountingTabComponentComponent implements OnChanges {
  @Input() formData!: PracticeModel;
  panelOpenState: boolean;
  displayedColumnsSellerInvoice: TableColumnsSetting<any>;
  dataSourceSellerInvoice = null;
  dataSourceBuyerInvoice = null;
  displayedColumnsBuyerInvoice: TableColumnsSetting<any>;
  reloadTrigger$ = new Subject<any>();

  constructor(private importFlowService: ImportFlowService) {
    this.displayedColumnsBuyerInvoice = BuyerPracticeInvoiceSettingsTable.getColumnsSetting();
    this.displayedColumnsSellerInvoice = SellerPracticeInvoiceSettingsTable.getColumnsSetting();
  }

  public ngOnChanges() {
    if (Object.values(this.formData).length !== 0) {
      this.dataSourceSellerInvoice = new SellerPracticeInvoiceDataSource(this.importFlowService);
      this.dataSourceSellerInvoice.content = this.formData.lineaFatturaVenditaList;
      this.dataSourceSellerInvoice.totalElements = 1;

      this.dataSourceBuyerInvoice = new BuyerPracticeInvoiceDataSource(this.importFlowService);
      this.dataSourceBuyerInvoice.content = this.formData.lineaFatturaAcquistoList;
      this.dataSourceBuyerInvoice.totalElements = 1;
    }
  }
}
