import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-bank-account',
  templateUrl: './dialog-bank-account.component.html',
  styleUrls: ['./dialog-bank-account.component.scss']
})
export class DialogBankAccountComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }
}

