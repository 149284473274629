import {Component} from '@angular/core';
import {Registry} from "@/registry.service";
import {UserService} from "@services/admin/user.service";
import {PracticesService} from "@services/practices.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(private userService: UserService, private practiceService: PracticesService) {
    const registry = Registry.getInstance()

    registry.set('userService', this.userService)
    registry.set('practiceService', this.practiceService)

  }
}
