import {Component} from '@angular/core';
import {StockService} from "@services/stock.service";
import {StockTableSettings} from "@/table-settings/stock-table-settings.settings";
import {StockManagementDataSource} from "@components/new-table/data-sources/stock-management.dataSource";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {ToastrService} from "ngx-toastr";
import {PracticeModel} from "@/models/practice.model";

@Component({
    selector: 'app-stock',
    templateUrl: './stock.component.html',
    styleUrls: ['./stock.component.scss']
})
export class StockComponent {

    displayedColumns = StockTableSettings.getColumnsSetting(this.stockService)

    dataSource = new StockManagementDataSource(this.stockService);

    reloadTrigger$ = new Subject<any>();

    constructor(
        private stockService: StockService,
        private router: Router,
        protected toastr: ToastrService
    ) {
    }

    btnDetailsClickFunction({id}: PracticeModel) {
      this.router.navigate([`/practices-management/practices/details/${id}`])
    }

    changeSubagentVisibility(stockModel: PracticeModel) {
        this.stockService.updateSubagentVisibility(stockModel.id, stockModel.visibilitaSubagenti).subscribe({
            next: (response) => {
                this.toastr.success('Visibilità Subagente aggiornata!');

                this.reloadTrigger$.next()
            },
            error: (error) => {
                LoggerService.error('updateSubagentVisibility failed!');
                LoggerService.error(error);

                this.toastr.error(`Modifica visibilità subagente fallita!`);

                this.reloadTrigger$.next()

                setTimeout(() => {
                    Registry.getInstance().set('saveInProgress', false)
                }, 1000)
            },
            complete: () => {
                LoggerService.log('Request done')

                setTimeout(() => {
                    Registry.getInstance().set('saveInProgress', false)
                }, 1000)
            },
        })
    }
}
