import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {BankAccountSettings} from "@/table-settings/tables/bank-account-settings";
import {BankAccountService} from "@services/tables/bank-account.service";

@Component({
  selector: 'app-rental-agency',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss']
})
export class BankAccountComponent {

  source: LocalDataSource = new LocalDataSource([]);
  settings = BankAccountSettings.gridSettings();

  constructor(private bankAccountService: BankAccountService) {
    Registry.getInstance().set('BankAccountComponent', this)

    this.loadBankAccount();
  }

  public loadBankAccount() {
    this.bankAccountService.getAll().subscribe((bankAccounts) => {
      this.source.load(bankAccounts);
    });
  }
}
