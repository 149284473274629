<div [ngSwitch]="meta.dateType">
  <mat-label class="label-form">{{ meta.label }}:</mat-label>
  <ng-container *ngSwitchCase="'YYYY'">
    <mat-form-field appearance="fill" class="date-input w-full">
      <mat-label>Scegli una data</mat-label>
      <input (dateChange)="customActionInput($event)" *ngIf="meta.maxDate" [formControl]="date" [matDatepicker]="pickerYear" [max]="today"
             [placeholder]="meta.placeholder"
             matInput>
      <mat-datepicker-toggle [for]="pickerYear" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #pickerYear
                      (monthSelected)="setYear($event, pickerYear)" startView="multi-year"></mat-datepicker>
    </mat-form-field>
  </ng-container>
  <ng-container *ngSwitchCase="'MM/YYYY'">
    <mat-form-field appearance="fill" class="date-input w-full">
      <mat-label>Scegli una data</mat-label>
      <input (dateChange)="customActionInput($event)" *ngIf="meta.maxDate" [formControl]="date" [matDatepicker]="pickerMonth" [max]="today"
             [placeholder]="meta.placeholder"
             matInput>
      <mat-datepicker-toggle [for]="pickerMonth" matSuffix></mat-datepicker-toggle>
      <mat-datepicker
        #pickerMonth
        (monthSelected)="setMonthAndYear($event, pickerMonth)" startView="multi-year"></mat-datepicker
      >
    </mat-form-field>
  </ng-container>
</div>
