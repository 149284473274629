import {Component} from '@angular/core';
import {ButtonComponent} from '@components/button/button.component';
import {Router} from "@angular/router";
import {faCloudDownload} from "@fortawesome/free-solid-svg-icons";
import {ToastrService} from "ngx-toastr";
import {UsedVehicleModel} from "@/models/usedVehicleModel";
import {PracticesService} from "@services/practices.service";
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-button-exchange-download',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss'],
})
export class ButtonDownloadExchangeComponent extends ButtonComponent {
  private exchange: UsedVehicleModel;
  private rowData: any;
  faIcon = faCloudDownload;

  constructor(protected router: Router, protected practiceService: PracticesService, protected toastr: ToastrService) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.smallBtn = true;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.exchange = <UsedVehicleModel>this.rowData;
  }

  public actionClick(): void {
    this.loading = true

    this.practiceService.downloadPermutaPdf(this.exchange.id).subscribe((res) => {
      saveAs(res, 'PresaInCarico-FoglioDiRitiro-' + this.exchange.id + '.pdf');

      this.loading = false
    });
  }
}
