import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {WarrantyModel} from "@/models/tables/warranty.model";
import {DialogWarrantyComponent} from "@pages/tables/warranty/dialog-warranty/dialog-warranty.component";


@Component({
  selector: 'app-button-new-warranty',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonNewWarrantyComponent extends ButtonComponent {
  private dialogOpened: MatDialogRef<any>
  faIcon = faPenToSquare;

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'ml-2'
    this.content = 'Nuova garanzia'
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogWarrantyComponent, {
      data: new WarrantyModel(),
    });

    Registry.getInstance().set('DialogWarrantyComponentNew', this.dialogOpened)
  }

}
