import {
  InsuranceServiceTypeRendererComponent
} from "@components/table-column-renderer/insurance-service-type-renderer.component";
import {
  ButtonDetailServicePracticeComponent
} from "@components/button/tables/service-practices/button-modify-service-practice.component";
import {
  ButtonDeleteServicePracticeComponent
} from "@components/button/tables/service-practices/button-delete-service-practice.component";

export class ServicePracticesSettings {

  public static gridSettings() {
    return {
      actions: false,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager:
        {
          display: true,
          perPage: 25
        },
      columns: {
        detailButton: {
          type: 'custom',
          renderComponent: ButtonDetailServicePracticeComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        deleteButton: {
          type: 'custom',
          renderComponent: ButtonDeleteServicePracticeComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        id: {
          title: 'Id',
          width: '70px'
        },
        tipoVeicolo: {
          title: 'Tipo veicolo',
          width: '100px',
          filter: {
            type: 'list',
            config: {
              list: [
                {value: 'NUOVO', title: 'NUOVO'},
                {value: 'USATO', title: 'USATO'},
              ],
            },
          },
        },
        tipoServizio: {
          title: 'Tipo servizio',
          width: '100px',
          filter: {
            type: 'list',
            config: {
              list: [
                {value: 'LOYALTY', title: 'LOYALTY'},
                {value: 'FINANZIARI', title: 'FINANZIARI'},
                {value: 'ALTRI_SERVIZI', title: 'ALTRI_SERVIZI'}
              ],
            },
          },
        },
        descrizione: {
          title: 'Descrizione'
        },
        note: {
          title: 'Note'
        },
        tipoServizioAssicurativo: {
          title: 'Tipo Servizio Assicurativo',
          type: 'custom',
          renderComponent: InsuranceServiceTypeRendererComponent,
          width: '120px',
          filter: {
            type: 'list',
            config: {
              list: [],
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            if (cell.id == search) {
              return true;
            } else {
              return false;
            }
          },
        },
        punteggio: {
          title: 'Punteggio/Importo',
          width: '100px',
          type: 'html',
          valuePrepareFunction: (data, row) => {
            if (row.tipoServizio === 'ALTRI_SERVIZI')
              return '<p class="cell_right">' + Intl.NumberFormat('it-IT', {
                style: 'currency',
                currency: 'EUR'
              }).format(data) + '</p>';
            else
              return '<p class="cell_right">' + Intl.NumberFormat('it-IT').format(data) + '</p>';
          }
        },
        provvigioneAccessorio: {
          title: 'Provvigione accessori',
          type: 'html',
          valuePrepareFunction: (data) => {
            if(data != null) {
              return '<p class="cell_right">' + Intl.NumberFormat('it-IT', {
                style: 'currency',
                currency: 'EUR'
              }).format(data) + '</p>';
            }
            else {
              return null;
            }
          },
          filter: false
        },
        enabled: {
          title: 'Abilitato',
          width: '130px',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
      },
      delete: null
    };
  }
}
