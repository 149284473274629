import {FormSetting} from "@/form-settings/form-setting";

export class DataVehicleNotMatchedFormSetting extends FormSetting {

  public static getMeta() {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "codiceModello",
            "controlType": "textinput",
            "label": "Codice Modello",
            "order": 1,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "siglaModello",
            "controlType": "autocomplete-model",
            "label": "Modello",
            "order": 2,
            "required": true,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true,
            "options": this.get('models'),
          },
          {
            "classToUse": this.classToCall,
            "key": "descrizioneModello",
            "controlType": "textinput",
            "label": "Descrizione Modello",
            "order": 3,
            "class": "md:w-full lg:w-6/12 w-full",
            "disabled": true,
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "telaio",
            "controlType": "textinput",
            "label": "Telaio",
            "order": 1,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "targa",
            "controlType": "textinput",
            "label": "Targa",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "statoOrdineVgi",
            "controlType": "textinput",
            "label": "Stato Ordine VGI",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "settimanaPrevistaProduzione",
            "controlType": "textinput",
            "label": "Settimana prevista produzione",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true,
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "codiceColore",
            "controlType": "textinput",
            "label": "Codice Colore",
            "order": 1,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "descrizioneColore",
            "controlType": "textinput",
            "label": "Descrizione Colore",
            "order": 2,
            "class": "md:w-6/12 lg:w-8/12 w-full",
            "disabled": true,
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "codiceColoreInterno",
            "controlType": "textinput",
            "label": "Codice Colore Interno",
            "order": 1,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "descrizioneColoreInterno",
            "controlType": "textinput",
            "label": "Descrizione Colore Interno",
            "order": 2,
            "class": "md:w-6/12 lg:w-8/12 w-full",
            "disabled": true,
          },
        ],
        "order": 1
      }
    ];
  }
}
