import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {PaymentTypeModel} from "@/models/tables/payment-type.model";

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/paymentType`);
  }

  deletePaymentType(paymentTypeId: string) {
    return this.http.delete(this.baseUrl + `/tables/paymentType/${paymentTypeId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deletePaymentType')

      return response;
    }));
  }

  savePaymentType(paymentType: PaymentTypeModel) {
    return this.http.post(this.baseUrl + `/tables/paymentType/save`, paymentType).pipe(map((response: any) => {
      LoggerService.log(response, 'savePaymentType')

      return response;
    }));
  }
}
