import {Component, Input} from '@angular/core';
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";
import {ControlBase} from "@components/dynamic-form/control-base";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent extends InputFieldComponent {
  @Input() meta!: ControlBase
  @Input() form!: FormGroup
}
