import {Component, OnInit} from '@angular/core';
import {DateTime} from 'luxon';
import {User} from '@/models/user.model';
import {LoggerService} from '@services/logger.service';
import {AuthService} from '@services/auth.service';
import {ProfileService} from "@services/admin/profile.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  public user: User = new User();

  constructor(private authService: AuthService, private profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.profileService.getProfile().subscribe({
      next: (user) => {
        LoggerService.log('getProfile completed');

        this.user = user;
      },
      error: (error) => {
        LoggerService.error('getProfile failed!');
        LoggerService.error(error);
      },
      complete: () => LoggerService.log('Request done'),
    });
  }


  formatDate(date) {
    return DateTime.fromISO(date).toFormat('dd LLL yyyy');
  }
}
