import {Injectable} from '@angular/core';
import {ApiService} from "@services/api.service";
import {Observable} from "rxjs";
import {TableDataApiParams} from "@services/practices.service";
import {
  DeliveryCardBoardModel,
  DeliveryCardboardPractice,
  TableDeliveryCardBoardModel
} from "@/models/tables/delivery-cardboard.models";
import {TablePaginationRespModel} from "@/models/table-pagination-resp.model";
import {map, tap} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {SellerModel} from "@/models/tables/seller.model";
import {
  TableDeliveryTableColumnStructure
} from "@pages/practices-management/delivery-cardboard-management/table-delivery-carton/table-delivery-carton.component";

@Injectable({
    providedIn: 'root'
})
export class DeliveryCardboardService extends ApiService {

    getTableData(params?: TableDataApiParams): Observable<TablePaginationRespModel<TableDeliveryCardBoardModel>> {
        return this.http
            .get<TablePaginationRespModel<TableDeliveryCardBoardModel>>(this.baseUrl + '/deliveryCardboard/page', {
                params: {...params}
            })
    }

    getPractices(): Observable<DeliveryCardboardPractice[]> {
        return this.http.get<DeliveryCardboardPractice[]>(this.baseUrl + '/deliveryCardboard/practices');
    }

    getDashboard(year: number, month: number): Observable<TableDeliveryTableColumnStructure[]> {
        return this.http.get<TableDeliveryTableColumnStructure[]>(this.baseUrl + `/deliveryCardboard/dashboard/${year}/${month}/`);
    }

    updateDeliveryTransmitted(deliveryCardboardId: number, transmitted: boolean): Observable<TableDeliveryTableColumnStructure[]> {
        return this.http.post<any>(this.baseUrl + `/deliveryCardboard/updateDeliveryTransmitted/${deliveryCardboardId}`, {'transmitted': transmitted})
    }

    deleteCardBoard(id: number) {
        return this.http.delete(this.baseUrl + `/deliveryCardboard/${id}`).pipe(
            tap(response => LoggerService.log(response, "deleted cardboard"))
        )
    }

    saveCardBoard(cardBoard: DeliveryCardBoardModel) {
        LoggerService.log(cardBoard, "ATTEMPTING TO SAVE -> ")
        return this.http.post<DeliveryCardBoardModel>(this.baseUrl + '/deliveryCardboard/save', cardBoard).pipe(
            tap(response => LoggerService.log(response, "saved cardboard"))
        )
    }

    getAllBrands(): Observable<any[]> {
        return this.http.get<any>(this.baseUrl + `/deliveryCardboard/brands`).pipe(map((response: any) => {
            LoggerService.log(response, 'getAllBrands')
            return response;
        }));
    }

    getSellers(): Observable<SellerModel[]> {
        return this.http.get(this.baseUrl + '/deliveryCardboard/sellers/').pipe(map((response: any) => {
            LoggerService.log(response, 'getSellers')
            return response;
        }));
    }
}
