import {
  ButtonDeletePracticeCommunicationComponent
} from "@components/button/practices/button-delete-practice-communication.component";
import {
  ButtonDetailPracticeCommunicationComponent
} from "@components/button/practices/button-detail-practice-communication.component";

export class CommunicationPracticeSettings {

  public static gridSettings() {
    return {
      actions: {
        columnTitle: 'Comunicazioni',
        add: false,
        edit: false,
        delete: false,
      },
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table',
      },
      pager: {
        display: false,
      },
      columns: {
        detailButton: {
          type: 'custom',
          renderComponent: ButtonDetailPracticeCommunicationComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        deleteButton: {
          type: 'custom',
          renderComponent: ButtonDeletePracticeCommunicationComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        titolo: {
          title: 'Titolo'
        },
        messaggio: {
          title: 'Messaggio'
        },
      },
      delete: null,
    };
  }

}
