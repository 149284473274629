import {FormSetting} from "@/form-settings/form-setting";
import {User} from "@/models/user.model";
import {LoggerService} from "@services/logger.service";

export class SellerFormSetting extends FormSetting {

  protected static classToCall: string

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static getMeta(): Array<any> {
    LoggerService.log(this.data, "DATA:")

    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "codiceVenditore",
            "label": "Codice Venditore",
            "controlType": "textinput",
            "class": "lg:w-4/12 w-full",
            "order": 1,
            "required": true
          },
          {
            "classToUse": this.classToCall,
            "key": "users",
            "label": "Nome utente",
            "controlType": "multiselect",
            compareFn: (o1: User, o2: User) => o1?.username === o2?.username,
            "class": "lg:w-4/12 w-full",
            "order": 1,
            "options": this.data['users'] ?? [],
          },
          {
            "classToUse": this.classToCall,
            "key": "marca",
            "controlType": "dropdown",
            "label": "Marca",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "required": true,
            "options": this.data['brands'] ?? []
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "descrizione",
            "label": "Descrizione",
            "controlType": "textinput",
            "class": "lg:w-4/12 w-full",
            "order": 1,
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "tipoVenditore",
            "label": "Tipo Venditore",
            "controlType": "dropdown",
            "class": "lg:w-2/12 w-full",
            "order": 1,
            "required": true,
            "options": this.data['sellerTypes'] ?? []
          },
          {
            "classToUse": this.classToCall,
            "key": "agenziaSubagente",
            "controlType": "autocomplete-subagent-agency",
            "label": "Agenzia Sub-Agente",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "required": false,
            "options": this.data['agencySubagent'] ?? []
          },
          {
            "classToUse": this.classToCall,
            "key": "enabled",
            "controlType": "slide-toggle",
            "label": "Abilitato",
            "default": true,
            "order": 1,
            "class": "lg:w-3/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "segnalatore",
            "controlType": "slide-toggle",
            "label": "Segnalatore",
            "default": false,
            "order": 1,
            "class": "lg:w-3/12 w-full",
          },

        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "id",
            "controlType": "hidden",
            "label": "",
            "order": 1,
          },
          {
            "classToUse": this.classToCall,
            "key": "creator",
            "controlType": "hidden",
            "label": "",
            "order": 1,
          },
        ],
        "order": 1
      },
    ]
  }
}
