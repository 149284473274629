import {FormSetting} from "@/form-settings/form-setting";

export class ServicePracticeFormSetting extends FormSetting {

  protected static classToCall: string

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static getMeta(): Array<any> {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "tipoVeicolo",
            "controlType": "dropdown",
            "label": "Tipo Veicolo",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "required": true,
            "options": [
              {key: 'NUOVO', value: 'NUOVO'},
              {key: 'USATO', value: 'USATO'}
            ]
          },
          {
            "classToUse": this.classToCall,
            "key": "tipoServizio",
            "controlType": "dropdown",
            "label": "Tipo Servizio",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "required": true,
            "options": [
              {key: 'LOYALTY', value: 'LOYALTY'},
              {key: 'FINANZIARI', value: 'FINANZIARI'},
              {key: 'ALTRI_SERVIZI', value: 'ALTRI_SERVIZI'}
            ]
          },
          {
            "classToUse": this.classToCall,
            "key": "tipoServizioAssicurativo",
            "controlType": "autocomplete-type-insurance-service",
            "label": "Tipo Servizio Assicurativo",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "required": true,
            "options": this.data['typeInsuranceService'] ?? []
          },
          {
            "classToUse": this.classToCall,
            "key": "punteggio",
            "controlType": "number",
            "label": "Punteggio",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "provvigioneAccessorio",
            "label": "Provvigione accessorio",
            "controlType": "number",
            "class": "lg:w-3/12 w-full",
            "order": 1
          },
          {
            "classToUse": this.classToCall,
            "key": "enabled",
            "controlType": "slide-toggle",
            "label": "Abilitato",
            "default": true,
            "order": 1,
            "class": "lg:w-3/12 w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "descrizione",
            "label": "Descrizione",
            "controlType": "textinput",
            "class": "lg:w-12/12 w-full",
            "order": 1,
            "required": true,
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "note",
            "label": "Note",
            "controlType": "textinput",
            "class": "lg:w-12/12 w-full",
            "order": 1,
            "required": false,
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "id",
            "controlType": "hidden",
            "label": "",
            "order": 1,
          },
          {
            "classToUse": this.classToCall,
            "key": "creator",
            "controlType": "hidden",
            "label": "",
            "order": 1,
          },
        ],
        "order": 1
      },
    ]
  }
}
