import {Component, inject, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {CashMovementsAnalisisSettings} from "@/table-settings/cash-management/cash-movements-analisis-settings";
import {CashMovementsAnalisisDataSource} from "@components/new-table/data-sources/cash-movements-analisis-data-source";
import {CashMovementsAnalisisService} from "@services/cash-movements.analisis.service";

@Component({
  selector: 'app-table-ms',
  templateUrl: './cash-movements-analisis.component.html',
  styleUrls: ['./cash-movements-analisis.component.scss']
})
export class CashMovementsAnalisisComponent implements OnInit {
  modalTrigger$ = new Subject<any>();
  triggerDate$ = new Subject<any>();
  triggerSelection$ = new Subject<any>();
  reloadTrigger$ = new Subject<any>();
  displayedColumns = CashMovementsAnalisisSettings.getColumnsSetting();
  dataSource = new CashMovementsAnalisisDataSource(this.cashMovementsAnalisisService);
  toastr = inject(ToastrService);

  constructor(private cashMovementsAnalisisService: CashMovementsAnalisisService) {}

  ngOnInit(): void {
    this.modalTrigger$.subscribe({
      next: () => {
        this.reloadTrigger$.next(1)
      }
    });
  }
}
