import {ButtonOpenLinkMenuBiComponent} from "@components/button/menu-bi/button-open-link-menu-bi.component";
import {AreaBiRendererComponent} from "@components/table-column-renderer/area-bi-renderer.component";

export class MenuBiSettings {

  public static gridSettings() {
    return {
      actions: false,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager:
        {
          display: true,
          perPage: 25
        },
      columns: {
        openLinkButton: {
          type: 'custom',
          renderComponent: ButtonOpenLinkMenuBiComponent,
          editable: false,
          addable: false,
          filter: false,
        },
        areaBi: {
          title: 'Area',
          type: 'custom',
          renderComponent: AreaBiRendererComponent,
          width: '120px',
          filter: {
            type: 'list',
            config: {
              list: [],
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            return cell == search;
          },
        },
        name: {
          title: 'Nome'
        },
        descrizione: {
          title: 'Descrizione'
        },
      },
      delete: null
    };
  }
}
