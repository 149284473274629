import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'columnsName'
})
export class ColumnsNamePipe implements PipeTransform {

  transform<T extends Record<string, any>>(arr: T[], prop: keyof T, mode?: 'filter' | 'form'): string[] {
    switch (mode) {
      case "filter":
        return arr.map(e => e[prop] + 'Filtro');
      case "form":
        const transform = arr.filter(e =>
          !['btnDelete', 'btnDetails'].includes(e[prop])).map(e =>
          e[prop] + 'FormField');
        return ['btnSubmitForm', 'btnClearForm'].concat(transform);
      default:
        return arr.map(e => e[prop]);
    }
  }

}
