import {Component, Input, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {PracticesService} from "@services/practices.service";
import {ButtonComponent} from "@components/button/button.component";
import {saveAs} from "file-saver";

@Component({
  selector: 'app-button-export-excel-delivery-card-board',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss'],
})
export class ButtonExportExcelDeliveryCardBoardComponent extends ButtonComponent implements OnInit {

  @Input() triggerDate$ = new Subject<any>();
  private year: string;
  private month: string;

  constructor(protected router: Router, protected toastr: ToastrService, private practiceService: PracticesService) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'ml-2'
    this.content = 'Scarica numeratore consegne'
  }

  ngOnInit(): void {
    this.triggerDate$.subscribe({
      next: (objectDate) => {
        this.month = objectDate.month
        this.year = objectDate.year
      }
    });
  }

  public actionClick(): void {
    this.loading = true
    this.practiceService.exportDeliveryCardBoard(this.year, this.month).subscribe((resp) => {
      saveAs(resp, `NumeratoreConsegne_${this.year}_${this.month}.xlsx`);

      this.triggerDate$.next({
        'year': this.year,
        'month': this.month
      })
      this.loading = false
    });
  }
}
