<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link">
  <img
    alt="AdminLTE Logo"
    class="brand-image elevation-3"
    src="assets/img/logo.png"
    style="opacity: 0.8"
  />
  <span class="brand-text leading-9">Progetto<b>Crespi</b></span>
</a>

<!-- Sidebar -->
<div class="sidebar">
  <!-- Sidebar Menu -->
  <nav class="mt-2" style="overflow-y: hidden">
    <ul
      class="nav nav-pills nav-sidebar flex-column pb-28"
      data-accordion="false"
      data-widget="treeview"
      role="menu"
    >
      <app-menu-item
        *ngFor="let item of menu"
        [menuItem]="item"
      ></app-menu-item>
    </ul>
  </nav>
</div>
