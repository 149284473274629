import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {
  DialogRentalPracticesComponent
} from "@pages/tables/rental-practices/dialog-rental-practices/dialog-rental-practices.component";
import {RentalPracticesModel} from "@/models/tables/rental-practices.model";

@Component({
  selector: 'app-button-new-rental-practice',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonNewRentalPracticeComponent extends ButtonComponent {
  private dialogOpened: MatDialogRef<any>
  faIcon = faPenToSquare;

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'ml-2'
    this.content = 'Nuova Pratica Noleggio'
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogRentalPracticesComponent, {
      data: new RentalPracticesModel(),
    });

    Registry.getInstance().set('DialogRentalPracticeComponentNew', this.dialogOpened)
  }

}
