import {Component} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {OptionSelect} from "@/models/option-select.model";
import {BigBuyersService} from "@services/tables/big-buyers.service";
import {BigBuyersModel} from "@/models/tables/big-buyers.model";
import {BigBuyersFormSettings} from "@/form-settings/big-buyers-form-settings";

@Component({
  selector: 'app-big-buyers-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class BigBuyersFormComponent extends DynamicFormComponent {
  private brands: OptionSelect[] = [];
  private customerTypes: OptionSelect[] = [];

  constructor(protected router: Router, protected userService: UserService, protected bigBuyersService: BigBuyersService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())

    this.bigBuyersService.getAllCustomerTypes().subscribe(customerTypes => {
      for (const customerType of customerTypes) {
        this.customerTypes.push({
          key: customerType,
          value: customerType
        });
      }

      this.setupMeta()
    });

    this.bigBuyersService.getAllBrands().subscribe({
      next: (brands) => {
        for (const brand of brands) {
          this.brands.push({
            key: brand,
            value: brand
          });
        }

        this.setupMeta()
      },
      error: (error) => {
        LoggerService.error('getAllBrands failed!');
        LoggerService.error(error);
      },
      complete: () => {
        LoggerService.log('Request done')
      },
    })
  }

  onSubmit() {
    let bigBuyersModel = <BigBuyersModel>this.form.getRawValue()

    this.bigBuyersService.saveBigBuyers(bigBuyersModel).subscribe({
      next: () => {
        this.toastr.success('Grande acquirente salvato correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveSeller failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio del Grande acquirente.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = BigBuyersFormSettings
      .set('customerTypes', this.customerTypes)
      .set('brands', this.brands)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogBigBuyersComponentModify').close()
    } else {
      Registry.getInstance().get('DialogBigBuyersComponentNew').close()
    }

    Registry.getInstance().get('BigBuyersComponent').loadBigBuyers()
  }

  protected secondaryButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('DialogBigBuyersComponentModify').close()
    } else {
      Registry.getInstance().get('DialogBigBuyersComponentNew').close()
    }
  }

}
