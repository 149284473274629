import {BrandNewRendererComponent} from "@components/table-column-renderer/brand-new-renderer.component";
import {ButtonDetailSellerComponent} from "@components/button/tables/seller/button-modify-seller.component";
import {ButtonDeleteSellerComponent} from "@components/button/tables/seller/button-delete-seller.component";
import {SubagentAgencyRendererComponent} from "@components/table-column-renderer/subagent-agency-renderer.component";
import {SellersUsersRendererComponent} from "@components/table-column-renderer/sellers-users-renderer.component";
import {SellerTypesRendererComponent} from "@components/table-column-renderer/seller-types-renderer.component";

export class SellerSettings {

  public static gridSettings() {
    return {
      actions: false,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager:
        {
          display: false
        },
      columns: {
        detailButton: {
          type: 'custom',
          renderComponent: ButtonDetailSellerComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        deleteButton: {
          type: 'custom',
          renderComponent: ButtonDeleteSellerComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        id: {
          title: 'Id',
          width: '70px'
        },
        codiceVenditore: {
          title: 'Codice'
        },
        descrizione: {
          title: 'Descrizione'
        },
        users: {
          title: 'Utenti',
          type: 'custom',
          renderComponent: SellersUsersRendererComponent,
          width: '120px',
          filter: {
            type: 'list',
            config: {
              list: [],
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            if(cell.length > 0)
              return cell.find(u => u.username === search) != null;
            else
              return false;
          },
        },
        marca: {
          title: 'Marca',
          type: 'custom',
          renderComponent: BrandNewRendererComponent,
          width: '120px',
          filter: {
            type: 'list',
            config: {
              list: [],
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            return cell == search;
          },
        },
        tipoVenditore: {
          title: 'Tipo venditore',
          width: '110px',
          type: 'custom',
          renderComponent: SellerTypesRendererComponent,
          filter: {
            type: 'list',
            config: {
              list: [],
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            return cell == search;
          },
        },
        agenziaSubagente: {
          title: 'Agenzia sub-agente',
          type: 'custom',
          renderComponent: SubagentAgencyRendererComponent,
          width: '120px',
          filter: {
            type: 'list',
            config: {
              list: [],
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            if (cell.id == search) {
              return true;
            } else {
              return false;
            }
          },
        },
        segnalatore: {
          width: '130px',
          title: 'Segnalatore',
          valuePrepareFunction: (segnalatore) => {
            if (segnalatore) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
        enabled: {
          width: '130px',
          title: 'Abilitato',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
      },
      delete: null
    };
  }
}
