export class User {
  id: number;
  username: string;
  password: string;
  name: string;
  lastname: string;
  mobileNumber: string;
  email: string;
  roles: string[] = [];
  token: string;
  picture: string;
  companyCodes: string[] = []
  enabled: boolean
}
