import {Component} from '@angular/core';
import {AutoCompleteComponent} from "@components/dynamic-form/auto-complete/auto-complete.component";

@Component({
  selector: 'app-auto-complete-bank-payment',
  templateUrl: './auto-complete-bank-payment.component.html',
  styleUrls: ['../../auto-complete.component.scss']
})
export class AutoCompleteBankPaymentComponent extends AutoCompleteComponent {
  faIcon: any;
  showAlert: boolean = false;

  displayFn(selectedOption: any): string {
    return selectedOption && selectedOption.descrizione && selectedOption.contoContabile ?
      `${selectedOption.contoContabile} - ${selectedOption.descrizione}` :
      '';
  }

  getValueFormatted(bank): string {
    return bank && bank.descrizione && bank.contoContabile ? `${bank.contoContabile} - ${bank.descrizione}` : ''
  }

}
