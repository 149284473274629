import {Component, Input} from '@angular/core';
import {ControlBase} from "@components/dynamic-form/control-base";
import {FormGroup} from "@angular/forms";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent extends InputFieldComponent {
  @Input() meta!: ControlBase
  @Input() form!: FormGroup

  protected customActionInput(event, indexRow = null, inputElement?: HTMLInputElement) {
    LoggerService.log('Input Changed!')

    if (this.meta.classToUse && this.meta.customAction && Registry.getInstance().get(this.meta.classToUse)[this.meta.customAction]) {
      Registry.getInstance().get(this.meta.classToUse)[this.meta.customAction](event.checked, indexRow)
    } else {
      LoggerService.log('Any function triggered')
    }
  }
}
