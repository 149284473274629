import {Injectable} from '@angular/core';
import {TableDataApiParams} from "@services/practices.service";
import {TableStockModel} from "@/models/tables/stock-table.model";
import {StockService} from "@services/stock.service";
import {DataSourceBaseNew} from "@components/new-table/data-source-base-new.dataSource";

@Injectable({
  providedIn: 'root'
})
export class StockManagementDataSource extends DataSourceBaseNew<TableStockModel>{
  constructor(private stockService: StockService) {
    super();
  }
  loadData(params?: TableDataApiParams): void {
    this._isLoading$.next(true);
    this.stockService.getTableData(params).subscribe(data => {
      this.elements$.next(data.content);
      this._totalElements$.next(data.totalElements);
      this._isLoading$.next(false);
    })
  }
}
