import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {ServicePracticesModel} from "@/models/tables/service-practices.model";

@Injectable({
  providedIn: 'root'
})
export class ServicePracticesService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/servicePractices`);
  }

  getAllServicePractices(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/servicePractices/insuranceServiceType`);
  }

  deleteServicePractice(servicePracticeId: number) {
    return this.http.delete(this.baseUrl + `/tables/servicePractices/${servicePracticeId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteServicePractice')
      return response;
    }));
  }

  saveServicePractice(servicePractice: ServicePracticesModel) {
    return this.http.post(this.baseUrl + `/tables/servicePractices/save`, servicePractice).pipe(map((response: any) => {
      LoggerService.log(response, 'saveServicePractice')
      return response;
    }));
  }
}
