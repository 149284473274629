<div [formGroup]="form">
  <mat-label class="label-form">{{meta.label}}:</mat-label>
  <mat-form-field appearance="fill" class="w-full additional-border-input" [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
    <mat-label>{{meta.label}}</mat-label>
    <input matInput
           [formControlName]="meta.key"
           [value]="meta.value"
           type="email"
           placeholder="Inserisci la tua mail">
  </mat-form-field>
  <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>
</div>
