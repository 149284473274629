import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {BrandModel} from "@/models/tables/brand.model";
import {DialogBrandComponent} from "@pages/tables/brand/dialog-brand/dialog-brand.component";

@Component({
    selector: 'app-button-new-brand',
    templateUrl: '../../button.component.html',
    styleUrls: ['../../button.component.scss'],
})
export class ButtonNewBrandComponent extends ButtonComponent {
    private dialogOpened: MatDialogRef<any>
    faIcon = faPenToSquare;

    constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
        super(router, toastr);

        this.showIcon = false
        this.additionalClass = 'ml-2'
        this.content = 'Nuova marca'
    }

    /**
     * Open modal new detail page
     */
    public actionClick(): void {
        this.dialogOpened = this.dialog.open(DialogBrandComponent, {
            data: {} as BrandModel,
        });

        Registry.getInstance().set('DialogBrandComponentNew', this.dialogOpened)
    }

}
