<div [formGroup]="form">
  <div [ngClass]="control.disabled ? 'disabled-dropdown' : 'active'">
    <mat-label class="label-form">{{ meta.label }}:</mat-label>
    <mat-form-field class="w-full additional-border-input mt-1" appearance="fill"
                    [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
      <mat-label>{{ meta.label }}</mat-label>
      <input type="text" matInput [formControl]="control" [matAutocomplete]="auto"
             (change)="customActionChange($event)">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="customActionInput($event)">
        <mat-option *ngFor="let action of filteredOptions | async" [value]="action">
          {{ getValueFormatted(action) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>
</div>
