import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {Injectable} from "@angular/core";
import {TableService} from "@services/commons/table.service";
import {CashMovementsModel} from "@/models/cash-movements.model";

@Injectable({
  providedIn: 'root'
})
export class CashMovementsService extends TableService {

  getDepartments() {
    return this.http.get(this.baseUrl + `/cashMovements/departments`).pipe(map((response: any) => {
      LoggerService.log(response, 'getDepartments')

      return response;
    }));
  }

  getMovementTypes() {
    return this.http.get(this.baseUrl + `/cashMovements/movementTypes`).pipe(map((response: any) => {
      LoggerService.log(response, 'getMovementTypes')

      return response;
    }));
  }

  getMovementsById(cashMovementsId: number) {
    return this.http.get(this.baseUrl + `/cashMovements/${cashMovementsId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getMovementsById')
      return response;
    }));
  }

  deleteCashMovements(cashMovementsId: number) {
    return this.http.delete(this.baseUrl + `/cashMovements/${cashMovementsId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteCashMovements')
      return response;
    }));
  }

  saveCashMovements(cashMovementsModel: CashMovementsModel) {
    return this.http.post(this.baseUrl + `/cashMovements/save`, cashMovementsModel).pipe(map((response: any) => {
      LoggerService.log(response, 'saveCashMovements')
      return response;
    }));
  }

  getBankAccounts() {
    return this.http.get(this.baseUrl + `/cashMovements/bankAccounts`).pipe(map((response: any) => {
      LoggerService.log(response, 'getBankAccounts')

      return response;
    }));
  }
}
