import {Component, OnInit} from '@angular/core';
import {PracticeModel} from "@/models/practice.model";
import {Tab} from "@/interfaces/tab";
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-practice',
  templateUrl: './new-practice.component.html',
  styleUrls: ['./new-practice.component.scss']
})
export class NewPracticeComponent implements OnInit {
  data: any = {}
  tabs: Array<Tab> = []
  faIcon: any

  constructor(protected router: Router) {
    this.data = {} as PracticeModel;
  }

  async ngOnInit() {
    this.tabs = [
      {
        'title': 'Dettaglio',
      },
    ]
  }

  buttonBack() {
    this.router.navigate(['practices-management/practices']);
  }
}
