import {
  ButtonDetailPracticeAccessoryComponent
} from "@components/button/practices/button-detail-practice-accessory.component";
import {
  ButtonDeletePracticeAccessoryComponent
} from "@components/button/practices/button-delete-practice-accessory.component";

export class AccessoriesSettings {

  public static gridSettings() {
    return {
      actions: {
        columnTitle: 'Accessori',
        add: false,
        edit: false,
        delete: false,
      },
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table',
      },
      pager: {
        display: false,
      },
      columns: {
        detailButton: {
          type: 'custom',
          renderComponent: ButtonDetailPracticeAccessoryComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        deleteButton: {
          type: 'custom',
          renderComponent: ButtonDeletePracticeAccessoryComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        descrizione: {
          title: 'Descrizione'
        },
        prezzo: {
          title: 'Prezzo',
          sortDirection: 'desc',
          width: '120px',
          type: 'html',
          valuePrepareFunction: (data) => {
            return '<p class="cell_right">' + Intl.NumberFormat('it-IT', {
              style: 'currency',
              currency: 'EUR'
            }).format(data) + '</p>';
          },
          filter: false
        },
        provvigione: {
          title: 'Provvigione',
          width: '120px',
          type: 'html',
          valuePrepareFunction: (data) => {
            return '<p class="cell_right">' + Intl.NumberFormat('it-IT', {
              style: 'currency',
              currency: 'EUR'
            }).format(data) + '</p>';
          },
          filter: false
        },
      },
      delete: null,
    };
  }
}
