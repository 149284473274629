import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {ContactSourceModel} from "@/models/tables/contact-source.model";
import {
  DialogContactSourceComponent
} from "@pages/tables/contact-source/dialog-contact-source/dialog-contact-source.component";


@Component({
  selector: 'app-button-new-contact-source',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonNewContactSourceComponent extends ButtonComponent {
  private dialogOpened: MatDialogRef<any>
  faIcon = faPenToSquare;

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'ml-2'
    this.content = 'Nuova Origine Contatto'
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogContactSourceComponent, {
      data: new ContactSourceModel(),
    });

    Registry.getInstance().set('DialogContactSourceComponentNew', this.dialogOpened)
  }

}
