import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

  @Output() btnUpdateClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input() tableTitle: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
