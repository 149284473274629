import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ControlBase} from "@components/dynamic-form/control-base";
import {FormGroup} from "@angular/forms";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordComponent extends InputFieldComponent {
  @Input() meta!: ControlBase
  @Input() form!: FormGroup
  protected hide: boolean = true
}
