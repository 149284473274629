import {ApiService} from "@services/api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {Injectable} from "@angular/core";
import {ModelsModel} from "@/models/tables/models.model";

@Injectable({
  providedIn: 'root'
})
export class ModelsService extends ApiService {
  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/models`);
  }

  getAllBrands(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/models/brands`);
  }

  deleteModel(modelId: number) {
    return this.http.delete(this.baseUrl + `/tables/models/${modelId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteModel')

      return response;
    }));
  }

  saveModel(model: ModelsModel) {
    return this.http.post(this.baseUrl + `/tables/models/save`, model).pipe(map((response: any) => {
      LoggerService.log(response, 'saveModel')

      return response;
    }));
  }
}
