import {CustomerTypeRendererComponent} from "@components/table-column-renderer/customer-type-renderer.component";
import {BrandNewRendererComponent} from "@components/table-column-renderer/brand-new-renderer.component";
import {ButtonDetailBigBuyersComponent} from "@components/button/tables/big-buyers/button-modify-big-buyer.component";
import {ButtonDeleteBigBuyersComponent} from "@components/button/tables/big-buyers/button-delete-big-buyer.component";

export class BigBuyersSettings {

  public static gridSettings() {
    return {
      actions: false,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager:
        {
          display: true,
          perPage: 25
        },
      columns: {
        detailButton: {
          type: 'custom',
          renderComponent: ButtonDetailBigBuyersComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        deleteButton: {
          type: 'custom',
          renderComponent: ButtonDeleteBigBuyersComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        id: {
          title: 'Id',
          width: '70px'
        },
        tipoCliente: {
          title: 'Tipo cliente',
          type: 'custom',
          renderComponent: CustomerTypeRendererComponent,
          width: '120px',
          filter: {
            type: 'list',
            config: {
              list: [],
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            return cell == search;
          },
        },
        marca: {
          title: 'Marca',
          type: 'custom',
          renderComponent: BrandNewRendererComponent,
          width: '120px',
          filter: {
            type: 'list',
            config: {
              list: [],
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            return cell == search;
          },
        },
        codiceGA: {
          title: 'Codice'
        },
        descrizione: {
          title: 'Descrizione'
        },
        enabled: {
          title: 'Abilitato',
          width: '130px',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
      },
      delete: null
    };
  }
}
