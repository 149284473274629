import {Component, HostBinding, OnDestroy, OnInit, Renderer2, ViewEncapsulation,} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '@services/auth.service';
import {environment} from "../../../environments/environment";
import {DIST_VERSION} from "../../../environments/dist.version";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'login-box';
  public loginForm: UntypedFormGroup;
  public isAuthLoading = false;
  public isGoogleLoading = false;
  public isFacebookLoading = false;
  showPassword: boolean = false;
  environment: string = environment.env;
  version?: string = DIST_VERSION;

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private authService: AuthService,
  ) {
    localStorage.removeItem('cassa.jwttoken');
    localStorage.removeItem('cassa.userRoles');
    localStorage.removeItem('cassa.userName');
  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'login-page',
    );
    this.renderer.addClass(
      document.querySelector('app-root'),
      'dark-mode',
    );
    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required),
    });
    this.loginForm.get('username').valueChanges.subscribe((event) => {
      this.loginForm.get('username').setValue(event.toLowerCase(), {emitEvent: false});
    })
  }

  async loginByAuth() {
    if (this.loginForm.valid) {
      this.isAuthLoading = true;
      this.authService.login(this.loginForm.value);
      this.isAuthLoading = false;
    } else {
      this.toastr.error('Form is not valid!');
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page',
    );
  }
}
