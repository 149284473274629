<div id="content-app">
  <app-status-bar *ngIf="environment === 'stage'"></app-status-bar>
  <app-header class="sticky top-0"></app-header>
  <app-menu-sidebar class="sidebar-dark-primary" [ngClass]="{'mt-10': environment=== 'stage'}"></app-menu-sidebar>
  <div class="content-wrapper px-3 pt-6">
    <app-alert *ngIf="showAlert" [type]="typeAlert" [message]="alertMessage"></app-alert>
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
  <app-control-sidebar></app-control-sidebar>
  <div id="sidebar-overlay" (click)="onToggleMenuSidebar()"></div>
</div>
