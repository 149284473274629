import {Component, Input, OnInit} from '@angular/core';
import {faCheckCircle, faExclamationCircle, faInfoCircle} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() type: string;
  @Input() message: string;
  mainClass: string;
  iconClass: string;
  faIcon = faInfoCircle;

  constructor() {
  }

  ngOnInit(): void {
    switch (this.type) {
      case 'ERROR':
        this.mainClass = 'alert_error'
        this.faIcon = faExclamationCircle
        this.iconClass = 'icon_alert_error'

        break
      case 'SUCCESS':
        this.mainClass = 'alert_success'
        this.faIcon = faCheckCircle
        this.iconClass = 'icon_alert_sucess'

        break
      default:
        this.mainClass = 'alert_info'
        this.iconClass = 'icon_alert_info'
        this.faIcon = faInfoCircle
    }
  }

}
