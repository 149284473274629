import {Component, Input, OnInit} from '@angular/core';
import {PracticeModel} from "@/models/practice.model";

@Component({
  selector: 'app-financial-data-analysis',
  templateUrl: './financial-data-analysis.component.html',
  styleUrls: ['./financial-data-analysis.component.scss']
})
export class FinancialDataAnalysisComponent implements OnInit {
  webappList: any[] = [];
  vwfsList: any[] = [];
  @Input() practiceData: PracticeModel;

  ngOnInit(): void {
    for (const service of this.practiceData.analisiDatiFinanziari.serviziWebapp ?? []) {
      this.webappList.push(service);
    }

    for (const service of this.practiceData.analisiDatiFinanziari.serviziCross ?? []) {
      this.vwfsList.push(service);
    }
  }


}
