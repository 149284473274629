import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {RentalAgencyModel} from "@/models/tables/rental-agency.model";

@Injectable({
  providedIn: 'root'
})
export class RentalAgencyService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/rentalAgency`);
  }

  deleteRentalAgency(rentalAgencyId: number) {
    return this.http.delete(this.baseUrl + `/tables/rentalAgency/${rentalAgencyId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteRentalAgency')

      return response;
    }));
  }

  saveRentalAgency(rentalAgency: RentalAgencyModel) {
    return this.http.post(this.baseUrl + `/tables/rentalAgency/save`, rentalAgency).pipe(map((response: any) => {
      LoggerService.log(response, 'saveRentalAgency')

      return response;
    }));
  }
}
