<div class="top-fields-container">
  <div class="field-box">
    <label for="formaTecnicaCross">Forma Tecnica VWFS</label>
    <input [value]="practiceData.analisiDatiFinanziari.formaTecnicaCross" id="formaTecnicaCross" readonly
           type="text">
  </div>
  <div class="field-box">
    <label for="codiceTabellaFinWebapp">Codice Tabella Fin. Webapp</label>
    <input [value]="practiceData.analisiDatiFinanziari.codiceTabellaFinWebapp"
           id="codiceTabellaFinWebapp" readonly
           type="text">
  </div>
  <div class="field-box">
    <label for="codiceTabellaFinCross">Codice Tabella Fin. VWFS</label>
    <input [value]="practiceData.analisiDatiFinanziari.codiceTabellaFinCross"
           id="codiceTabellaFinCross" readonly
           type="text">
  </div>
  <div class="field-box">
    <label for="numeroRateFinanziamentoCross">Numero Rate Fin. VWFS</label>
    <input [value]="practiceData.analisiDatiFinanziari.numeroRateFinanziamentoCross"
           id="numeroRateFinanziamentoCross"
           readonly type="text">
  </div>
  <div class="field-box">
    <label for="numeroRateFinanziamentoWebapp">Numero Rate Fin. Webapp</label>
    <input [value]="practiceData.analisiDatiFinanziari.numeroRateFinanziamentoWebapp"
           id="numeroRateFinanziamentoWebapp"
           readonly type="text">
  </div>
  <!-- Add more fields as needed -->
</div>
<div class="list-section-container">
  <div class="table-container">
    <h3>WebApp - Servizi Finanziari e Assicurativi</h3>
    <table class="styled-table">
      <thead>
      <tr>
        <th>Descrizione</th>
        <th>Punteggio</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let service of webappList">
        <td>{{ service.descrizione }}</td>
        <td>{{ service.punteggio }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="separator"></div>

  <div class="table-container">
    <h3>VWFS Dati da Assicurazioni</h3>
    <table class="styled-table">
      <thead>
      <tr>
        <th>Descrizione</th>
        <th>Punteggio</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let service of vwfsList">
        <td>{{ service.descrizione }}</td>
        <td>{{ service.punteggio }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
