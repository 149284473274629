import {Component} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {LocalDataSource} from 'ng2-smart-table';
import {UsersSettings} from '@/table-settings/admin/users-settings';
import {UserService} from "@services/admin/user.service";
import {Router} from "@angular/router";
import {Registry} from "@/registry.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  sourceUsersTable: LocalDataSource;
  settings = UsersSettings.gridSettings();

  constructor(private router: Router, private formBuilder: FormBuilder, private userService: UserService) {
    this.sourceUsersTable = new LocalDataSource([]);

    Registry.getInstance().set('UsersComponent', this)

    this.refreshUsers();
  }

  refreshUsers() {
    this.userService.searchUser().subscribe((users) => {
      this.sourceUsersTable.load(users);
    });
  }

}
