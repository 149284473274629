import {Component, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {VehicleAccessoryService} from "@services/tables/vehicle-accessory.service";
import {VehicleAccessoryFormSetting} from "@/form-settings/vehicle-accessory-form-setting";
import {VehicleAccessoryModel} from "@/models/tables/vehicle-accessory.model";

@Component({
  selector: 'app-vehicle-accessory-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class VehicleAccessoryFormComponent extends DynamicFormComponent implements OnInit {
  private models: Array<any> = [];

  constructor(protected router: Router, protected userService: UserService, protected vehicleAccessoryService: VehicleAccessoryService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'

    this.vehicleAccessoryService.getAllModels().subscribe(models => {
      this.models = models

      this.setupMeta()
    });
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())
  }

  onSubmit() {
    let vehicleAccessoryModel = <VehicleAccessoryModel>this.form.getRawValue()

    this.vehicleAccessoryService.saveVehicleAccessory(vehicleAccessoryModel).subscribe({
      next: () => {
        this.toastr.success('Accesorio Veicolo salvato correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveVehicleAccessory failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio dell'Accesorio Veicolo.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = VehicleAccessoryFormSetting
      .set('modelsAnag', this.models)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogVehicleAccessoryComponentModify').close()
    } else {
      Registry.getInstance().get('DialogVehicleAccessoryComponentNew').close()
    }

    Registry.getInstance().get('VehicleAccessoryComponent').loadVehicleAccessory()
  }

  protected secondaryButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('DialogVehicleAccessoryComponentModify').close()
    } else {
      Registry.getInstance().get('DialogVehicleAccessoryComponentNew').close()
    }
  }

}
