<div class="block">
  <div [formGroup]="form" class="flex w-full flex-wrap">
    <div class="col-md-12 mt-3 mb-2 p-1 text-center title-repeater w-full">
      <p class="m-auto font-bold text-base">{{ meta.title }}</p>
    </div>
    <div [formArrayName]="meta.key" class="w-11/12 flex flex-wrap">
      <div *ngFor="let control of meta.repeaterRows" [class]="control.row.class">
        <div [ngSwitch]="control.row.controlType">
          <div *ngSwitchCase="'textinput'" [formGroupName]="indexRow" [formGroup]="form" class="input-repeater">
            <app-text-input
              [form]="form"
              [indexRow]="control.index"
              [keyformArrayName]="meta.key"
              [meta]="control.row"
              class="w-full"></app-text-input>
          </div>
          <div *ngSwitchCase="'uploadFile'" [formGroupName]="indexRow" [formGroup]="form" class="input-repeater">
            <app-upload-input
              *ngSwitchCase="'uploadFile'"
              [form]="form"
              [indexRow]="control.index"
              [keyformArrayName]="meta.key"
              [meta]="control.row"
              [triggerMethodSubject]="triggerMethodSubject"
              class="w-full"
            ></app-upload-input>
          </div>
          <div *ngSwitchCase="'date'" [formGroupName]="indexRow" [formGroup]="form" class="input-repeater">
            <app-date-input
              [form]="form"
              [indexRow]="control.index"
              [keyformArrayName]="meta.key"
              [meta]="control.row"
              class="w-full"></app-date-input>
          </div>
          <div *ngSwitchCase="'autocomplete-string'" class="input-repeater">
            <app-auto-complete-string
              [form]="form"
              [indexRow]="control.index"
              [keyformArrayName]="meta.key"
              [meta]="control.row"
              class="w-full"></app-auto-complete-string>
          </div>
          <div *ngSwitchCase="'autocomplete'" class="input-repeater">
            <app-auto-complete
              [form]="form"
              [indexRow]="control.index"
              [keyformArrayName]="meta.key"
              [meta]="control.row"
              class="w-full"></app-auto-complete>
          </div>
          <div *ngSwitchCase="'autocomplete-action-codes'" class="input-repeater">
            <app-auto-complete-action-codes
              [form]="form"
              [indexRow]="control.index"
              [keyformArrayName]="meta.key"
              [meta]="control.row"
              class="w-full"></app-auto-complete-action-codes>
          </div>
          <div *ngSwitchCase="'autocomplete-loyalty-services'" class="input-repeater">
            <app-auto-complete-loyalty-services
              [form]="form"
              [indexRow]="control.index"
              [keyformArrayName]="meta.key"
              [meta]="control.row"
              class="w-full"></app-auto-complete-loyalty-services>
          </div>
          <div *ngSwitchCase="'autocomplete-financial-services'" class="input-repeater">
            <app-auto-complete-financial-services
              [form]="form"
              [indexRow]="control.index"
              [keyformArrayName]="meta.key"
              [meta]="control.row"
              class="w-full"></app-auto-complete-financial-services>
          </div>
          <div *ngSwitchCase="'dropdown'" class="input-repeater">
            <app-dropdown-form
              [form]="form"
              [indexRow]="control.index"
              [keyformArrayName]="meta.key"
              [meta]="control.row"
              class="w-full"></app-dropdown-form>
          </div>
          <div *ngSwitchCase="'number'" class="input-repeater">
            <app-number-input
              [form]="form"
              [indexRow]="control.index"
              [keyformArrayName]="meta.key"
              [meta]="control.row"
              class="w-full"></app-number-input>
          </div>
        </div>
      </div>
    </div>
    <div class="w-1/12">
      <div *ngFor="let buttonDelete of this.deleteButtons"
           class="min-height-row flex items-center justify-center row-{{meta.key}}-{{buttonDelete.index}}">
        <app-button (click)="deleteRow(buttonDelete.index, buttonDelete.start, buttonDelete.end)" [color]="'danger'"
                    [icon]="'delete'"
                    [showIcon]="true"
                    [type]="'button'"></app-button>
      </div>
    </div>
    <div class="w-full">
      <button (click)="addNewRow()" *ngIf="!meta.hideNewRowButton" class="btn btn-primary float-left" type="button">
        {{ meta.buttonRepeater }}
      </button>
    </div>
  </div>
</div>
