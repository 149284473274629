import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {RentalPracticesModel} from "@/models/tables/rental-practices.model";

@Injectable({
  providedIn: 'root'
})
export class RentalPracticesService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/rentalPractices`);
  }

  deleteRentalPractice(rentalPracticeId: number) {
    return this.http.delete(this.baseUrl + `/tables/rentalPractices/${rentalPracticeId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteRentalPractice')
      return response;
    }));
  }

  saveRentalPractice(rentalPractice: RentalPracticesModel) {
    return this.http.post(this.baseUrl + `/tables/rentalPractices/save`, rentalPractice).pipe(map((response: any) => {
      LoggerService.log(response, 'saveRentalPractice')
      return response;
    }));
  }
}
