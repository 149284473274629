import {of} from "rxjs";
import {map} from "rxjs/operators";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {TableColumnsSetting} from "@/interfaces/table-setting";
import {PracticesService} from "@services/practices.service";
import {getCurrentUserRoles} from "@utils-functions";
import {Role} from "@/models/role";

export class PracticeTableSettings {
  static disableDeleteButton: boolean = true;

  public static setRoleDeleteButton(roleDeleteButton: boolean) {
    this.disableDeleteButton = !roleDeleteButton

    return this;
  }

  public static getColumnsSetting(practiceService: PracticesService): TableColumnsSetting<keyof TablePracticeModel> {
    let columns = [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      }
    ]

    if (!this.disableDeleteButton) {
      Array.prototype.push.apply(columns, [
        {
          name: "btnDelete",
          customComponent: true,
          unsortable: true,
        }
      ]);
    }

    Array.prototype.push.apply(columns, [
      {
        name: "id",
        text: "id",
        filter: {
          type: "input",
        }
      },
      {
        name: "tipoPratica",
        text: "tipo pratica",
        filter: {
          type: "select",
          options: practiceService.getPracticeTypes().pipe(map(practiceTypes => practiceTypes.map((practiceType) => ({
            [practiceType]: practiceType
          }))))
        }
      },
      {
        name: "data",
        type: "date",
        text: "data",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "codicePratica",
        text: "N Pratica",
        filter: {
          type: "input"
        }
      }
    ]);

    if (!this.isUserASubAgent()) {
      Array.prototype.push.apply(columns, [
        {
          name: "tipoVetturaAziendale",
          text: "Veicoli aziendali",
          filter: {
            type: "select",
            options: of([
              {
                VA: 'VA',
              },
              {
                VN: 'VN'
              }
            ])
          }
        },
      ]);
    }

    Array.prototype.push.apply(columns, [
      {
        name: "statoPratica",
        text: "stato pratica",
        filter: {
          type: "select",
          options: of([
            {
              IMPORTATA: 'importata',
            },
            {
              GESTITA: 'gestita'
            }
          ])
        }
      },
      {
        name: "statoOrdineVgi",
        text: "Stato ordine Vgi",
        filter: {
          type: "input"
        }
      },
      {
        name: "marca",
        text: "marca",
        filter: {
          type: "select",
          options: practiceService.getAllBrands().pipe(map(brands => brands.map(({
                                                                                   id,
                                                                                   codiceMarca,
                                                                                   descrizioneMarca
                                                                                 }) => ({
            [id]: `${codiceMarca} - ${descrizioneMarca}`
          }))))
        }
      },
      {
        name: "targa",
        text: "Targa",
        filter: {
          type: "input"
        }
      },
      {
        name: "cliente",
        text: "cliente",
        filter: {
          type: "input"
        }
      },
      {
        name: "tipoPagamento",
        text: "tipologia pagamento",
        filter: {
          type: "select",
          options: practiceService.getAllPaymentTypes().pipe(map(paymentTypes => paymentTypes.map(({
                                                                                                     id,
                                                                                                     descrizione
                                                                                                   }) => ({
            [id]: `${descrizione}`
          }))))
        }
      },
      {
        name: "totPunteggioFinanziari",
        text: "T.S. Assicurativi",
        unsortable: true
      },
      {
        name: "totPunteggioLoyalty",
        text: "T.S. Loyalty",
        unsortable: true,
      },
      {
        name: "venditore",
        text: "venditore",
        filter: {
          type: "select",
          options: practiceService.getSellers().pipe(map(sellers => sellers.map(({
                                                                                   id,
                                                                                   descrizione,
                                                                                   codiceVenditore
                                                                                 }) => ({
            [id]: `${codiceVenditore} - ${descrizione}`
          }))))
        }
      },
      {
        name: "codiceModello",
        text: "Codice modello",
        filter: {
          type: "input"
        }
      },
      {
        name: "descrizioneModello",
        text: "Descrizione modello",
        filter: {
          type: "input"
        }
      },
      {
        name: "presenzaPermute",
        text: "Permuta",
        type: "checkbox",
        filter: {
          type: "select",
          options: of([
            {
              true: 'si',
            },
            {
              false: 'no'
            }
          ])
        }
      },
      {
        name: "presenzaAccessori",
        text: "Con accessori",
        type: "checkbox",
        filter: {
          type: "select",
          options: of([
            {
              true: 'si',
            },
            {
              false: 'no'
            }
          ])
        }
      },
      {
        name: "creator",
        text: "creato da",
        filter: {
          type: "input",
        }
      },
      {
        name: "created",
        type: "datetime",
        text: "creato il",
        filter: {
          type: 'dateRange'
        }
      }
    ]);

    return columns;
  }

  private static isUserASubAgent() {
    return getCurrentUserRoles().includes(Role.Subagents)
      || getCurrentUserRoles().includes(Role.ResponsibleSubagents)
  }
}
