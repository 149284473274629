import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TableDataApiParams} from "@services/practices.service";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {debounceTime, startWith, tap} from "rxjs/operators";
import {combineLatest, Subject} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {TableColumnsSetting} from "@/interfaces/table-setting";
import {faCircleMinus, faCirclePlus, faPenToSquare, faXmark, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {DataSourceBaseNew} from "@components/new-table/data-source-base-new.dataSource";
import {isControlDisabled} from "@utils-functions";
import moment from "moment";
import {LoggerService} from "@services/logger.service";

@Component({
  selector: 'app-new-table',
  templateUrl: './new-table.component.html',
  styleUrls: ['./new-table.component.scss'],
})
export class NewTableComponent<T extends string, D extends DataSourceBaseNew<T>> implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  // doesn't matter the type of the subject, it is only needed for triggering updates
  @Input() reloadTrigger$ = new Subject<any>();
  @Input() triggerSelector$ = new Subject<any>();
  @Input() triggerDate$ = new Subject<any>();
  @Input() displayedColumns: TableColumnsSetting<T> = [];
  @Input() newItemInlineColumnsSetting: TableColumnsSetting<T> = [];
  @Input() hideButtonDelete: boolean = false;
  @Input() initialSort: Sort = {
    active: '',
    direction: ''
  };
  @Input() btnCustomToolTip: string;
  @Input() btnCustomFaIcon: IconDefinition;
  @Input() dataSource: D;
  @Input() inlineForm: {
    form: FormGroup,
    meta: Array<any>
  }
  @Input() newItemInlineForm: FormGroup;
  @Input() additionalFilters: any;
  @Input() showPaginate: boolean = true;
  @Input() showElementsPerPage: boolean = true;

  @Output() btnDeleteClickEvent = new EventEmitter();
  @Output() btnDetailsClickEvent = new EventEmitter();
  @Output() btnDoneFormEditClickEvent = new EventEmitter<T>();
  @Output() btnCustomClickEvent = new EventEmitter();
  @Output() newItemFormSubmit = new EventEmitter();
  @Output() customActionSlideToggle = new EventEmitter();

  filtersForm?: FormGroup;
  editingRow: T;
  paginationChanging: boolean = false;
  protected readonly faXmark = faXmark;
  protected readonly faCirclePlus = faCirclePlus;
  protected readonly faCircleMinus = faCircleMinus;
  protected readonly faPenToSquare = faPenToSquare;
  protected readonly isControlDisabled = isControlDisabled;

  ngOnInit(): void {
    this.setupFiltersFormGroup()

    this.paginator._intl.itemsPerPageLabel = 'Elementi per pagina:';
    this.paginator._intl.firstPageLabel = 'Prima pagina';
    this.paginator._intl.lastPageLabel = 'Ultima pagina';
    this.paginator._intl.previousPageLabel = 'Pagina precedente';
    this.paginator._intl.nextPageLabel = 'Pagina successiva';

    this.triggerSelector$.subscribe({
      next: (objectBrand) => {
        this.additionalFilters.brandId = objectBrand.brandId

        this.reloadTrigger$.next(1)
      }
    });
  }

  ngAfterViewInit() {
    const tableEngine$ = combineLatest([
      this.reloadTrigger$.pipe(startWith(0)),
      this.paginator.page.pipe(startWith({} as PageEvent)),
      this.sort.sortChange.pipe(startWith(this.initialSort)),
      this.filtersForm.valueChanges.pipe(startWith('')),
    ])

    tableEngine$.pipe(
      debounceTime(500),
      tap(([_, paginator, sort, formValue]) => {
        if (!this.paginationChanging) {
          this.paginator.firstPage()
        }

        const columnFilters = this.getOnlySelectedFilters(formValue)
        const ranges = [];

        for (const [key, value] of Object.entries(columnFilters)) {
          if (key.includes('From') || key.includes('To')) {
            ranges.push(key)
          }
        }

        if (ranges.length !== 1) {
          this.dataSource.loadData({
            page: paginator?.pageIndex ?? 0,
            size: this.paginator.pageSize,
            sort: sort?.active as TableDataApiParams['sort'] ?? '',
            orderBy: sort?.direction?.toUpperCase() as TableDataApiParams['orderBy'] || 'ASC',
            ...columnFilters,
            ...(this.additionalFilters)
          })
          this.paginationChanging = false
        }
      })
    ).subscribe();
  }

  getSelectOption(mode: 'KEY' | 'VALUE', op: Record<string, any>) {
    return mode === 'KEY' ? Object.keys(op)[0] : Object.values(op)[0]
  }

  getControlMeta(key: string) {
    return this.inlineForm.meta.find(control => control.key === key);
  }

  getFormattedString(str: string, row: any) {
    return str.replace(/:\w+/, m => row[m.substring(1)] ?? '');
  }

  btnDetailsClicked(row: T) {
    LoggerService.log('btnDetailsClickEvent')
    this.btnDetailsClickEvent.emit(row);
    if (this.inlineForm)
      this.editingRow = row;
  }

  clearDate($from: FormControl, $to: FormControl) {
    $from.setValue(null)
    $to.setValue(null)

    LoggerService.log($from);
    LoggerService.log($to);
  }

  private setupFiltersFormGroup() {
    this.filtersForm = new FormGroup(this.displayedColumns.reduce((controls, tableColumnModel) => {
      if (tableColumnModel?.filter) {
        switch (tableColumnModel.filter.type) {
          case "select":
            controls[tableColumnModel.name] = new FormControl('');
            break;
          case "input":
            controls[tableColumnModel.name] = new FormControl('');
            break;
          case "number":
            controls[tableColumnModel.name] = new FormControl('');
            break;
          case "date":
            controls[tableColumnModel.name] = new FormControl('');
            break;
          case "dateRange":
            controls[`${tableColumnModel.name}From`] = new FormControl<Date | null>(null);
            controls[`${tableColumnModel.name}To`] = new FormControl<Date | null>(null);
        }
      }

      return controls;
    }, {} as Record<string, FormControl>));
  }

  private getOnlySelectedFilters(filtersFormValue: Record<string, any>): Record<string, any> {
    return Object.entries(filtersFormValue).reduce((controlValues, [controlName, valueControl]) => {

      if (valueControl === false || valueControl) {
        controlValues[controlName] = valueControl;
      }

      if (valueControl && valueControl instanceof Date) {
        controlValues[controlName] = moment(valueControl).format('YYYY-MM-DD');
      }

      return controlValues;
    }, {})
  }
}
