import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {AuthRolesGuard} from '@guards/auth-roles.guard';
import {Role} from '@/models/role';
import {UsersComponent as UsersPageComponent} from '@pages/admin/users/users.component';
import {UserDetailsComponent as DetailUserPageComponent} from "@pages/admin/users/details/user-details.component";
import {CompanyComponent} from "@pages/admin/company/company.component";
import {NewPracticeComponent} from "@pages/practices-management/practices/new-practice/new-practice.component";
import {NewUserComponent} from "@pages/admin/users/new-user/new-user.component";
import {StockComponent} from "@pages/stock-management/stock.component";
import {UsedVehicleComponent} from "@pages/used-vehicle-management/used-vehicle.component";
import {
  PracticeDetailsComponent
} from "@pages/practices-management/practices/practice-details/practice-details.component";
import {BrandComponent} from "@pages/tables/brand/brand.component";
import {ActionPracticesComponent} from "@pages/tables/action-practices/action-practices.component";
import {BigBuyersComponent} from "@pages/tables/big-buyers/big-buyers.component";
import {RentalPracticesComponent} from "@pages/tables/rental-practices/rental-practices.component";
import {SellerComponent} from "@pages/tables/seller/seller.component";
import {ServicePracticesComponent} from "@pages/tables/service-practices/service-practices.component";
import {InsuranceServiceTypeComponent} from "@pages/tables/insurance-service-type/insurance-service-type.component";
import {RentalAgencyComponent} from "@pages/tables/rental-agency/rental-agency.component";
import {SubagentAgencyComponent} from "@pages/tables/subagent-agency/subagent-agency.component";
import {VehicleAccessoryComponent} from "@pages/tables/vehicle-accessory/vehicle-accessory.component";
import {ModelsComponent} from "@pages/tables/models/models.component";
import {CashMovementsComponent} from "@pages/cash-management/cash-movements/cash-movements.component";
import {MenuBiConfigComponent} from "@pages/tables/menu-bi-config/menu-bi-config.component";
import {MenuBiComponent} from "@pages/menu-bi/menu-bi.component";
import {NewTableComponent} from "@components/new-table/new-table.component";
import {PracticesComponent} from "@pages/practices-management/practices/practices.component";
import {
  DeliveryCardboardManagementComponent
} from "@pages/practices-management/delivery-cardboard-management/delivery-cardboard-management.component";
import {
  NewCashMovementsComponent
} from "@pages/cash-management/cash-movements/new-cash-movements/new-cash-movements.component";
import {PaymentTypeComponent} from "@pages/tables/payment-type/payment-type.component";
import {ContactSourceComponent} from "@pages/tables/contact-source/contact-source.component";
import {WarrantyComponent} from "@pages/tables/warranty/warranty.component";
import {BrandGoalsComponent} from "@pages/practices-management/brand-goals/brand-goals.component";
import {BusinessMachinesComponent} from "@pages/practices-management/business-machines/business-machines.component";
import {UsedVehicleDetailComponent} from "@pages/used-vehicle-management/details/used-vehicle-detail.component";
import {BankAccountComponent} from "@pages/tables/bank-account/bank-account.component";
import {BiggerDiscountComponent} from "@pages/bigger-discount/bigger-discount.component";
import {CrossF7TableComponent} from "@pages/imports/cross-f7-table/cross-f7-table.component";
import {CrossF8TableComponent} from "@pages/imports/cross-f8-table/cross-f8-table.component";
import {VwfsDashboardTableComponent} from "@pages/imports/vwfs-dashboard-table/vwfs-dashboard-table.component";
import {VwfsCommissionTableComponent} from "@pages/imports/vwfs-commission-table/vwfs-commission-table.component";
import {ImportStatusTableComponent} from "@pages/imports/import-status-table/import-status-table.component";
import {LoyaltyTableComponent} from "@pages/imports/loyalty-table/loyalty-table.component";
import {VwbankCommissionTableComponent} from "@pages/imports/vwbank-commission-table/vwbank-commission-table.component";
import {TableMSComponent} from "@pages/bigger-discount/table-ms/table-ms.component";
import {PracticeDeletedComponent} from "@pages/imports/practice-deleted/practice-deleted.component";
import {
  CashMovementsAnalisisComponent
} from "@pages/cash-management/cash-movements-analisis/cash-movements-analisis.component";

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'admin/users',
        component: UsersPageComponent,
        canActivate: [AuthRolesGuard],
        data: {roles: [Role.Admin]},
      },
      {
        path: 'admin/users/:username',
        component: DetailUserPageComponent,
        canActivate: [AuthRolesGuard],
        data: {roles: [Role.Admin]},
      },
      {
        path: 'admin/user/new',
        component: NewUserComponent,
        canActivate: [AuthRolesGuard],
        data: {roles: [Role.Admin]},
      },
      {
        path: 'admin/company',
        component: CompanyComponent,
        canActivate: [AuthRolesGuard],
        data: {roles: [Role.Admin]},
      },
      {
        path: 'practices-management/practices',
        component: PracticesComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.ResponsibleSubagents,
            Role.ResponsibleGarage,
            Role.Sellers,
            Role.Subagents,
            Role.Accounting,
            Role.Backoffice
          ]
        },
      },
      {
        path: 'practices-management/business-machines',
        component: BusinessMachinesComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.Accounting,
            Role.Backoffice
          ]
        },
      },
      {
        path: 'new-practices-management/practices',
        component: NewTableComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.ResponsibleSubagents,
            Role.Sellers,
            Role.Subagents,
            Role.Accounting,
            Role.Backoffice
          ]
        },
      },
      {
        path: 'practices-management/practices/details/:practiceId',
        component: PracticeDetailsComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.ResponsibleSubagents,
            Role.ResponsibleGarage,
            Role.Sellers,
            Role.Subagents,
            Role.Accounting,
            Role.Backoffice
          ]
        },
      },
      {
        path: 'practices-management/practices/new',
        component: NewPracticeComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.ResponsibleSubagents,
            Role.Sellers,
            Role.Subagents,
            Role.Accounting,
            Role.Backoffice
          ]
        },
      },
      {
        path: 'practices-management/delivery-cardboard',
        component: DeliveryCardboardManagementComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.Accounting,
            Role.Backoffice,
            Role.ResponsibleSellers
          ]
        },
      },
      {
        path: 'practices-management/brand-goals',
        component: BrandGoalsComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.Accounting,
            Role.Backoffice,
            Role.ResponsibleSellers
          ]
        },
      },
      {
        path: 'stock-management',
        component: StockComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.Sellers,
            Role.Accounting,
            Role.Backoffice,
            Role.Subagents,
            Role.ResponsibleSubagents
          ]
        },
      },
      {
        path: 'used-vehicle-management',
        component: UsedVehicleComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.Sellers,
            Role.Accounting,
            Role.Backoffice
          ]
        },
      },
      {
        path: 'used-vehicle-management/details/:id',
        component: UsedVehicleDetailComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.Sellers,
            Role.Accounting,
            Role.Backoffice
          ]
        },
      },
      {
        path: 'mmss-management/simulator-ms',
        component: BiggerDiscountComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.Accounting
          ]
        },
      },
      {
        path: 'mmss-management/table-ms',
        component: TableMSComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.Accounting
          ]
        },
      },
      {
        path: 'imports/crossf7',
        component: CrossF7TableComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.Import
          ]
        },
      },
      {
        path: 'imports/practice-deleted',
        component: PracticeDeletedComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.Import
          ]
        },
      },
      {
        path: 'imports/crossf8',
        component: CrossF8TableComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.Import
          ]
        },
      },
      {
        path: 'imports/loyalty',
        component: LoyaltyTableComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.Import
          ]
        },
      },
      {
        path: 'imports/vwbankCommission',
        component: VwbankCommissionTableComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.Import
          ]
        },
      },
      {
        path: 'imports/vwfsDashboard',
        component: VwfsDashboardTableComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.Import
          ]
        },
      },
      {
        path: 'imports/vwfsCommission',
        component: VwfsCommissionTableComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.Import
          ]
        },
      },
      {
        path: 'imports/registry',
        component: ImportStatusTableComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.Import
          ]
        },
      },
      {
        path: 'cash-management/cash-movements',
        component: CashMovementsComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.ResponsibleWarehouse,
            Role.ResponsibleGarage,
            Role.Sellers,
            Role.Backoffice,
            Role.Warehouse,
            Role.Garage,
            Role.Accounting
          ]
        },
      },
      {
        path: 'cash-management/cash-movements/new',
        component: NewCashMovementsComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.ResponsibleWarehouse,
            Role.ResponsibleGarage,
            Role.Sellers,
            Role.Backoffice,
            Role.Warehouse,
            Role.Garage,
            Role.Accounting
          ]
        },
      },
      {
        path: 'cash-management/cash-movements/details/:id',
        component: NewCashMovementsComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.ResponsibleWarehouse,
            Role.ResponsibleGarage,
            Role.Sellers,
            Role.Backoffice,
            Role.Warehouse,
            Role.Garage,
            Role.Accounting
          ]
        },
      },
      {
        path: 'cash-management/cash-movements-analisis',
        component: CashMovementsAnalisisComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.Accounting
          ]
        },
      },
      {
        path: 'menu-bi',
        component: MenuBiComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.ResponsibleSellers,
            Role.ResponsibleWarehouse,
            Role.ResponsibleGarage,
            Role.Backoffice,
            Role.Accounting,
            Role.Warehouse
          ]
        },
      },
      {
        path: 'tables/action-practices',
        component: ActionPracticesComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/big-buyers',
        component: BigBuyersComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/brand',
        component: BrandComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/insurance-service-type',
        component: InsuranceServiceTypeComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/rental-practices',
        component: RentalPracticesComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/subagent-agency',
        component: SubagentAgencyComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/seller',
        component: SellerComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/service-practices',
        component: ServicePracticesComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/rental-agency',
        component: RentalAgencyComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/vehicle-accessory',
        component: VehicleAccessoryComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/models',
        component: ModelsComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/payment-type',
        component: PaymentTypeComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/contact-source',
        component: ContactSourceComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/warranty',
        component: WarrantyComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/bank-account',
        component: BankAccountComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },
      {
        path: 'tables/menu-bi-config',
        component: MenuBiConfigComponent,
        canActivate: [AuthRolesGuard],
        data: {
          roles: [
            Role.Admin,
            Role.TableManager,
            Role.Accounting
          ]
        },
      },

    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    canActivate: [NonAuthGuard],
  },
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
