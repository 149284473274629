import {FormSetting} from "@/form-settings/form-setting";
import {BigBuyersModel} from "@/models/tables/big-buyers.model";
import {BrandModel} from "@/models/tables/brand.model";

export class BrandGoalsFormSetting extends FormSetting {

  protected static classToCall: string

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static getMeta(): Array<any> {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "anno",
            "label": "Anno",
            "controlType": "number",
            "class": "lg:w-6/12 w-full",
            "order": 1,
            "required": true,
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "mese",
            "label": "Mese",
            "controlType": "number",
            "class": "lg:w-6/12 w-full",
            "order": 1,
            "required": true,
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "marca",
            "controlType": "dropdown",
            compareFn: (o1: BrandModel, o2: BrandModel) => o1?.id === o2?.id,
            "label": "Marca",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "required": true,
            "disabled": true,
            "options": this.data['brands'] ?? []
          },
          {
            "classToUse": this.classToCall,
            "key": "obiettivoMeseRetail",
            "label": "Obiettivo RETAIL",
            "controlType": "number",
            "class": "lg:w-6/12 w-full",
            "order": 1
          },
          {
            "classToUse": this.classToCall,
            "key": "obiettivoMeseNlt",
            "label": "Obiettivo NLT",
            "controlType": "number",
            "class": "lg:w-6/12 w-full",
            "order": 1
          },
          {
            "classToUse": this.classToCall,
            "key": "grandiAcquirenti",
            "controlType": "multiselect",
            compareFn: (o1: BigBuyersModel, o2: BigBuyersModel) => o1?.id === o2?.id,
            "label": "Grandi Acquirenti",
            "order": 1,
            "class": "lg:w-6/12 w-full",
            "required": false,
            "options": this.data['bigBuyers'] ?? []
          },
          {
            "classToUse": this.classToCall,
            "key": "retailIncludeNlt",
            "controlType": "slide-toggle",
            "label": "Retail include NLT",
            "order": 1,
            "class": "lg:w-6/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "visualizzaNltInCartoneConsegna",
            "controlType": "slide-toggle",
            "label": "Visualizza NLT in Cartone Consegna",
            "order": 1,
            "class": "lg:w-6/12 w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "id",
            "controlType": "hidden",
            "label": "",
            "order": 1,
          },
          {
            "classToUse": this.classToCall,
            "key": "creator",
            "controlType": "hidden",
            "label": "",
            "order": 1,
          },
        ],
        "order": 1
      },
    ]
  }
}
