import {Component} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {PaymentTypeService} from "@services/tables/payment-type.service";
import {PaymentTypeFormSetting} from "@/form-settings/payment-type-form-setting";
import {PaymentTypeModel} from "@/models/tables/payment-type.model";

@Component({
  selector: 'app-payment-type-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class PaymentTypeFormComponent extends DynamicFormComponent {
  constructor(protected router: Router, protected userService: UserService, protected paymentTypeService: PaymentTypeService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())
  }

  onSubmit() {
    let paymentTypeModel = <PaymentTypeModel>this.form.getRawValue()

    this.paymentTypeService.savePaymentType(paymentTypeModel).subscribe({
      next: () => {
        this.toastr.success('Tipo pagamento salvato correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('savePaymentType failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio del Tipo Pagamento.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = PaymentTypeFormSetting
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    Registry.getInstance().get('DialogPaymentTypeComponent').close()

    Registry.getInstance().get('PaymentTypeComponent').loadPaymentTypes()
  }

  protected secondaryButtonAction() {
    Registry.getInstance().get('DialogPaymentTypeComponent').close()
  }

}
