import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {VehicleAccessoryModel} from "@/models/tables/vehicle-accessory.model";
import {
    DialogVehicleAccessoryComponent
} from "@pages/tables/vehicle-accessory/dialog-vehicle-accessory/dialog-vehicle-accessory.component";

@Component({
    selector: 'app-button-detail-vehicle-accessory',
    templateUrl: '../../button.component.html',
    styleUrls: ['../../button.component.scss'],
})
export class ButtonDetailVehicleAccessoryComponent extends ButtonComponent implements OnInit {

    private dialogOpened: MatDialogRef<any>
    private vehicleAccessoryModel: VehicleAccessoryModel;
    private rowData: any;
    faIcon = faPenToSquare;

    constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
        super(router, toastr);

        this.showIcon = true
        this.additionalClass = 'py-0.5 px-2.5'
        this.toolTip = 'Dettaglio Accesorio Veicolo'
        this.smallBtn = true;
    }

    ngOnInit() {
        this.vehicleAccessoryModel = <VehicleAccessoryModel>this.rowData;
    }

    /**
     * Open modal new detail page
     */
    public actionClick(): void {
        this.dialogOpened = this.dialog.open(DialogVehicleAccessoryComponent, {
            data: this.vehicleAccessoryModel,
        });

        Registry.getInstance().set('DialogVehicleAccessoryComponentModify', this.dialogOpened)
    }

}
