export enum Role {
  User = 'ROLE_USER',
  Admin = 'ROLE_ADMINISTRATOR',
  ResponsibleSubagents = 'ROLE_RESPONSABILE_SUBAGENTE',
  ResponsibleSellers = 'ROLE_RESPONSABILE_VENDITORE',
  Sellers = 'ROLE_VENDITORE',
  Subagents = 'ROLE_SUBAGENTE',
  Backoffice = 'ROLE_BACKOFFICE',
  Accounting = 'ROLE_CONTABILITA',
  Warehouse = 'ROLE_MAGAZZINO',
  Garage = 'ROLE_OFFICINA',
  ResponsibleWarehouse = 'ROLE_RESPONSABILE_MAGAZZINO',
  ResponsibleGarage = 'ROLE_RESPONSABILE_OFFICINA',
  TableManager = 'ROLE_GESTORE_TABELLE',
  Import = 'ROLE_IMPORTAZIONI'
}
