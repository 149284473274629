import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ControlBase} from "@components/dynamic-form/control-base";
import {FormGroup} from "@angular/forms";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent extends InputFieldComponent implements OnChanges{
  @Input() meta!: ControlBase
  @Input() form!: FormGroup
  @Input() indexRow: number = null
  @Input() keyformArrayName: any

  ngOnInit() {
    this.setControl()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setControl()
  }
}
