import {Component, Input} from '@angular/core';
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";
import {ControlBase} from "@components/dynamic-form/control-base";
import {AbstractControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss']
})
export class CurrencyInputComponent extends InputFieldComponent {
  @Input() meta!: ControlBase
  @Input() form!: FormGroup

  ngOnInit() {
    this.setControl()
  }

  getParsedValue(value: string) {
    return parseFloat(
      value
        .replace(/\./g, '')
        .replace(/,/g, '\.')
    ) ?? 0
  }

  isControlRequired(abstractControl: AbstractControl) {
    return abstractControl.hasValidator(Validators.required);
  }

  dispatchChangeEvent(element: HTMLInputElement) {
    element.dispatchEvent(new Event('change'))
  }
}
