import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../environments/environment';
import {JwtHelperService} from '@services/jwt-helper.service';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public updateList = new Subject();
  protected baseUrl: string = '';
  protected headers: HttpHeaders;

  constructor(protected router: Router, protected toastr: ToastrService, protected http: HttpClient, protected jwtHelper: JwtHelperService) {
    this.baseUrl = environment.apiUrl;
    const auth_token = this.jwtHelper.getToken();

    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`,
    });
  }

  getDataForTable(params): Observable<any> {
    return new Observable(observer => params);
  }


  onUpdate(): Observable<any> {
    return this.updateList.asObservable();
  }

  triggerUpdate() {
    this.updateList.next({});
  }
}
