import {Component} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {MenuBiConfigModel} from "@/models/tables/menu-bi-config.model";
import {MenuBiConfigService} from "@services/tables/menu-bi-config.service";
import {MenuBiConfigFormSetting} from "@/form-settings/menu-bi-config-form-setting";
import {OptionSelect} from "@/models/option-select.model";

@Component({
  selector: 'app-menu-bi-config-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class MenuBiConfigFormComponent extends DynamicFormComponent {
  private areas: OptionSelect[] = [];

  constructor(protected router: Router, protected userService: UserService, protected menuBiConfigService: MenuBiConfigService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.menuBiConfigService.getAreas().subscribe(areas => {
      for (const area of areas) {
        this.areas.push({
          key: area,
          value: area
        });
      }

      this.setupMeta()
    });

    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())
  }

  onSubmit() {
    let menuBI = <MenuBiConfigModel>this.form.getRawValue()

    this.menuBiConfigService.saveMenuBI(menuBI).subscribe({
      next: () => {
        this.toastr.success('Menu BI salvato correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveMenuBI failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio del Menu BI.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = MenuBiConfigFormSetting
      .set('areas', this.areas)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogMenuBIConfigComponentModify').close()
    } else {
      Registry.getInstance().get('DialogMenuBIConfigComponentNew').close()
    }

    Registry.getInstance().get('MenuBiConfigComponent').loadMenuBiConfig()
  }

  protected secondaryButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('DialogMenuBIConfigComponentModify').close()
    } else {
      Registry.getInstance().get('DialogMenuBIConfigComponentNew').close()
    }
  }
}
