<div [formGroup]="form">
  <div *ngIf="indexRow !== null" [formArrayName]="keyformArrayName">
    <div [formGroupName]="indexRow">
      <mat-label class="label-form">{{meta.label}}:</mat-label>
      <mat-form-field appearance="fill" class="date-input w-full"
                      [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
        <mat-label>Scegli una data</mat-label>
        <input *ngIf="meta.maxDate" matInput placeholder="DD/MM/YYYY" [max]="today" [matDatepicker]="picker"
               [formControlName]="meta.key" (dateChange)="customActionInput($event, indexRow)">
        <input *ngIf="!meta.maxDate" matInput placeholder="DD/MM/YYYY" [matDatepicker]="picker"
               [formControlName]="meta.key" (dateChange)="customActionInput($event, indexRow)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="indexRow === null">
    <mat-label class="label-form">{{meta.label}}:</mat-label>
    <mat-form-field appearance="fill" class="date-input w-full"
                    [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
      <mat-label>Scegli una data</mat-label>
      <input *ngIf="meta.maxDate" matInput placeholder="DD/MM/YYYY" [max]="today" [matDatepicker]="picker"
             [formControlName]="meta.key" (dateChange)="customActionInput($event)">
      <input *ngIf="!meta.maxDate" matInput placeholder="DD/MM/YYYY" [matDatepicker]="picker"
             [formControlName]="meta.key" (dateChange)="customActionInput($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>
</div>
