export class RentalAgencyModel {
  public id: number;
  public codiceAgenzia: number;
  public descrizione: string;
  public enabled: boolean;
  public creator: string;
  public created: Date;
  public modifier: string;
  public modified: Date;
}
