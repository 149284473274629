<div class="container-box">
  <app-table-header tableTitle="Movimenti Cassa" (btnUpdateClick)="reloadTrigger$.next()">
    <app-button-new-cash-movements action-button></app-button-new-cash-movements>
  </app-table-header>
  <div class="table-container">
    <app-new-table
      [dataSource]="dataSource"
      [reloadTrigger$]="reloadTrigger$"
      [displayedColumns]="displayedColumns"
      [initialSort]="{
        active: 'dataMovimento',
        direction: 'desc'
      }"
      [hideButtonDelete]="hideButtonDelete"
      (btnDeleteClickEvent)="btnDeleteFunction($event)"
      (btnDetailsClickEvent)="btnDetailsClickFunction($event)"
    ></app-new-table>
  </div>
</div>
