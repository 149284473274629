import {Component, OnChanges} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {PracticesService} from "@services/practices.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {Registry} from "@/registry.service";
import {DataVehicleNotMatchedFormSetting} from "@/form-settings/practice/data-vehicle-not-matched-form-setting";
import {LoggerService} from "@services/logger.service";

@Component({
  selector: 'app-data-vehicle-not-matched-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class DataVehicleNotMatchedFormComponent extends DynamicFormComponent implements OnChanges {

  models: any[] = [];

  constructor(protected router: Router, protected userService: UserService, protected practiceService: PracticesService, protected toastr: ToastrService, public dialog: MatDialog) {
    super(toastr, userService)

    this.submitTopButton = false
    this.submitBottomButton = false

    Registry.getInstance().set('DataVehicleNotMatchedFormComponent', this)
  }

  public ngOnChanges() {
    if (Object.values(this.data).length !== 0) {
      this.refreshForm()
    }
  }

  protected setupMeta() {
    this.meta = DataVehicleNotMatchedFormSetting
      .set('models', this.models)
      .getMeta()

    LoggerService.log(this.meta)
  }

}
