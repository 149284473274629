import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {PaymentTypeSettings} from "@/table-settings/tables/payment-type-settings";
import {PaymentTypeService} from "@services/tables/payment-type.service";

@Component({
  selector: 'app-payment-type',
  templateUrl: './payment-type.component.html',
  styleUrls: ['./payment-type.component.scss']
})
export class PaymentTypeComponent {

  source: LocalDataSource;
  settings = PaymentTypeSettings.gridSettings();

  constructor(private paymentTypeService: PaymentTypeService) {
    this.source = new LocalDataSource([]);
    const registry = Registry.getInstance()
    registry.get('userService').onUpdate().subscribe(() => {
      this.loadPaymentTypes();
    });

    Registry.getInstance().set('PaymentTypeComponent', this)

    this.loadPaymentTypes();
  }

  loadPaymentTypes() {
    this.paymentTypeService.getAll().subscribe((paymentTypes) => {
      this.source.load(paymentTypes);
    });
  }
}
