import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiService} from '@services/api.service';
import {catchError, map,} from 'rxjs/operators';
import {LoggerService} from '@services/logger.service';
import {PracticeModel, PracticePageResp} from '@/models/practice.model';
import {HttpHeaders} from '@angular/common/http';
import {UsedVehicleModel} from '@/models/usedVehicleModel';
import {PracticeDocument} from '@/models/practice-document.model';
import {PracticeAccessoryModel} from '@/models/practice-accessory-model';
import {SellerModel} from "@/models/tables/seller.model";
import {BrandModel} from "@/models/tables/brand.model";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {PracticeCommunicationModel} from "@/models/practice-communication-model";
import {PaymentTypeModel} from "@/models/tables/payment-type.model";
import {EquipmentModel} from "@/models/equipment-model";

export interface TableDataApiParams {
  sort?: keyof TablePracticeModel | '';
  orderBy?: 'DESC' | 'ASC';
  page?: number;
  size?: number;
}

type TempCustomPracticeResp = Omit<PracticePageResp, 'content'> & {
  content: TablePracticeModel[];
}

@Injectable({
  providedIn: 'root'
})
export class PracticesService extends ApiService {
  getDataForTable(
    params: TableDataApiParams = {
      sort: '',
      orderBy: 'DESC',
      page: 1,
      size: 15
    }
  ): Observable<TempCustomPracticeResp> {
    return this.http.get<PracticePageResp>(this.baseUrl + '/practices/page', {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content.map(
          ({
             id,
             tipoPratica,
             data,
             codicePratica,
             statoPratica,
             tipoVetturaAziendale,
             statoOrdineVgi,
             marca,
             targa,
             cliente,
             tipoPagamento,
             totPunteggioFinanziari,
             totPunteggioLoyalty,
             codiceModello,
             descrizioneModello,
             venditore,
             presenzaAccessori,
             presenzaPermute,
             creator,
             created
           }) => ({
            id,
            tipoPratica,
            data,
            codicePratica,
            tipoVetturaAziendale,
            statoPratica,
            statoOrdineVgi,
            marca: marca?.descrizioneMarca,
            targa,
            cliente,
            tipoPagamento: tipoPagamento?.id,
            totPunteggioFinanziari,
            totPunteggioLoyalty,
            codiceModello,
            descrizioneModello,
            venditore: venditore ? `${venditore.codiceVenditore} - ${venditore.descrizione}` : null,
            presenzaAccessori,
            presenzaPermute,
            creator,
            created
          })
        )
      })),
      catchError(() => of(null))
    );
  }

  savePractice(practice: PracticeModel) {
    return this.http.post(this.baseUrl + '/practices/save/', practice).pipe(map((response: any) => {
      LoggerService.log(response, 'savePractice')
      return response;
    }));
  }

  saveAccessory(accessory: PracticeAccessoryModel) {
    return this.http.post(this.baseUrl + '/practices/saveVehicleAccessories/', accessory).pipe(map((response: any) => {
      LoggerService.log(response, 'saveAccessory')
      return response;
    }));
  }

  clonePractice(practiceToDuplicate) {
    return this.http.post(this.baseUrl + '/practices/clone/', practiceToDuplicate).pipe(map((response: any) => {
      LoggerService.log(response, 'clonePractice')
      return response;
    }));
  }

  deleteAccessory(accessoryId: number) {
    return this.http.delete(this.baseUrl + `/practices/deleteVehicleAccessories/${accessoryId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteAccessory')
      return response;
    }));
  }

  createExchange(exchange: UsedVehicleModel) {
    return this.http.post(this.baseUrl + '/practices/saveExchange/', exchange).pipe(map((response: any) => {
      LoggerService.log(response, 'createExchange')
      return response;
    }));
  }

  setCompleteDocuments(practiceId: number): Observable<any> {
    return this.http.post(this.baseUrl + '/practices/completePractice', {'id': practiceId}).pipe(map((response: any) => {
      LoggerService.log(response, 'setCompleteDocuments')

      return response;
    }));
  }

  downloadPraticaPdf(practiceId: number): any {
    return this.http.get(this.baseUrl + `/practices/pdf/${practiceId}/`, {
      headers: new HttpHeaders({'Content-Type': 'application/octet-stream'}),
      responseType: 'blob'
    });
  }

  downloadSummaryCVPdf(practiceId: number): any {
    return this.http.get(this.baseUrl + `/practices/pdf/${practiceId}/riepilogo`, {
      headers: new HttpHeaders({'Content-Type': 'application/octet-stream'}),
      responseType: 'blob'
    });
  }

  downloadDetailVehiclePdf(practiceId: number): any {
    return this.http.get(this.baseUrl + `/practices/vehicle/pdf/${practiceId}`, {
      headers: new HttpHeaders({'Content-Type': 'application/octet-stream'}),
      responseType: 'blob'
    });
  }

  downloadPermutaPdf(exchangeId: number): any {
    return this.http.get(this.baseUrl + `/practices/exchange/pdf/${exchangeId}/`, {
      headers: new HttpHeaders({'Content-Type': 'application/octet-stream'}),
      responseType: 'blob'
    });
  }

  getModelsByBrandId(brandId: number) {
    return this.http.get<any>(this.baseUrl + `/practices/anagModelCode/${brandId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getModelsByBrandId')
      return response;
    }));
  }

  getAccessoriesByModel(modelId: number) {
    return this.http.get<any>(this.baseUrl + `/practices/anagVehicleAccessories/${modelId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getAccessoriesByModel')
      return response;
    }));
  }

  async getPractice(practiceId: number) {
    return await this.http.get<PracticeModel>(this.baseUrl + `/practices/${practiceId}/`).toPromise();
  }

  getExchangesList(practiceId: number) {
    return this.http.get<UsedVehicleModel>(this.baseUrl + `/practices/exchanges/${practiceId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getExchanges')
      return response;
    }));
  }

  getExchange(exchangeId: number) {
    return this.http.get<UsedVehicleModel>(this.baseUrl + `/practices/exchange/${exchangeId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getExchange')
      return response;
    }));
  }

  getAccessories(practiceId: number) {
    return this.http.get<PracticeAccessoryModel>(this.baseUrl + `/practices/vehicleAccessories/${practiceId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getAccessories')

      return response;
    }));
  }

  getDocuments(practiceId: number) {
    return this.http.get<PracticeDocument>(this.baseUrl + `/practices/document/${practiceId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getDocuments')

      return response;
    }));
  }

  getChecklistDocument(practiceId: number): Observable<any> {
    return this.http.get(this.baseUrl + `/practices/checklist/${practiceId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getChecklistDocument')

      return response;
    }));
  }

  getCommunications(practiceId: number) {
    return this.http.get<PracticeCommunicationModel>(this.baseUrl + `/practices/practiceCommunication/${practiceId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getCommunications')

      return response;
    }));
  }

  getEquipments(practiceId: number) {
    return this.http.get<EquipmentModel>(this.baseUrl + `/practices/equipment/${practiceId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getEquipments')

      return response;
    }));
  }

  saveCommunication(communication: PracticeCommunicationModel) {
    return this.http.post(this.baseUrl + '/practices/savePracticeCommunication/', communication).pipe(map((response: any) => {
      LoggerService.log(response, 'saveCommunication')
      return response;
    }));
  }

  deleteCommunication(communicationId: number) {
    return this.http.delete(this.baseUrl + `/practices/deletePracticeCommunication/${communicationId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteCommunication')
      return response;
    }));
  }

  uploadPracticeDocument(document: FormData): Observable<any> {
    return this.http.post(this.baseUrl + '/practices/uploadFile', document).pipe(map((response: any) => {
      LoggerService.log(response, 'uploadPracticeDocument')

      return response;
    }));
  }

  deletePractice(practiceId: number) {
    return this.http.delete(this.baseUrl + `/practices/${practiceId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deletePractice')
      return response;
    }));
  }

  deleteExchange(exchangeId: number) {
    return this.http.delete(this.baseUrl + `/practices/deleteExchange/${exchangeId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteExchange')
      return response;
    }));
  }

  getBrandsBypracticeType(practiceType: string): Observable<any> {
    return this.http.get(this.baseUrl + `/practices/brands/${practiceType}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getBrandsBypracticeType')
      return response;
    }));
  }

  getRentalAgency(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/practices/getRentalAgency`).pipe(map((response: any) => {
      LoggerService.log(response, 'getRentalAgency')
      return response;
    }));
  }

  getDocumentCategories(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/practices/documentTypes`).pipe(map((response: any) => {
      LoggerService.log(response, 'getDocumentCategories')
      return response;
    }));
  }

  getAllBrands(): Observable<BrandModel[]> {
    return this.http.get<any>(this.baseUrl + `/practices/brands`).pipe(map((response: any) => {
      LoggerService.log(response, 'getAllBrands')
      return response;
    }));
  }

  getAllPaymentTypes(): Observable<PaymentTypeModel[]> {
    return this.http.get<any>(this.baseUrl + `/practices/paymentTypes`).pipe(map((response: any) => {
      LoggerService.log(response, 'getAllPaymentTypes')
      return response;
    }));
  }

  getPaymentTypesByPracticeType(practiceType: string): Observable<PaymentTypeModel[]> {
    return this.http.get<any>(this.baseUrl + `/practices/paymentTypes/${practiceType}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getPaymentTypesByPracticeType')
      return response;
    }));
  }

  getSellers(): Observable<SellerModel[]> {
    return this.http.get(this.baseUrl + '/practices/sellers/').pipe(map((response: any) => {
      LoggerService.log(response, 'getSellers')
      return response;
    }));
  }

  getCustomerTypesByPracticeType(practiceType: string): Observable<any> {
    return this.http.get(this.baseUrl + `/practices/customerTypes/${practiceType}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getPracticeTypes')
      return response;
    }));
  }

  getRentalCodes(): Observable<any> {
    return this.http.get(this.baseUrl + '/practices/rentalCodes/').pipe(map((response: any) => {
      LoggerService.log(response, 'getRentalCodes')
      return response;
    }));
  }

  getPracticeTypes(): Observable<any> {
    return this.http.get(this.baseUrl + '/practices/practicalTypes/').pipe(map((response: any) => {
      LoggerService.log(response, 'getPracticeTypes')
      return response;
    }));
  }

  getAnagraficaServices(vehicleType: string, serviceType: string): Observable<any> {
    return this.http.get(this.baseUrl + `/practices/services/${serviceType}/${vehicleType}`).pipe(map((response: any) => {
      LoggerService.log(response, 'getAnagraficaServices')
      return response;
    }));
  }

  getActionCodes(brandId: BrandModel['id']): Observable<any> {
    return this.http.get(this.baseUrl + `/practices/actionCodes/${brandId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getActionCodes')
      return response;
    }));
  }

  exportDeliveryCardBoard(year: string, month: string): Observable<Blob> {
    return this.http.get(this.baseUrl + `/deliveryCardboard/exportXlsx/${year}/${month}/`, {
      headers: new HttpHeaders({'Content-Type': 'application/octet-stream'}),
      responseType: 'blob'
    });
  }

  recalculateDeliveryCardboard(year: string, month: string): Observable<any> {
    return this.http.post(this.baseUrl + `/deliveryCardboard/recalculate/${year}/${month}/`, '');
  }

  getBigBuyers(brandId: number, customerType: string): Observable<any> {
    return this.http.get(this.baseUrl + `/practices/bigBuyers/${brandId}/${customerType}`).pipe(map((response: any) => {
      LoggerService.log(response, 'getBigBuyers')
      return response;
    }));
  }

  deleteDocument(documentId: number) {
    return this.http.delete(this.baseUrl + `/practices/document/${documentId}/`);
  }

  downloadDocument(praticaId: number, fileId: number) {
    return this.http.get(this.baseUrl + `/practices/${praticaId}/document/${fileId}/download`, {
      headers: new HttpHeaders({'Content-Type': 'application/octet-stream'}),
      responseType: 'blob'
    });
  }

  getContactSource(): Observable<any> {
    return this.http.get(this.baseUrl + '/practices/contactSource/').pipe(map((response: any) => {
      LoggerService.log(response, 'getContactSource')
      return response;
    }));
  }

  getWarranties(): Observable<any> {
    return this.http.get(this.baseUrl + '/practices/warranties/').pipe(map((response: any) => {
      LoggerService.log(response, 'getWarranties')
      return response;
    }));
  }

  getTypesCommercialVehicle(): Observable<any> {
    return this.http.get(this.baseUrl + '/practices/typesCommercialVehicles/').pipe(map((response: any) => {
      LoggerService.log(response, 'getTypesCommercialVehicle')
      return response;
    }));
  }
}
