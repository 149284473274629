import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-new-communication-practice',
  templateUrl: './dialog-new-communication-practice.component.html',
})
export class DialogNewCommunicationPracticeComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }

}
