import {Component, Input} from '@angular/core';
import {AbstractControl, FormGroup} from "@angular/forms";
import {MatDatepicker} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";
import {DropdownControl} from "@components/dynamic-form/dropdown/dropdown-control";
import {Moment} from "moment/moment";
import moment from "moment";


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM',
  },
  display: {
    dateInput: 'MM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-month-date-picker-input',
  templateUrl: './month-date-picker-input.component.html',
  styleUrls: ['./month-date-picker-input.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class MonthDatePickerInputComponent extends InputFieldComponent {
  @Input() meta!: DropdownControl
  @Input() form!: FormGroup
  @Input() max: any;
  @Input() indexRow: number = null
  @Input() keyformArrayName: any
  today: Date = new Date();
  protected control: AbstractControl<any>;

  constructor(private dateAdapter: DateAdapter<Date>) {
    super()

    this.today.setDate(this.today.getDate());
    this.dateAdapter.setLocale('it');
  }

  ngOnInit(): void {
    this.setControl()

    const month = this.form.controls[this.meta.key].value - 1;

    this.form.controls[this.meta.key].setValue(moment({month: month}));
  }

  setMonth(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    this.form.controls[this.meta.key].setValue(normalizedYear)

    datepicker.close();
  }
}
