import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {RentalPracticesService} from "@services/tables/rental-practices.service";
import {RentalPracticesModel} from "@/models/tables/rental-practices.model";
import {RentalPracticeFormSetting} from "@/form-settings/rental-practice-form-setting";
import {Component} from "@angular/core";

@Component({
  selector: 'app-rental-practices-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class RentalPracticesFormComponent extends DynamicFormComponent {

  constructor(protected router: Router, protected userService: UserService, protected rentalPracticesService: RentalPracticesService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())
  }

  onSubmit() {
    let rentalPracticesModel = <RentalPracticesModel>this.form.getRawValue()

    this.rentalPracticesService.saveRentalPractice(rentalPracticesModel).subscribe({
      next: () => {
        this.toastr.success('Pratica Noleggio salvata correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveRentalPractice failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio del Pratica Noleggio.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = RentalPracticeFormSetting
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogRentalPracticeComponentModify').close()
    } else {
      Registry.getInstance().get('DialogRentalPracticeComponentNew').close()
    }

    Registry.getInstance().get('RentalPracticesComponent').loadRentalPractices()
  }

  protected secondaryButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('DialogRentalPracticeComponentModify').close()
    } else {
      Registry.getInstance().get('DialogRentalPracticeComponentNew').close()
    }
  }

}
