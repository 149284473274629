<div [formGroup]="form">
  <div *ngIf="indexRow === null">
    <mat-label class="label-form">{{ meta.label }}:</mat-label>
    <mat-form-field [ngClass]="control.invalid && submitted ? 'invalid-field' : ''" appearance="fill"
                    class="date-input w-full">
      <mat-label>Scegli una data</mat-label>
      <input (dateChange)="customActionInput($event)" *ngIf="meta.maxDate" [formControlName]="meta.key"
             [matDatepicker]="picker" [max]="today"
             matInput placeholder="YYYY">
      <input (dateChange)="customActionInput($event)" *ngIf="!meta.maxDate" [formControlName]="meta.key"
             [matDatepicker]="picker"
             matInput placeholder="YYYY">
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker (yearSelected)="setYear($event, picker)" startView="multi-year"></mat-datepicker>
    </mat-form-field>
  </div>
  <app-box-errors [control]="control" [meta]="meta" [submitted]="submitted"></app-box-errors>
</div>
