import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {MenuBiConfigModel} from "@/models/tables/menu-bi-config.model";

@Injectable({
    providedIn: 'root'
})
export class MenuBiConfigService extends ApiService {

    getAll(): Observable<any> {
        return this.http.get<any>(this.baseUrl + `/tables/menuBi`);
    }

    deleteMenuBI(menuBIId: number) {
        return this.http.delete(this.baseUrl + `/tables/menuBi/${menuBIId}/`).pipe(map((response: any) => {
            LoggerService.log(response, 'deleteMenuBI')
            return response;
        }));
    }

    saveMenuBI(menuBI: MenuBiConfigModel) {
        return this.http.post(this.baseUrl + `/tables/menuBi/save`, menuBI).pipe(map((response: any) => {
            LoggerService.log(response, 'saveMenuBI')
            return response;
        }));
    }

    getAreas(): Observable<any> {
        return this.http.get<any>(this.baseUrl + `/tables/menuBi/areas`);
    }
}
