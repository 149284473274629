import {Component} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {UserForm} from "@pages/admin/users/details/form-setting/user-form";
import {LoggerService} from "@services/logger.service";
import {UserService} from "@services/admin/user.service";
import {Router} from "@angular/router";
import {User} from "@/models/user.model";
import {Registry} from "@/registry.service";
import {ToastrService} from "ngx-toastr";
import {concatMap, tap} from "rxjs/operators"; //<==== this one (**Updated**)

@Component({
  selector: 'app-user-form-detail',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss'],
  providers: [Registry]
})
export class UserFormDetailComponent extends DynamicFormComponent {
  faIcon = faUser;

  format = {
    add: 'Aggiungi',
    remove: 'Rimuovi',
    all: 'Tutte',
    none: 'Nessuna',
    direction: 'left-to-right',
    draggable: false,
    locale: undefined
  }
  private companies;
  private roles;

  constructor(protected router: Router, protected userService: UserService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.tabs = [
      {
        'title': 'Dettaglio Utente',
        'meta': UserForm.formDetailMeta().sort((a: any, b: any) => a.order - b.order)
      },
      {
        'title': 'Aziende',
        'meta': []
      },
      {
        'title': ' Ruoli',
        'meta': []
      }
    ]
    this.submitButtonVisible = false
    this.primaryButton = 'Salva Utente'

    this.loadData()
  }

  protected setupTabs() {
    LoggerService.log('Setup tabs')

    this.tabs[1] = {
      'title': 'Aziende',
      'meta': [
        {
          "rows": [
            {
              "key": "companyCodes",
              "controlType": "dual-list",
              "label": "Aziende",
              "class": "w-full",
              "source": this.companies,
              "format": this.format,
              "destination": this.data.companyCodes
            },
          ],
          "order": 1
        }
      ]
    }
    this.tabs[2] = {
      'title': ' Ruoli',
      'meta': [
        {
          "rows": [
            {
              "key": "roles",
              "controlType": "dual-list",
              "label": "Ruoli",
              "class": "w-full",
              "source": this.roles,
              "format": this.format,
              "destination": this.data.roles
            },
          ],
          "order": 1
        }
      ]
    }

    this.refreshForm()
  }

  onSubmit() {
    this.validPasswords()
    const registry = Registry.getInstance()

    const user = <User>this.form.getRawValue()
    user.roles = registry.get('roles')
    user.companyCodes = registry.get('companyCodes')

    this.userService.updateUser(user).subscribe({
      next: () => {
        this.toastr.success('Utente aggiornato correttamente!');

        this.loadData()
      },
      error: (error) => {
        LoggerService.error('updateUser failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante l'aggiornamento dell'utente.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        this.userService.triggerUpdate()

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })

  }

  protected loadData() {
    this.userService.getCompanyCodes().pipe(
      tap((companies) => {
        LoggerService.log('Getting companies')
        this.companies = companies;
      }),
      concatMap(() => this.userService.getRoles()),
      tap((roles) => {
        LoggerService.log('Getting roles')

        this.roles = roles
      }),
      concatMap(() => this.userService.getByUsername(this.data.username)),
      tap((user) => {
        LoggerService.log('Getting user')

        this.data = user
      }),
    ).subscribe(ret => {
      this.setupTabs()
    })
  }

  protected validPasswords() {
    const password = this.form.controls.password.value ?? '';
    const confirmPassword = this.form.controls.confirmPassword.value ?? '';

    if ((password.length !== 0 || confirmPassword.length !== 0) && password !== confirmPassword) {
      LoggerService.error('Error validation')
      this.form.controls['password'].setErrors({
        'additionalValidator': true,
        message: 'Le password devono essere uguali'
      });
      this.form.controls['confirmPassword'].setErrors({
        'additionalValidator': true,
        message: 'Le password devono essere uguali'
      });
    }
  }

  public secondButtonAction() {
    this.router.navigate(['admin/users']);
  }

}
