import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  updateList = new Subject();

  onUpdate(): Observable<any> {
    return this.updateList.asObservable();
  }

  triggerUpdate() {
    this.updateList.next({});
  }
}
