export enum ControlType {
  repeater = 'repeater',
  date = 'date',
  dualList = 'dual-list',
  textInput = 'textinput',
  autocompleteModel = 'autocomplete-model',
  slideToggle = 'slide-toggle',
  password = 'password',
  dropdown = 'dropdown',
  hidden = 'hidden',
  number = 'number',
  multiSelect = 'multiselect',
  uploadFile = 'uploadFile',
  telephone = 'telephone',
  email = 'email',
}
