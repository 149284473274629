import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {faQuestion, faTrashCan, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() type: string = 'button';
  @Input() block: boolean = false;
  @Input() color: string = 'primary';
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() icon: string = '';
  @Input() content: string = '';
  @Input() data: any;
  @Input() showIcon: boolean = false;
  @Input() linkButton: string;
  @Input() external: boolean = false;
  @Input() toolTip: string = '';
  @Input() additionalClass: string;
  @Input() smallBtn: boolean;
  @Input() faIcon: IconDefinition;
  @Output() btnClicked = new EventEmitter<MouseEvent>();

  protected icons = {
    facebook: 'fab fa-facebook',
    google: 'fab fa-google',
    googlePlus: 'fab fa-google-plus',
    details: 'fab fa-info',
    delete: faTrashCan,
    question: faQuestion
  };

  constructor(protected router: Router, protected toastr: ToastrService) {
  }


  public actionClick(): void {
    return;
  }

  ngOnInit() {
    if (this.icon.length !== 0) {
      this.faIcon = this.icons[this.icon];
    }
  }

  btnClick() {
    if (this.external) {
      window.open(this.linkButton);
    } else {
      this.router.navigateByUrl(this.linkButton);
    }
  };
}
