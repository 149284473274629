<a
  (click)="handleMainMenuAction()"
  class="nav-link"
  [ngClass]="{active: isMainActive || isOneOfChildrenActive}"
>
  <i class="nav-icon {{ menuItem.iconClasses }}"></i>
  <p>{{ menuItem.name }}
    <i
      *ngIf="isExpandable && menuItem.children.length > 0"
      class="right fas fa-angle-right"
      [@rotate]="isMenuExtended"
    ></i>
  </p>
</a>
<ul class="nav nav-treeview" *ngFor="let item of menuItem.children" [@openClose]="isMenuExtended">
  <li class="nav-item children-menu">
    <a
      [routerLink]="item.path"
      [routerLinkActive]="'active'"
      class="nav-link"
    >
      <div class="flex gap-1">
        <i class="nav-icon mt-[4px] {{ item.iconClasses }}"></i>
        <p>{{ item.name }}</p>
      </div>
    </a>
  </li>
</ul>
