import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {ContactSourceModel} from "@/models/tables/contact-source.model";

@Injectable({
  providedIn: 'root'
})
export class ContactSourceService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/contactSource`);
  }

  deleteContactSource(contactSourceId: number) {
    return this.http.delete(this.baseUrl + `/tables/contactSource/${contactSourceId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteContactSource')

      return response;
    }));
  }

  saveContactSource(contactSource: ContactSourceModel) {
    return this.http.post(this.baseUrl + `/tables/contactSource/save`, contactSource).pipe(map((response: any) => {
      LoggerService.log(response, 'saveContactSource')

      return response;
    }));
  }
}
