import {ModelsModel} from "@/models/tables/models.model";

export class VehicleAccessoryModel {
  public id: number;
  public anagSiglaModello: ModelsModel;
  public descrizioneAccessorio: string;
  public prezzo: number;
  public provvigione: number;
  public enabled: boolean;
  public creator: string;
  public created: Date;
  public modifier: string;
  public modified: Date;
}
