export class EquipmentSettings {

  public static gridSettings() {
    return {
      actions: false,
      hideSubHeader: true,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager: {
        display: false
      },
      columns: {
        id: {
          title: 'ID'
        },
        codice: {
          title: 'Codice'
        },
        descrizione: {
          title: 'Descrizione'
        },
        prezzo: {
          title: 'Prezzo lordo'
        },
        prezzoNetto: {
          title: 'Prezzo netto'
        },
      }
    }
  }
}
