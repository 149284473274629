import {ButtonDetailMenuBIComponent} from "@components/button/tables/menu-bi-config/button-modify-menu-bi.component";
import {ButtonDeleteMenuBIComponent} from "@components/button/tables/menu-bi-config/button-delete-menu-bi.component";
import {AreaBiRendererComponent} from "@components/table-column-renderer/area-bi-renderer.component";

export class MenuBiConfigSettings {

    public static gridSettings() {
        return {
            actions: false,
            noDataMessage: 'Nessun dato trovato',
            attr: {
                class: 'table table-bordered'
            },
            pager:
                {
                    display: true,
                    perPage: 25
                },
            columns: {
                detailButton: {
                    type: 'custom',
                    renderComponent: ButtonDetailMenuBIComponent,
                    editable: false,
                    addable: false,
                    filter: false,
                    width: '50px'
                },
                deleteButton: {
                    type: 'custom',
                    renderComponent: ButtonDeleteMenuBIComponent,
                    editable: false,
                    addable: false,
                    filter: false,
                    width: '50px'
                },
                id: {
                    title: 'Id',
                    width: '70px'
                },
                areaBi: {
                  title: 'Area',
                  type: 'custom',
                  renderComponent: AreaBiRendererComponent,
                  width: '120px',
                  filter: {
                    type: 'list',
                    config: {
                      list: [],
                    }
                  },
                  filterFunction(cell?: any, search?: string): boolean {
                    return cell == search;
                  },
                },
                name: {
                    title: 'Nome'
                },
                url: {
                    title: 'Url'
                },
                descrizione: {
                    title: 'Descrizione'
                },
                enabled: {
                    width: '130px',
                    title: 'Abilitato',
                    valuePrepareFunction: (abilitato) => {
                        if (abilitato) {
                            return 'Si'
                        } else {
                            return 'No';
                        }
                    },
                    filter: {
                        type: 'list',
                        config: {
                            list: [
                                {value: true, title: 'Si'},
                                {value: false, title: 'No'},
                            ],
                        },
                    },
                },
            },
            delete: null
        };
    }
}
