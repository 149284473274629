import {Injectable} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {Observable} from 'rxjs';
import {LoggerService} from '@services/logger.service';
import {map} from 'rxjs/operators';
import {ProfileService} from "@services/admin/profile.service";

@Injectable({
  providedIn: 'root',
})
export class AuthRolesGuard implements CanActivate {
  constructor(private router: Router, private route: ActivatedRoute, private profileService: ProfileService, private logger: LoggerService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkRole(route);
  }

  private checkRole(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.profileService.getProfile().pipe(map((user) => {
      const authorized = [];
      LoggerService.log(route.data.roles);

      user.roles.forEach(function (role) {
        if (route.data.roles && route.data.roles.indexOf(role) === -1) {
          authorized.push(false);
        } else {
          authorized.push(true);
        }
      });

      if (!authorized.includes(true)) {
        this.router.navigate(['/']);
      }

      return authorized.includes(true);
    }));
  }
}
