<button *ngIf="!linkButton"
        mat-raised-button
        [type]="type"
        [ngStyle]="{'padding': smallBtn ? 0 : null, 'min-width': smallBtn ? 'min-content' : null}"
        class="btn btn-{{ color }}"
        [ngClass]="{'btn-block': block, additionalClass: additionalClass}"
        [disabled]="disabled || loading"
        [matTooltip]="toolTip"
        [matTooltipPosition]="'above'"
        (click)="actionClick(); btnClicked.emit($event)"
>
  <ng-content></ng-content>
  {{content}}
  <fa-icon *ngIf="showIcon" [icon]="faIcon"></fa-icon>
  <span
    *ngIf="loading"
    class="spinner-border spinner-border-sm ml-2"
    role="status"
    aria-hidden="true"
  ></span>
</button>
<button *ngIf="linkButton"
        mat-raised-button
        [type]="type"
        [style]="{'padding: 0; min-width: min-content;': smallBtn}"
        class="btn btn-{{ color }} {{additionalClass}}"
        [matTooltip]="toolTip"
        [matTooltipPosition]="'above'"
        [ngClass]="{'btn-block': block}"
        [disabled]="disabled || loading"
        (click)="btnClick(); btnClicked.emit($event)"
>
  <fa-icon *ngIf="showIcon" [icon]="faIcon"></fa-icon>
  {{content}}
  <span
    *ngIf="loading"
    class="spinner-border spinner-border-sm ml-2"
    role="status"
    aria-hidden="true"
  ></span>
</button>
