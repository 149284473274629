export class MenuBiConfigModel {
  public id: number;
  public areaBi: string;
  public name: string;
  public url: string;
  public descrizione: string;
  public permessoUtente: boolean
  public permessoResponsabile: boolean
  public permessoTitolare: boolean
  public enabled: boolean
  public creator: string;
  public created: Date;
  public modifier: string;
  public modified: Date;
}
