import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {ActionPracticesService} from "@services/tables/action-practices.service";
import {ActionPracticesSettings} from "@/table-settings/tables/action-practices-settings";

@Component({
  selector: 'app-action-practices',
  templateUrl: './action-practices.component.html',
  styleUrls: ['./action-practices.component.scss']
})
export class ActionPracticesComponent {

  source: LocalDataSource = new LocalDataSource([]);
  settings = ActionPracticesSettings.gridSettings();

  constructor(private actionPracticesService: ActionPracticesService) {
    Registry.getInstance().set('ActionPracticesComponent', this)

    this.loadActionPractices();
  }

  loadActionPractices() {
    this.actionPracticesService.getAll().subscribe((brands) => {
      this.source.load(brands);
    });
    this.actionPracticesService.getAllBrands().subscribe(brands => {
      const options = [];
      for (const brand of brands) {
        options.push({
          value: brand,
          title: brand
        });
      }

      this.settings.columns.marca.filter.config.list = options;
      this.settings = Object.assign({}, this.settings);
    });
  }
}
