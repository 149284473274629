import {ButtonDetailUserComponent} from '@components/button/users/button-detail-user.component';
import {ButtonDeleteUserComponent} from "@components/button/users/button-delete-user.component";
import {SlideToggleUserComponent} from "@components/slide-toggle-user/slide-toggle-user.component";

export class UsersSettings {
  public static gridSettings() {
    return {
      actions: {
        columnTitle: 'Azioni',
        add: false,
        edit: false,
        delete: false,
      },
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table',
      },
      pager: {
        display: false,
      },
      columns: {
        button: {
          type: 'custom',
          renderComponent: ButtonDetailUserComponent,
          editable: false,
          addable: false,
          filter: false,
        },
        deleteButton: {
          title: null,
          type: 'custom',
          renderComponent: ButtonDeleteUserComponent,
          editable: false,
          addable: false,
          filter: false,
        },
        enableButton: {
          title: 'Abilitato',
          type: 'custom',
          renderComponent: SlideToggleUserComponent,
          editable: false,
          addable: false,
          filter: false,
        },
        username: {
          title: 'Username',
          width: '10%'
        },
        name: {
          title: 'Nome',
          width: '10%'
        },
        lastname: {
          title: 'Cognome',
          width: '10%'
        },
        mobileNumber: {
          title: 'Cellulare',
          width: '15%'
        },
        email: {
          title: 'Email',
          width: '15%'
        },
        status: {
          title: 'Status',
          width: '10%'
        },
        enabled: {
          title: 'Abilitato',
          width: '8%',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si';
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
        modified: {
          title: 'Ultima Modifica',
          editable: false,
          addable: false,
          filter: false,
          width: '15%',
          valuePrepareFunction: (date) => {
            let result = null;
            if (date != null) {
              const raw = new Date(date);

              const dateOptions: Intl.DateTimeFormatOptions = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric'
              };

              result = raw.toLocaleDateString('it', dateOptions);
            }
            return result;
          }
        },
      },
      delete: null,
    };
  }
}
