import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-vehicle-accessory',
  templateUrl: './dialog-vehicle-accessory.component.html',
  styleUrls: ['./dialog-vehicle-accessory.component.scss']
})
export class DialogVehicleAccessoryComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }
}
