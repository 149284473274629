import {Component} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {ContactSourceService} from "@services/tables/contact-source.service";
import {ContactSourceFormSetting} from "@/form-settings/contact-source-form-setting";
import {ContactSourceModel} from "@/models/tables/contact-source.model";

@Component({
  selector: 'app-contact-source-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class ContactSourceFormComponent extends DynamicFormComponent {
  constructor(protected router: Router, protected userService: UserService, protected contactSourceService: ContactSourceService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())
  }

  onSubmit() {
    let contactSourceModel = <ContactSourceModel>this.form.getRawValue()

    this.contactSourceService.saveContactSource(contactSourceModel).subscribe({
      next: () => {
        this.toastr.success('Origine contatto salvata correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveContactSource failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio dell' Origine contatto.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = ContactSourceFormSetting
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogContactSourceComponentModify').close()
    } else {
      Registry.getInstance().get('DialogContactSourceComponentNew').close()
    }

    Registry.getInstance().get('ContactSourceComponent').loadContactSource()
  }

  protected secondaryButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('DialogContactSourceComponentModify').close()
    } else {
      Registry.getInstance().get('DialogContactSourceComponentNew').close()
    }
  }

}
