import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {SubagentAgencySettings} from "@/table-settings/tables/subagent-agency-settings";
import {SubagentAgencyService} from "@services/tables/subagent-agency.service";

@Component({
  selector: 'app-subagent-agency',
  templateUrl: './subagent-agency.component.html',
  styleUrls: ['./subagent-agency.component.scss']
})
export class SubagentAgencyComponent {

  source: LocalDataSource = new LocalDataSource([]);
  settings = SubagentAgencySettings.gridSettings();

  constructor(private subagentAgencyService: SubagentAgencyService) {
    this.loadUsers();

    Registry.getInstance().set('SubagentAgencyComponent', this);

    this.loadSubagentAgency();
  }

  loadUsers() {
    this.subagentAgencyService.getResponsibleSubagentUsers().subscribe(users => {
      this.settings.columns.users.filter.config.list = users.map(({ username }) => ({
        value: username,
        title: username
      }))
      this.settings = Object.assign({}, this.settings);
    })
    this.subagentAgencyService.getReferentsUsers().subscribe(users => {
      this.settings.columns.referenti.filter.config.list = users.map(({ username }) => ({
        value: username,
        title: username
      }))
      this.settings = Object.assign({}, this.settings);
    })
  }

  loadSubagentAgency() {
    this.subagentAgencyService.getAll().subscribe((brands) => {
      this.source.load(brands);
    });
  }
}
