import {Component} from '@angular/core';
import {ButtonComponent} from '@components/button/button.component';
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {AuthService} from "@services/auth.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-button-logout',
    templateUrl: '../button.component.html',
    styleUrls: ['../button.component.scss'],
})
export class ButtonLogoutComponent extends ButtonComponent {
    faIcon = faTrashCan;

    constructor(protected router: Router, public dialog: MatDialog, private authService: AuthService, protected toastr: ToastrService) {
        super(router, toastr);

        this.content = 'Log out'
    }

    public actionClick(): void {
        this.authService.logout();
    }
}
