<div [formGroup]="form">
  <div *ngIf="indexRow !== null" [formArrayName]="keyformArrayName">
    <div [formGroupName]="indexRow"
         [ngClass]="meta.disabled ? 'disabled-dropdown' : 'active'">
      <mat-label class="label-form">{{meta.label}}:</mat-label>
      <mat-form-field class="w-full additional-border-input mt-1" appearance="fill"
                      [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
        <mat-label>{{meta.label}}:</mat-label>
        <input type="text"
               placeholder="Seleziona un valore"
               matInput
               [formControl]="control"
               [formControlName]="meta.key"
               [matAutocomplete]="auto"
               (change)="customActionChange($event)">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                          (optionSelected)="customActionInput($event, indexRow)">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="indexRow === null" [ngClass]="meta.disabled ? 'disabled-dropdown' : 'active'">
    <mat-label class="label-form">{{meta.label}}:</mat-label>
    <mat-form-field class="w-full additional-border-input mt-1" appearance="fill"
                    [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
      <mat-label>{{meta.label}}</mat-label>
      <input type="text"
             placeholder="Seleziona un valore"
             matInput
             [formControl]="control"
             [formControlName]="meta.key"
             [matAutocomplete]="auto"
             (change)="customActionChange($event)">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                        (optionSelected)="customActionInput($event)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>
</div>
