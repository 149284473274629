import {Component} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {RentalAgencyService} from "@services/tables/rental-agency.service";
import {RentalAgencyModel} from "@/models/tables/rental-agency.model";
import {RentalAgencyFormSetting} from "@/form-settings/rental-agency-form-setting";

@Component({
  selector: 'app-rental-agency-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class RentalAgencyFormComponent extends DynamicFormComponent {
  constructor(protected router: Router, protected userService: UserService, protected rentalAgencyService: RentalAgencyService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())
  }

  onSubmit() {
    let sellerModel = <RentalAgencyModel>this.form.getRawValue()

    this.rentalAgencyService.saveRentalAgency(sellerModel).subscribe({
      next: () => {
        this.toastr.success('Agenzia Noleggio salvata correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveRentalAgency failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio dell'Agenzia Noleggio.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = RentalAgencyFormSetting
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogRentalAgencyComponentModify').close()
    } else {
      Registry.getInstance().get('DialogRentalAgencyComponentNew').close()
    }

    Registry.getInstance().get('RentalAgencyComponent').loadRentalAgency()
  }

  protected secondaryButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('DialogRentalAgencyComponentModify').close()
    } else {
      Registry.getInstance().get('DialogRentalAgencyComponentNew').close()
    }
  }

}
