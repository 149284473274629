import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {BrandSettings} from "@/table-settings/tables/brand-settings";
import {Registry} from "@/registry.service";
import {BrandsService} from "@services/tables/brands.service";

@Component({
  selector: 'app-stock',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent {

  source: LocalDataSource = new LocalDataSource([]);
  settings = BrandSettings.gridSettings();

  constructor(private brandsService: BrandsService) {
    Registry.getInstance().set('BrandComponent', this)

    this.loadBrands();
  }

  loadBrands() {
    this.brandsService.getAll().subscribe((brands) => {
      this.source.load(brands);
    });
  }
}
