import {Injectable} from '@angular/core';
import {TableDataApiParams} from "@services/practices.service";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {DataSourceBaseNew} from "@components/new-table/data-source-base-new.dataSource";
import {ImportFlowService} from "@services/imports/import-flow.service";

@Injectable({
  providedIn: 'root'
})
export class BuyerPracticeInvoiceDataSource extends DataSourceBaseNew<TablePracticeModel> {
  public content: any[] = [];
  public totalElements: number = 0;

  constructor(private importFlowService: ImportFlowService) {
    super();
  }

  loadData(params?: TableDataApiParams): void {
    this._isLoading$.next(true);
    this.elements$.next(this.content);
    this._totalElements$.next(this.totalElements);
    this._isLoading$.next(false);
  }
}
