export class SubAgent {
  id: number;
  descrizione: string;
  users: UserInfo[];
  enabled: boolean;
  creator: boolean;
  created: Date;
  modifier: string;
  modified: string;
}

export interface UserInfo {
  username: string;
  name: string;
  lastname: string;
  mobileNumber: string;
  email: string;
  status: string;
  enabled: boolean;
  creator: string;
  created: string;
  modifier: string;
  modified: string;
}
