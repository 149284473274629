import {Directive, ElementRef, HostListener, Input, OnInit,} from '@angular/core';
import {NumberInputPipe} from "@/pipes/number-input.pipe";
import {AbstractControl} from "@angular/forms";
import {LoggerService} from "@services/logger.service";

type TransformOutput = {
  rawNumber: number | string,
  formattedNumber: string
}

@Directive({
  selector: '[appCurrencyInput]'
})
export class CurrencyInputDirective implements OnInit {

  /*@Input() initialValue?: string | number;*/
  @Input() abstractControl?: AbstractControl

  constructor(
    private numberInputPipe: NumberInputPipe,
    private elementRef: ElementRef
  ) {
  }

  static getParsedValue(value: string | number) {
    value = typeof value === 'number' ? value.toString() : value;

    if (value.length <= 0) {
      return 0;
    }

    return parseFloat(
      value
        .replace(/\./g, '')
        .replace(/,/g, '\.')
    )
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.value = this.numberInputPipe.transform(this.abstractControl.getRawValue(), true)

    this.abstractControl.valueChanges.subscribe(v => {
      if (document.activeElement !== this.elementRef.nativeElement)
        this.elementRef.nativeElement.value = this.numberInputPipe.transform(v, true)
    })
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent) {
    this.setControlValue(
      this.elementRef.nativeElement.value
    )
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.stopPropagation();
    event.preventDefault();

    const clipboardData = event.clipboardData?.getData('Text');
    if (!clipboardData) return;

    if (/^[\d,]*$/g.test(clipboardData))
      this.setControlValue(
        clipboardData
      )
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    let e = event;

    // Allowed key codes (backspace, tab, escape, enter, etc.)
    const allowedKeys = [46, 8, 9, 27, 13, 110, 190, 188];
    const controlKeys = [65, 67, 86, 88]; // A, C, V, X for Ctrl + A/C/V/X

    // Check for allowed keys and control combinations
    if (
      allowedKeys.includes(e.keyCode) ||
      (controlKeys.includes(e.keyCode) && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode >= 35 && e.keyCode <= 39) // home, end, left, right arrows
    ) {
      return;
    }

    // Prevent input if shift key is pressed or if it's not a number
    if (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      LoggerService.log('STOP EVENT')
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    // Get the current input value and cursor position
    const inputElement = e.target;
    const value = inputElement.value;
    const cursorPos = inputElement.selectionStart;

    // Find the position of the decimal point
    const decimalPos = value.indexOf(',');

    // Block input if it results in more than two decimal places
    if (decimalPos >= 0 && cursorPos > decimalPos && value.length - decimalPos > 2) {
      LoggerService.log('STOP EVENT')
      e.stopPropagation();
      e.preventDefault();
    }
  }

  private setControlValue(value: string | number) {
    this.elementRef.nativeElement.value = this.numberInputPipe.transform(value);
    this.abstractControl.setValue(
      CurrencyInputDirective.getParsedValue(value)
    )
  }
}
