import {ModelsModel} from '@/models/tables/models.model';
import {BrandModel} from "@/models/tables/brand.model";
import {SellerModel} from "@/models/tables/seller.model";
import {PaymentTypeModel} from "@/models/tables/payment-type.model";
import {ContactSourceModel} from "@/models/tables/contact-source.model";
import {BigBuyersModel} from "@/models/tables/big-buyers.model";
import {FinancialDataAnalysisModel} from "@/models/financial-data-analysis.model";

export interface PracticeModel {
  id: number;
  statoPratica: string;
  tipoPratica: string;
  data: string;
  codiceVenditore: string;
  venditore: SellerModel;
  noteSegnalatore: string;
  segnalatore: boolean;
  daOrdinare: boolean;
  codicePratica: string;
  tipoVetturaAziendale: string;
  marca: BrandModel;
  marcaId: number;
  codiceModello: string;
  descrizioneModello: string;
  codiceColore: string;
  descrizioneColore: string;
  codiceColoreInterno: string;
  descrizioneColoreInterno: string;
  cliente: string;
  tipoCliente: string;
  tipoPagamento: PaymentTypeModel;
  codiceTabellaFin: string;
  codiceNoleggioId: string;
  codiceNoleggio: string;
  numPraticaFinanziamento: string;
  acconto: number;
  saldo: string;
  azioni: Array<any>;
  serviziFinanziari: Array<any>;
  analisiDatiFinanziari: FinancialDataAnalysisModel;
  serviziLoyalty: Array<any>;
  siglaModello: ModelsModel;
  creator: string;
  created: string;
  modifier: string;
  modified: string;
  numeroFattura: number;
  dataFattura: moment.Moment;
  importoFattura: string;
  serviziList: string;
  totPunteggioFinanziari: string;
  totPunteggioLoyalty: string;
  totPunteggioAltriServizi: string;
  prezzoImponibile: number;
  scontoTotale: number;
  percMargineGA: number;
  depositoCauzionale: boolean;
  importoDepositoCauzionale: number;
  nostroStock: string;
  totaleAzioni: number;
  scontoNettoAzioni: number;
  percScontoNettoAzioni: number;
  percProvvigione: number;
  provvigioneNettoAzioni: number;
  premioFisso: number;
  eventualiPremi: string;
  note: string;
  statoDocumentiPratica: string;
  percProvvigioneConcordata: number;
  provvigioneSegnalatore: number;
  provvigioneTotale: number;
  provvigioneAccessori: number;
  margineGA: string;
  omaggi: string;
  importoRateFinanaziamento: number;
  numeroRateFinanziamento: number;
  grandiAcquirenti: BigBuyersModel;
  codiceClienteContabilita: string;
  codiceContabilitaCliente: string;
  codiceContabilitaFornitore: string;
  dataScadenzaAutoDemo: moment.Moment;
  assegnatarioAutoDemo: string;
  telaio: string;
  targa: string;
  tipoAcquirente: string;
  origineContatto: ContactSourceModel;
  visibilitaSubagenti: boolean;
  descrizioneFamiglia: string;
  prezzoVenditaListinoNetto: number;
  prezzoVenditaListinoLordo: number;
  prezzoColoreNetto: number;
  prezzoColoreLordo: number;
  tipoAlimentazione: string;
  cilindrata: number;
  potenzaCV: number;
  potenzaKW: number;
  dataPrimaImmatricolazione: moment.Moment;
  dataRitiroVettura: moment.Moment;
  statoOrdineVgi: string;
  numeroPraticaVenditaAutoAziendale: string;
  venditaAutoAziendalePraticaId: number;
  lineaFatturaAcquistoList: Array<any>;
  lineaFatturaVenditaList: Array<any>;
  prezzoTotaleEquipaggiamentiNetto: number;
  prezzoTotaleEquipaggiamentiLordo: number;
  prezzoTotaleVeicoloNetto: number;
  prezzoTotaleVeicoloLordo: number;
  dataConsegna: moment.Moment;
  dataConsegnaReale: moment.Moment;
  equipaggiamenti: string;
  presenzaAccessori: boolean;
  presenzaPermute: boolean;
  contributoDealerObbligatorio: number;
  percContributoDealerObbligatorio: number;

  nrBpProprPropAcq: string;
  descrProprPropAcq: string;
}

export const PracticeDefaultValues: Partial<PracticeModel> = {
  prezzoImponibile: 0,
  percScontoNettoAzioni: 0,
  percContributoDealerObbligatorio: 0,
  provvigioneTotale: 0,
  provvigioneAccessori: 0,
  percProvvigione: 0,
  percProvvigioneConcordata: 0,
  provvigioneSegnalatore: 0,
  premioFisso: 0,
  provvigioneNettoAzioni: 0,
  scontoNettoAzioni: 0,
  scontoTotale: 0,
};

export interface PracticePageResp {
  content: PracticeModel[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  pageNumber: number;
  pageSize: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}
