import {Component} from '@angular/core';
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {ButtonComponent} from "@components/button/button.component";

@Component({
  selector: 'app-button-details',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss']
})
export class ButtonDetailsComponent extends ButtonComponent {

  faIcon = faPenToSquare;
  showIcon = true;
  additionalClass = 'py-0.5 px-2.5';
  smallBtn = true;
}

