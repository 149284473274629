export class CompanySettings {

  public static gridSettings() {
    return {
      actions: {
        columnTitle: 'Azioni',
        add: false,
        edit: false,
        delete: false,
      },
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table',
      },
      pager: {
        display: false,
      },
      columns: {
        companyCode: {
          title: 'Azienda'
        },
        enabled: {
          title: 'Abilitato',
          width: '8%',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si';
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },

      },
      delete: null,
    };
  }
}
