import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {MsDiscountModel} from "@/models/tables/ms-discount-model";
import {BiggerDiscountService} from "@services/bigger-discount.service";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs";
import {MSDiscountFormSetting} from "@/form-settings/msdiscount-form-setting";
import {OptionSelect} from "@/models/option-select.model";

@Component({
  selector: 'app-ms-discount-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class MSUpdatesFormComponent extends DynamicFormComponent implements OnInit, OnChanges {

  @Input() data: MsDiscountModel
  @Input() modalTrigger$ = new Subject<any>();
  private brands: OptionSelect[] = [];

  constructor(protected userService: UserService, protected toastr: ToastrService, protected biggerDiscountService: BiggerDiscountService) {
    super(toastr, userService)

    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'

    this.biggerDiscountService.getAllBrands().subscribe(brands => {
      this.brands = brands.map(brand => ({
        key: brand,
        value: brand.codiceMarca
      }))
      this.refreshForm()
    });
  }

  onSubmit() {
    let msDiscountModel = <any>this.form.getRawValue()
    msDiscountModel.anno = msDiscountModel.anno.year()
    msDiscountModel.mese = msDiscountModel.mese.month() + 1

    this.biggerDiscountService.saveBiggerDiscount(msDiscountModel).subscribe({
      next: (response) => {
        this.toastr.success('Maggiore Sconto correttamente!');

        this.modalTrigger$.next(1)
      },
      error: (error) => {
        LoggerService.error('saveBiggerDiscount failed!');
        LoggerService.error(error);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  ngOnInit(): void {
    this.setupMeta()
  }

  protected setupMeta() {
    this.meta = MSDiscountFormSetting
      .set('brands', this.brands)
      .set('defaultType', this.data.tipo ?? '')
      .getMeta()
  }

  protected secondaryButtonAction() {
    this.modalTrigger$.next(1)
  }
}
