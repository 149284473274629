import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {
  DialogMenuBiConfigComponent
} from "@pages/tables/menu-bi-config/dialog-menu-biconfig/dialog-menu-bi-config.component";
import {MenuBiConfigModel} from "@/models/tables/menu-bi-config.model";

@Component({
  selector: 'app-button-new-menu-bi',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonNewMenuBiComponent extends ButtonComponent {
  private dialogOpened: MatDialogRef<any>

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'ml-2'
    this.content = 'Nuovo menu BI'
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogMenuBiConfigComponent, {
      data: new MenuBiConfigModel(),
    });

    Registry.getInstance().set('DialogMenuBIConfigComponentNew', this.dialogOpened)
  }

}
