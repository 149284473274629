import {Injectable} from '@angular/core';
import {PracticesService, TableDataApiParams} from "@services/practices.service";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {DataSourceBaseNew} from "@components/new-table/data-source-base-new.dataSource";

@Injectable({
  providedIn: 'root'
})
export class PracticesDataSource extends DataSourceBaseNew<TablePracticeModel> {
  constructor(private practicesService: PracticesService) {
    super();
  }
  loadData(params?: TableDataApiParams): void {
    this._isLoading$.next(true);
    this.practicesService.getDataForTable(params).subscribe(data => {
      this.elements$.next(data.content);
      this._totalElements$.next(data.totalElements);
      this._isLoading$.next(false);
    })
  }
}
