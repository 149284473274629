import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {UsedVehicleModel} from "@/models/usedVehicleModel";

@Component({
  selector: 'app-dialog-detail-exchange',
  templateUrl: './dialog-detail-exchange.component.html',
  styleUrls: ['./dialog-detail-exchange.component.scss']
})

export class DialogDetailExchangeComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: UsedVehicleModel) {
  }

}
