import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {
  DialogInsuranceServiceTypeComponent
} from "@pages/tables/insurance-service-type/dialog-insurance-service-type/dialog-insurance-service-type.component";
import {InsuranceServiceTypeModel} from "@/models/tables/insurance-service-type.model";


@Component({
  selector: 'app-button-new-insurance-service-type',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonNewInsuranceServiceTypeComponent extends ButtonComponent {
  private dialogOpened: MatDialogRef<any>
  faIcon = faPenToSquare;

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'ml-2'
    this.content = 'Nuovo Servizio assicurativo'
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogInsuranceServiceTypeComponent, {
      data: new InsuranceServiceTypeModel(),
    });

    Registry.getInstance().set('DialogInsuranceServiceTypeComponentNew', this.dialogOpened)
  }

}
