import {Pipe, PipeTransform} from '@angular/core';
import {formatNumber} from "@angular/common";

@Pipe({
  name: 'numberInput'
})
export class NumberInputPipe implements PipeTransform {
  transform(value: string | number, dotAsComma: boolean = false): string {
    const defaultValue = '';
    value = typeof value === 'number' ? value.toString() : value;

    if (+value !== 0 && !value || isNaN(parseFloat(value))) return defaultValue;

    let valueParsed = value
      .replace(/\./g, dotAsComma ? ',' : '')
      .replace(/,/g, '.')
      .replace(/[^\d.]/g, '')
      .trim(); // Remove non-digits and trim

    const valuesSplit = valueParsed.split(".");
    let decimal = ''
    const integer = Number(valuesSplit[0])

    if (valuesSplit.length > 1) {
      decimal = valuesSplit[1].substring(0, 3)
    }

    if (integer || integer === 0) {
      return formatNumber(integer, 'it-IT', '0.0') + (value.includes(",") || decimal.length >= 1 ? ',' : '') + (decimal.length >= 1 ? decimal : '')
    }

    return defaultValue;
  }

}
