<mat-accordion>
  <mat-expansion-panel (closed)="panelOpenState = false"
                       (opened)="panelOpenState = true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Dati contabili
      </mat-panel-title>
      <mat-panel-description>
        Informazioni contabili dettagliate e registrazioni finanziarie.
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-accounting-practice-form [data]="formData"></app-accounting-practice-form>
  </mat-expansion-panel>
  <mat-expansion-panel (closed)="panelOpenState = false"
                       (opened)="panelOpenState = true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Dati fatture di vendita
      </mat-panel-title>
      <mat-panel-description>
        Dati dettagliati delle fatture di vendita.
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-new-table
      [dataSource]="dataSourceSellerInvoice"
      [displayedColumns]="displayedColumnsSellerInvoice"
      [initialSort]="{
              active: 'practiceNumber',
              direction: 'desc'
            }"
      [reloadTrigger$]="reloadTrigger$"
      [showElementsPerPage]="false"
      [showPaginate]="false"
    ></app-new-table>
  </mat-expansion-panel>
  <mat-expansion-panel (closed)="panelOpenState = false"
                       (opened)="panelOpenState = true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Dati fatture di acquisto
      </mat-panel-title>
      <mat-panel-description>
        Dettagli completi delle fatture di acquisto.
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-new-table
      [dataSource]="dataSourceBuyerInvoice"
      [displayedColumns]="displayedColumnsBuyerInvoice"
      [initialSort]="{ active: 'practiceNumber', direction: 'desc'}"
      [reloadTrigger$]="reloadTrigger$"
      [showElementsPerPage]="false"
      [showPaginate]="false"
    ></app-new-table>
  </mat-expansion-panel>
</mat-accordion>
