import {OptionSelect} from "@/models/option-select.model";

export class UsedVehicleFormSetting {

  private static brands: OptionSelect[] = [];
  protected static classToCall: string
  private static addClient: boolean = false

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this;
  }

  public static activeClient() {
    this.addClient = true

    return this;
  }

  public static setBrands(brands) {
    this.brands = brands;

    return this;
  }

  public static getMeta(): Array<any> {
    let meta = [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "stato",
            "controlType": "textinput",
            "label": "Stato",
            "order": 1,
            "class": "w-full",
            "disabled": true
          },
        ]
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "marca",
            "compareFn": (o1, o2) => o1?.id === o2?.id,
            "controlType": "dropdown",
            "label": "Marca",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full additional-border-input",
            "required": true,
            "options": this.brands
          },
          {
            "classToUse": this.classToCall,
            "key": "modello",
            "controlType": "textinput",
            "label": "Modello",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full xs:w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "targa",
            "controlType": "textinput",
            "label": "Targa",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "importo",
            "controlType": "number",
            "label": "Importo",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "dataPresuntoRitiro",
            "controlType": "date",
            "label": "Data presunto ritiro",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "dataRitiro",
            "controlType": "date",
            "label": "Data ritiro",
            "order": 1,
            "disabled": true,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "autorizzato",
            "controlType": "textinput",
            "label": "Autorizzato",
            "order": 1,
            "class": "lg:w-6/12 md:w-6/12 xs:w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "speseUsato",
            "controlType": "slide-toggle",
            "label": "Spese Usato",
            "order": 1,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "default": false
          },
          {
            "classToUse": this.classToCall,
            "key": "chilometriPercorsi",
            "controlType": "number",
            "label": "Chilometri percorsi",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "colore",
            "controlType": "textinput",
            "label": "Colore",
            "order": 1,
            "class": "lg:w-6/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "tipologiaFatturazione",
            "controlType": "dropdown",
            "label": "Tipologia Fatturazione",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
            "options": [
              {"value": "Privato", "key": "PRIVATO"},
              {"value": "Fattura Esente", "key": "FATTURA_ESENTE"},
              {"value": "Fattura con IVA", "key": "FATTURA_CON_IVA"},
              {"value": "Fattura con IVA Parziale (40-60)", "key": "FATTURA_CON_IVA_PARZIALE"},
            ],
          },
          {
            "classToUse": this.classToCall,
            "key": "intestato",
            "controlType": "textinput",
            "label": "Intestato a",
            "order": 1,
            "class": "lg:w-6/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "destinazioneUsato",
            "controlType": "dropdown",
            "label": "Destinazione",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
            "options": [
              {"value": "Permuta", "key": "PERMUTA"},
              {"value": "Rottamazione", "key": "ROTTAMAZIONE"}
            ],
          },
          {
            "classToUse": this.classToCall,
            "key": "scadenzaBollo",
            "controlType": "date",
            "label": "Scadenza bollo",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "scadenzaRevisione",
            "controlType": "date",
            "label": "Scadenza revisione",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "presenzaGravamiContratto",
            "controlType": "slide-toggle",
            "label": "Presenza gravami in fase di contratto",
            "order": 1,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "default": false
          },
          {
            "classToUse": this.classToCall,
            "key": "presenzaGravamiRitiro",
            "controlType": "slide-toggle",
            "label": "Presenza gravami in fase di ritiro",
            "order": 1,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "default": false
          },
          {
            "classToUse": this.classToCall,
            "key": "valoreContabileUsato",
            "controlType": "number",
            "label": "Valore contabile usato",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "estinzioneFinanziamento",
            "controlType": "number",
            "label": "Estinzione finanziamento (valore usato)",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "codiceFornitore",
            "controlType": "textinput",
            "label": "Codice fornitore",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "speseLeggeDini",
            "controlType": "number",
            "label": "Spese legge Dini",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "speseOfficinaInterna",
            "controlType": "number",
            "label": "Spese Officina Interna",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "speseCarrozzeria",
            "controlType": "number",
            "label": "Spese carrozzeria",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "speseGommista",
            "controlType": "number",
            "label": "Spese gommista",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "speseLavaggio",
            "controlType": "number",
            "label": "Spese lavaggio",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "speseGaranzia",
            "controlType": "number",
            "label": "Spese garanzia",
            "order": 1,
            "class": "lg:w-3/12 md:w-6/12 xs:w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "note",
            "controlType": "textarea",
            "label": "Note",
            "order": 1,
            "class": "w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "pratica",
            "controlType": "hidden",
            "label": "",
            "order": 1,
          },
          {
            "classToUse": this.classToCall,
            "key": "id",
            "controlType": "hidden",
            "label": "",
            "order": 1,
          },
        ],
        "order": 1
      },
    ]

    if (this.addClient) {
      meta = [...[
        {
          "rows": [
            {
              "classToUse": this.classToCall,
              "key": "cliente",
              "controlType": "textinput",
              "label": "Cliente",
              "order": 1,
              "class": "w-full",
            },
          ],
          order: 1
        }
      ], ...meta]
    }

    return meta;
  }
}
