import {ButtonDetailExchangeComponent} from "@components/button/practices/button-detail-exchange.component";
import {ButtonDeleteExchangeComponent} from "@components/button/practices/button-delete-exchange.component";
import {ButtonDownloadExchangeComponent} from "@components/button/practices/button-download-exchange.component";

export class ExchangeSettings {

    public static gridSettings() {
        return {
            actions: false,
            hideSubHeader: true,
            noDataMessage: 'Nessun dato trovato',
            attr: {
                class: 'table table-bordered'
            },
            pager: {
                display: false
            },
            columns: {
              downloadButton: {
                type: 'custom',
                renderComponent: ButtonDownloadExchangeComponent,
                editable: false,
                addable: false,
                filter: false,
                width: '50px'
              },
              detailButton: {
                    type: 'custom',
                    renderComponent: ButtonDetailExchangeComponent,
                    editable: false,
                    addable: false,
                    filter: false,
                    width: '50px'
                },
                deleteButton: {
                    type: 'custom',
                    renderComponent: ButtonDeleteExchangeComponent,
                    editable: false,
                    addable: false,
                    filter: false,
                    width: '50px'
                },
                stato: {
                    title: 'Stato'
                },
                marca: {
                    title: 'Marca',
                    width: '120px',
                    valuePrepareFunction: (cell, row) => {
                        return row.marca.descrizioneMarca;
                    },
                    filter: {
                        type: 'list',
                        config: {
                            list: [],
                        }
                    },
                    filterFunction(cell?: any, search?: string): boolean {
                        return cell == search;
                    },
                },
                modello: {
                    title: 'Modello'
                },
                targa: {
                    title: 'Targa'
                },
                importo: {
                    title: 'Importo',
                    type: 'html',
                    valuePrepareFunction: (data) => {
                        return '<p class="cell_right">' + Intl.NumberFormat('it-IT', {
                            style: 'currency',
                            currency: 'EUR'
                        }).format(data) + '</p>';
                    }
                },
                autorizzato: {
                    title: 'Autorizzato da'
                },
                dataPresuntoRitiro: {
                    title: 'Data presunta ritiro',
                    width: '80px',
                    valuePrepareFunction: (date) => {
                        if (date) {
                            let raw = new Date(date);

                            const options: Intl.DateTimeFormatOptions = {
                                year: 'numeric', month: '2-digit', day: '2-digit',
                            };

                            return raw.toLocaleDateString('it', options);
                        }
                        return null;
                    },
                    filter: {
                        type: 'daterange',
                        config: {
                            daterange: {
                                format: 'dd/mm/yyyy',
                            },
                        },
                    },
                    filterFunction(cell?: string, search?: any): boolean {
                        if (search != null) {
                            const date1 = search.split('..')[0];
                            let date2 = search.split('..')[1];

                            if (date1 != 0 && date2 != 0) {
                                let startStr = '' + date1 + '000';
                                const start = new Date(parseInt(startStr));
                                start.setHours(0, 0, 0, 0);

                                const endStr = '' + date2 + '000';
                                const end = new Date(parseInt(endStr));
                                end.setHours(23, 59, 59, 999);

                                const myDate = new Date(cell);

                                return myDate.getTime() >= start.getTime() && myDate.getTime() <= end.getTime();
                            }

                            return true;
                        }
                    },
                },
                dataRitiro: {
                    title: 'Data ritiro',
                    width: '80px',
                    valuePrepareFunction: (date) => {
                        if (date) {
                            let raw = new Date(date);

                            const options: Intl.DateTimeFormatOptions = {
                                year: 'numeric', month: '2-digit', day: '2-digit',
                            };

                            return raw.toLocaleDateString('it', options);
                        }
                        return null;
                    },
                    filter: {
                        type: 'daterange',
                        config: {
                            daterange: {
                                format: 'dd/mm/yyyy',
                            },
                        },
                    },
                    filterFunction(cell?: string, search?: any): boolean {
                        if (search != null) {
                            let date1 = search.split('..')[0];
                            const date2 = search.split('..')[1];

                            if (date1 != 0 && date2 != 0) {
                                const startStr = '' + date1 + '000';
                                const start = new Date(parseInt(startStr));
                                start.setHours(0, 0, 0, 0);

                                let endStr = '' + date2 + '000';
                                const end = new Date(parseInt(endStr));
                                end.setHours(23, 59, 59, 999);

                                let myDate = new Date(cell);

                                return myDate.getTime() >= start.getTime() && myDate.getTime() <= end.getTime();
                            }

                            return true;
                        }
                    },
                },
                speseUsato: {
                    title: 'Spese Usato',
                    valuePrepareFunction: (speseUsato) => {
                        if (speseUsato) {
                            return 'Si'
                        } else {
                            return 'No';
                        }
                    },
                    filter: {
                        type: 'list',
                        config: {
                            list: [
                                {value: true, title: 'Si'},
                                {value: false, title: 'No'},
                            ],
                        },
                    },
                },
                creator: {
                    title: 'Creato da'
                },
                created: {
                    title: 'Creato il',
                    width: '120px',
                    sortDirection: 'desc',
                    valuePrepareFunction: (date) => {
                        const raw = new Date(date);

                        const options: Intl.DateTimeFormatOptions = {
                            year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric',
                        };

                        return raw.toLocaleDateString('it', options);
                    },
                    filter: false
                }
            }
        }
    }
}
