import {Component, inject} from '@angular/core';
import {ImportFlowService} from "@services/imports/import-flow.service";
import {TableColumnsSetting} from "@/interfaces/table-setting";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {VwfsDashboardDataSource} from "@components/new-table/data-sources/vwfs-dashboard.dataSource";
import {VwfsDashboardSettingsTable} from "@/table-settings/imports/vwfs-dashboard-settings-table";

@Component({
  selector: 'app-vwfs-dashboard-table',
  templateUrl: './vwfs-dashboard-table.component.html',
  styleUrls: ['./vwfs-dashboard-table.component.scss']
})
export class VwfsDashboardTableComponent {

  displayedColumns: TableColumnsSetting<any>

  dataSource = new VwfsDashboardDataSource(this.importFlowService);

  toastr = inject(ToastrService);
  dialog = inject(MatDialog);

  reloadTrigger$ = new Subject<any>();

  constructor(private importFlowService: ImportFlowService) {
    this.displayedColumns = VwfsDashboardSettingsTable.getColumnsSetting();
  }
}
