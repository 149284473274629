import {Injectable} from '@angular/core';
import {TableDataApiParams} from "@services/practices.service";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {DataSourceBaseNew} from "@components/new-table/data-source-base-new.dataSource";
import {ImportFlowService} from "@services/imports/import-flow.service";

@Injectable({
  providedIn: 'root'
})
export class VwbankCommissionDataSource extends DataSourceBaseNew<TablePracticeModel> {
  constructor(private importFlowService: ImportFlowService) {
    super();
  }

  loadData(params?: TableDataApiParams): void {
    this._isLoading$.next(true);
    this.importFlowService.getVwbankCommissionData(params).subscribe(data => {
      this.elements$.next(data.content);
      this._totalElements$.next(data.totalElements);
      this._isLoading$.next(false);
    })
  }
}
