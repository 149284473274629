import {OptionSelect} from "@/models/option-select.model";

export class DocumentForm {
  protected static classToCall: string
  private static category: OptionSelect[] = [];

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static setCategory(category) {
    this.category = category

    return this
  }

  public static getMeta(): Array<any> {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "file",
            "controlType": "uploadFile",
            "accept": "application/pdf",
            "label": "Carica un documento",
            "order": 1,
            "multiple": false,
            "class": "w-full",
            "customAction": "onFileSelected",
            "required": true,
            "inputUploadText": 'Carica qui il documento'
          },
          {
            "classToUse": this.classToCall,
            "key": "tipologiaDocumentoPratica",
            "controlType": "dropdown",
            "label": "Tipologia documento",
            "order": 1,
            "class": "w-6/12",
            "required": true,
            "options": this.category
          },
          {
            "classToUse": this.classToCall,
            "key": "praticaId",
            "controlType": "hidden",
            "accept": "application/pdf",
            "order": 1,
            "required": true,
          },
        ],
        "order": 1
      },
    ]
  }
}
