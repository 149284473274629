import {Injectable} from '@angular/core';
import {ApiService} from '@services/api.service';
import {User} from '@/models/user.model';
import {HttpHeaders} from '@angular/common/http';
import {Registry} from "@/registry.service";

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ApiService {
  login({username, password}) {

    this.http.post<any>(this.baseUrl + '/auth/signin/', {username: username, password: password})
      .subscribe(
        (data) => {
          const auth_token = data.token.toString();
          Registry.getInstance().set('UserData', data);

          localStorage.setItem('cassa.jwttoken', data.token.toString());

          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth_token}`,
          });

          this.http.get<User>(this.baseUrl + '/admin/profile/userInfo', {headers: headers}).subscribe((user) => {
            localStorage.setItem('cassa.userRoles', JSON.stringify(user.roles));
            localStorage.setItem('cassa.userName', JSON.stringify(user.username));
          });

          this.router.navigate(['/']);
        },
        (error) => {
          this.toastr.error(error.message);
        },
      );
  }
  
  logout() {
    localStorage.removeItem('cassa.jwttoken');
    localStorage.removeItem('cassa.userRoles');
    localStorage.removeItem('cassa.userName');

    this.router.navigate(['/login']);
  }

  public isAuthenticated(): boolean {
    const token = this.jwtHelper.getToken();

    return !this.jwtHelper.isTokenExpired(token);
  }
}
