import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Registry {
  public data = {};
  private static instance: Registry = new Registry();

  public static getInstance() {
    if (!this.instance) {
      this.instance = new Registry();
    }

    return this.instance;
  }

  public set(key: string, data: any) {
    this.data[key] = data
  }

  public get(key: string) {
    return this.data[key]
  }
}
