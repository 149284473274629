import {Component, Input, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {formatNumber} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {LoggerService} from "@services/logger.service";
import {BiggerDiscountService} from "@services/bigger-discount.service";

export interface TableBiggerDiscountColumnStructure {
  anno: number
  codiceMarca: string
  consegneTotali: number
  deltaObiettivo: number
  descrizioneMarca: string
  tipo: string
  id: string
  mese: number
  obiettivoMese: number
  percObiettivo: number
  totalePrezzoAcquistoNetto: number
  percMsPrevista: number
  maturatoPrevisto: number
  importoLiquidato: number
}

@Component({
  selector: 'app-table-bigger-discount',
  templateUrl: './table-bigger-discount.component.html',
  styleUrls: ['./table-bigger-discount.component.scss']
})
export class TableBiggerDiscountComponent implements OnInit {
  @Input() data: object
  @Input() triggerDate$: Subject<any>

  today = new Date()
  displayedColumns: string[] = ['marca', 'tipo', 'obiettivoMese', 'consegneTotali', 'deltaObiettivo', 'percObiettivo', 'totalePrezzoAcquistoNetto','percMsPrevista','maturatoPrevisto','importoLiquidato'];
  columns = [
    {
      columnDef: 'marca',
      header: 'Marca',
      cell: (element: TableBiggerDiscountColumnStructure) => `${element.descrizioneMarca}`,
    },
    {
      columnDef: 'tipo',
      header: 'Tipo vendita',
      cell: (element: TableBiggerDiscountColumnStructure) => `${element.tipo}`,
    },
    {
      columnDef: 'obiettivoMese',
      header: 'Obiettivo mese',
      cell: (element: TableBiggerDiscountColumnStructure) => `${element.obiettivoMese}`,
    },
    {
      columnDef: 'consegneTotali',
      header: 'Consegne Totali',
      cell: (element: TableBiggerDiscountColumnStructure) => `${element.consegneTotali}`,
    },
    {
      columnDef: 'deltaObiettivo',
      header: 'delta Obiettivo',
      cell: (element: TableBiggerDiscountColumnStructure) => `${element.deltaObiettivo}`,
    },
    {
      columnDef: 'percObiettivo',
      header: '% Obiettivo',
      cell: (element: TableBiggerDiscountColumnStructure) => formatNumber(Number(`${element.percObiettivo}`), 'it-IT', '1.2-2') + ' %',
    },
    {
      columnDef: 'totalePrezzoAcquistoNetto',
      header: 'Totale prezzo acquisto netto',
      cell: (element: TableBiggerDiscountColumnStructure) => formatNumber(Number(`${element.totalePrezzoAcquistoNetto}`), 'it-IT', '1.2-2'),
    },
    {
      columnDef: 'percMsPrevista',
      header: '% MS prevista',
      cell: (element: TableBiggerDiscountColumnStructure) => formatNumber(Number(`${element.percMsPrevista}`), 'it-IT', '1.2-2') + ' %',
    },
    {
      columnDef: 'maturatoPrevisto',
      header: 'Maturato previsto',
      cell: (element: TableBiggerDiscountColumnStructure) => formatNumber(Number(`${element.maturatoPrevisto}`), 'it-IT', '1.2-2'),
    },
    {
      columnDef: 'importoLiquidato',
      header: 'Importo liquidato',
      cell: (element: TableBiggerDiscountColumnStructure) => formatNumber(Number(`${element.importoLiquidato}`), 'it-IT', '1.2-2'),
    },
  ];
  dataSource: TableBiggerDiscountColumnStructure[] = [];

  constructor(private biggerDiscountService: BiggerDiscountService, protected toastr: ToastrService) {
  }

  ngOnInit(): void {
    const year = this.today.getFullYear()
    const month = this.today.getMonth() + 1

    this.biggerDiscountService.getDashboard(year, month).subscribe({
      next: (data) => {
        this.triggerDate$.next({
          'year': year,
          'month': month
        })
        this.dataSource = data
      },
      error: (error) => {
        LoggerService.error(error);
      },
      complete: () => {
        LoggerService.log('Request done')
      },
    });

    this.triggerDate$.subscribe({
      next: (objectDate) => {
        LoggerService.log('UPDATE TRIGGER TABLE')
        this.biggerDiscountService.getDashboard(objectDate.year, objectDate.month).subscribe({
          next: (data) => {
            this.dataSource = data
          },
          error: (error) => {
            LoggerService.error(error);

            this.toastr.error(error);
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

}
