import {Injectable} from '@angular/core';
import {TableDataApiParams} from "@services/practices.service";
import {Observable} from "rxjs";
import {TablePaginationRespModel} from "@/models/table-pagination-resp.model";
import {tap} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {ApiService} from "@services/api.service";

@Injectable({
  providedIn: 'root'
})
export class TableService extends ApiService {
  getTableData<T>(pageName: string, params?: TableDataApiParams): Observable<TablePaginationRespModel<T>> {
    return this.http.get<TablePaginationRespModel<T>>(this.baseUrl + `/${pageName}/page`, {
      params: {...params}
    }).pipe(
      tap(r => LoggerService.log(r, 'table page'))
    )
  }
}
