import {Component, Input, OnInit} from '@angular/core';
import {DeliveryCardboardService} from "@services/delivery-cardboard.service";
import {LoggerService} from "@services/logger.service";
import {Subject} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {formatNumber} from "@angular/common";

export interface TableDeliveryTableColumnStructure {
  anno: number
  codiceMarca: string
  consegneProvvisorie: number
  consegneTrasmesse: number
  deltaObiettivoProvvisorio: number
  deltaObiettivoReale: number
  descrizioneMarca: string
  tipo: string
  id: string
  mese: number
  obiettivoMese: number
  percObiettivoProvvisorio: number
  percObiettivoReale: number
}

@Component({
  selector: 'app-table-delivery-carton',
  templateUrl: './table-delivery-carton.component.html',
  styleUrls: ['./table-delivery-carton.component.scss']
})
export class TableDeliveryCartonComponent implements OnInit {
  @Input() data: object
  @Input() triggerDate$: Subject<any>

  today = new Date()
  displayedColumns: string[] = ['marca', 'tipo', 'obiettivoMese', 'consegneProvvisorie', 'consegneTrasmesse', 'deltaObiettivoProvvisorio', 'deltaObiettivoReale', 'percObiettivoProvvisorio', 'percObiettivoReale'];
  columns = [
    {
      columnDef: 'marca',
      header: 'Marca',
      cell: (element: TableDeliveryTableColumnStructure) => `${element.descrizioneMarca}`,
    },
    {
      columnDef: 'tipo',
      header: 'Tipo vendita',
      cell: (element: TableDeliveryTableColumnStructure) => `${element.tipo}`,
    },
    {
      columnDef: 'obiettivoMese',
      header: 'Obiettivo mese',
      cell: (element: TableDeliveryTableColumnStructure) => `${element.obiettivoMese}`,
    },
    {
      columnDef: 'consegneProvvisorie',
      header: 'Consegne Totali',
      cell: (element: TableDeliveryTableColumnStructure) => `${element.consegneProvvisorie}`,
    },
    {
      columnDef: 'consegneTrasmesse',
      header: 'Consegne Trasmesse',
      cell: (element: TableDeliveryTableColumnStructure) => `${element.consegneTrasmesse}`,
    },
    {
      columnDef: 'deltaObiettivoProvvisorio',
      header: 'delta Obiettivo Provvisorio',
      cell: (element: TableDeliveryTableColumnStructure) => `${element.deltaObiettivoProvvisorio}`,
    },
    {
      columnDef: 'deltaObiettivoReale',
      header: 'delta Obiettivo Reale',
      cell: (element: TableDeliveryTableColumnStructure) => `${element.deltaObiettivoReale}`,
    },
    {
      columnDef: 'percObiettivoProvvisorio',
      header: '% Obiettivo Provvisorio',
      cell: (element: TableDeliveryTableColumnStructure) => formatNumber(Number(`${element.percObiettivoProvvisorio}`), 'it-IT', '1.0-2') + ' %',
    },
    {
      columnDef: 'percObiettivoReale',
      header: '% Obiettivo Reale',
      cell: (element: TableDeliveryTableColumnStructure) => formatNumber(Number(`${element.percObiettivoReale}`), 'it-IT', '1.0-2') + ' %',
    },
  ];
  dataSource: TableDeliveryTableColumnStructure[] = [];

  constructor(private deliveryCardboardService: DeliveryCardboardService, protected toastr: ToastrService) {
  }

  ngOnInit(): void {
    const year = this.today.getFullYear()
    const month = this.today.getMonth() + 1

    this.deliveryCardboardService.getDashboard(year, month).subscribe({
      next: (data) => {
        this.triggerDate$.next({
          'year': year,
          'month': month
        })
        this.dataSource = data
      },
      error: (error) => {
        LoggerService.error(error);
      },
      complete: () => {
        LoggerService.log('Request done')
      },
    });

    this.triggerDate$.subscribe({
      next: (objectDate) => {
        LoggerService.log('UPDATE TRIGGER TABLE', objectDate)
        this.deliveryCardboardService.getDashboard(objectDate.year, objectDate.month).subscribe({
          next: (data) => {
            this.dataSource = data
          },
          error: (error) => {
            LoggerService.error(error);

            this.toastr.error(error);
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

}
