import {Component} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {ToastrService} from "ngx-toastr";
import {UserService} from "@services/admin/user.service";
import {Registry} from "@/registry.service";
import {VehicleDataPracticeForm} from "@/form-settings/vehicle-data-practice-form";

@Component({
  selector: 'app-detail-practice-vehicle-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class DetailPracticeVehicleFormComponent extends DynamicFormComponent {

  constructor(protected toastr: ToastrService, protected userService: UserService) {
    super(toastr, userService)

    this.submitTopButton = false
    this.submitBottomButton = false
    this.setupMeta()

    Registry.getInstance().set('DetailPracticeVehicleFormComponent', this)
  }

  protected setupMeta() {
    this.meta = VehicleDataPracticeForm.getMeta()
  }

  public ngOnChanges() {
    this.refreshForm()

    if (Object.values(this.data).length !== 0) {
      this.setupMeta()
    }
  }
}
