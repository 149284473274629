import {Injectable} from '@angular/core';
import {LoggerService} from '@services/logger.service';
import jwtDecode, {JwtPayload} from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class JwtHelperService {

  isTokenExpired(token: string) {
    LoggerService.log('Decoding JWT token');

    if (token.length === 0) {
      LoggerService.error('JWT token is not valid!');

      return true;
    }

    const jwtDecoded = jwtDecode<JwtPayload>(token); // Returns with the JwtPayload type

    if (Date.now() >= jwtDecoded.exp * 1000) {
      LoggerService.error('JWT token expired!');

      return true;
    }

    return false;
  }

  getToken(): string {
    return localStorage.getItem('cassa.jwttoken') ?? '';
  }
}
