import {TableColumnsSetting} from "@/interfaces/table-setting";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {of} from "rxjs";

export class ImportStatusTableSettings {

  public static getColumnsSetting(): TableColumnsSetting<keyof TablePracticeModel> {
    return [
      {
        name: "id",
        text: "Import. Id",
        filter: {
          type: "input",
        }
      },
      {
        name: "importFileName",
        text: "Nome File",
        filter: {
          type: "input",
        }
      },
      {
        name: "state",
        text: "Status Importazione",
        filter: {
          type: "select",
          options: of([
            {'completed': 'COMPLETATO',},
            {'to-import': 'DA IMPORTARE'},
            {'on-working': 'IN LAVORAZIONE'},
            {'failed': 'FALLITO'}
          ])
        }
      },
      {
        name: "createdAt",
        type: "datetime",
        text: "creato il",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "updatedAt",
        type: "datetime",
        text: "modificato il",
        filter: {
          type: 'dateRange'
        }
      }
    ]
  }
}
