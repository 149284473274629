import {BrandModel} from "@/models/tables/brand.model";

export class MsDiscountModel {
  id: number;
  anno: number;
  mese: number;
  marca: BrandModel;
  tipo: string;
  codiceMs: string;
  descrizioneMs: string;
  classificazioneMs: string;
  tipologiaMs: string;
  fascia: string;
  descRaggiungimentoObiettivo: string;
  percObiettivo: number;
  percMsPrevista: number;
  obiettivoRaggiunto: number;
  posizioneRanking: number;
}
