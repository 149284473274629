import {Component} from '@angular/core';
import {ButtonComponent} from '@components/button/button.component';
import {Router} from "@angular/router";
import {User} from "@/models/user.model";
import {faUserPen} from "@fortawesome/free-solid-svg-icons";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-button-user-detail',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss'],
})
export class ButtonDetailUserComponent extends ButtonComponent {
  private user: User;
  private rowData: any;
  faIcon = faUserPen;

  constructor(protected router: Router, protected toastr: ToastrService) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio Utente'
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.user = this.rowData;
  }

  /**
   * Open user detail page
   */
  public actionClick(): void {
    const username = this.user.username;
    this.router.navigate([`/admin/users/${username}`])
  }
}
