import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {
    DialogVehicleAccessoryComponent
} from "@pages/tables/vehicle-accessory/dialog-vehicle-accessory/dialog-vehicle-accessory.component";
import {VehicleAccessoryModel} from "@/models/tables/vehicle-accessory.model";

@Component({
    selector: 'app-button-new-vehicle-accessory',
    templateUrl: '../../button.component.html',
    styleUrls: ['../../button.component.scss'],
})
export class ButtonNewVehicleAccessoryComponent extends ButtonComponent {
    private dialogOpened: MatDialogRef<any>
    faIcon = faPenToSquare;

    constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
        super(router, toastr);

        this.showIcon = false
        this.additionalClass = 'ml-2'
        this.content = 'Nuovo Accessorio Veicolo'
    }

    /**
     * Open modal new detail page
     */
    public actionClick(): void {
        this.dialogOpened = this.dialog.open(DialogVehicleAccessoryComponent, {
            data: new VehicleAccessoryModel(),
        });

        Registry.getInstance().set('DialogVehicleAccessoryComponentNew', this.dialogOpened)
    }

}
