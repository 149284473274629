import {Component, inject} from '@angular/core';
import {ImportFlowService} from "@services/imports/import-flow.service";
import {TableColumnsSetting} from "@/interfaces/table-setting";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {VwfsCommissionSettingsTable} from "@/table-settings/imports/vwfs-commission-settings-table";
import {VwfsCommissionDataSource} from "@components/new-table/data-sources/vwfs-commission.dataSource";

@Component({
  selector: 'app-vwfs-commission-table',
  templateUrl: './vwfs-commission-table.component.html',
  styleUrls: ['./vwfs-commission-table.component.scss']
})
export class VwfsCommissionTableComponent {

  displayedColumns: TableColumnsSetting<any>

  dataSource = new VwfsCommissionDataSource(this.importFlowService);

  toastr = inject(ToastrService);
  dialog = inject(MatDialog);

  reloadTrigger$ = new Subject<any>();

  constructor(private importFlowService: ImportFlowService) {
    this.displayedColumns = VwfsCommissionSettingsTable.getColumnsSetting();
  }
}
