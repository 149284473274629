import {SubAgent, UserInfo} from "@/models/sub-agent";

export class SellerModel {
  id: number;
  codiceVenditore: string;
  descrizione: string;
  marca: string;
  tipoVenditore: string;
  agenziaSubagente: SubAgent;
  users: UserInfo[] = [];
  enabled: boolean;
  segnalatore: boolean;
}
