import {ButtonComponent} from "@components/button/button.component";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogMSUpdateComponent} from "@pages/bigger-discount/table-ms/dialog-msupdate/dialog-msupdate.component";
import {Subject} from "rxjs";
import {MsDiscountModel} from "@/models/tables/ms-discount-model";
import {Component} from "@angular/core";

@Component({
  selector: 'app-button-new-ms',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class NewButtonMSDiscountComponent extends ButtonComponent {

  modalTrigger$ = new Subject<any>();
  dialogMSNew: MatDialogRef<DialogMSUpdateComponent>

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'ml-2'
    this.content = 'Nuovo Maggiore Sconto'
  }

  ngOnInit(): void {
    this.modalTrigger$.subscribe({
      next: () => {
        this.dialogMSNew.close()
      }
    });
  }

  public actionClick(): void {
    this.dialogMSNew = this.dialog.open(DialogMSUpdateComponent,
      {
        data: {
          msData: new MsDiscountModel(),
          modalTrigger: this.modalTrigger$
        }
      },
    );
  }

}
