import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import {AppModule} from '@/app.module';
import {environment} from './environments/environment';
import {DIST_VERSION} from './environments/dist.version';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  environment: environment.env,
  dsn: "https://4b9c9ff64f6ad4c0526707697ef4e74b@o4506723888726016.ingest.sentry.io/4506723889774592",
  beforeSend(event, hint) {
    // Check condition or inspect the event/hint to decide whether to show the feedback dialog
    // Returning null will prevent the event from being sent and the feedback dialog from showing
    return event;
  },
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.BrowserProfilingIntegration()
    //new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: environment.production ? 0.5 : 1.0, // Capture 100% of the transactions, reduce in production!
  profilesSampleRate: environment.production ? 0.5 : 1.0,
  // Session Replay
  //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
  release: 'progetto-crespi@' + DIST_VERSION, // Use your dynamic versioning strategy here
  dist: DIST_VERSION,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
