import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from '@components/button/button.component';
import {Router} from "@angular/router";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {MatDialog} from "@angular/material/dialog";
import {Registry} from "@/registry.service";
import {ToastrService} from "ngx-toastr";
import {SubAgent} from "@/models/sub-agent";
import {
  DialogSubAgentComponent
} from "@pages/tables/subagent-agency/modals/dialog-sub-agent/dialog-sub-agent.component";

@Component({
  selector: 'app-button-exchange-detail',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss'],
})
export class ButtonDetailSubAgentComponent extends ButtonComponent implements OnInit {
  private subAgent: SubAgent;
  private rowData: any;
  faIcon = faPenToSquare;

  constructor(protected router: Router, private dialog: MatDialog, protected toastr: ToastrService) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio Subagente'
    this.smallBtn = true;
  }

  ngOnInit() {
    this.subAgent = <SubAgent>this.rowData;
  }

  /**
   * Open practice detail page
   */
  public actionClick(): void {
    const dialogDetail = this.dialog.open(DialogSubAgentComponent, {
      data: this.subAgent,
    });

    Registry.getInstance().set('DialogSubAgentComponentModify', dialogDetail)
  }
}
