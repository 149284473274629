import {TableColumnsSetting} from "@/interfaces/table-setting";
import {TablePracticeModel} from "@/models/tables/table-practices.model";

export class VwfsDashboardSettingsTable {

  public static getColumnsSetting(): TableColumnsSetting<keyof TablePracticeModel> {
    return [
      {
        name: "id",
        text: "Id",
        filter: {
          type: "input",
        }
      },
      {
        name: "importId",
        text: "Import Id",
        filter: {
          type: "input",
        }
      },
      {
        name: "type",
        text: "Tipo file",
        filter: {
          type: "input",
        }
      },
      {
        name: "practiceNumber",
        text: "Num. pratica",
        filter: {
          type: "input",
        }
      },
      {
        name: "client",
        text: "Cliente",
        filter: {
          type: "input",
        }
      },
      {
        name: "model",
        text: "Codice modello",
        filter: {
          type: "input",
        }
      },
      {
        name: "modelDescription",
        text: "Descrizione modello",
        filter: {
          type: "input",
        }
      },
      {
        name: "createdAt",
        type: "datetime",
        text: "creato il",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "updatedAt",
        type: "datetime",
        text: "modificato il",
        filter: {
          type: 'dateRange'
        }
      }
    ];
  }
}
