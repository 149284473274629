import {PracticeModel} from "@/models/practice.model";

export class PracticeAccessoryModel {
  public id: number;
  public descrizione: string;
  public pratica: PracticeModel;
  public prezzo: number;
  public creator: string;
  public created: Date;
  public modifier: string;
  public modified: Date;
}
