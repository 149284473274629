import {Injectable} from '@angular/core';
import {TableDeliveryCardBoardModel} from "@/models/tables/delivery-cardboard.models";
import {DeliveryCardboardService} from "@services/delivery-cardboard.service";
import {TableDataApiParams} from "@services/practices.service";
import {DataSourceBaseNew} from "@components/new-table/data-source-base-new.dataSource";

@Injectable({
  providedIn: 'root'
})
export class DeliveryCardboardDataSource extends DataSourceBaseNew<TableDeliveryCardBoardModel> {

  constructor(private deliveryCardboardService: DeliveryCardboardService) {
    super();
  }

  loadData(params?: TableDataApiParams): void {
    this._isLoading$.next(true);

    this.deliveryCardboardService.getTableData(params).subscribe(data => {
      this.elements$.next(data.content);
      this._totalElements$.next(data.totalElements);
      this._isLoading$.next(false);
    })
  }
}
