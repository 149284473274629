import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";
import {UploadControl} from "@components/dynamic-form/upload-input/upload-control";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {Subject} from "rxjs";
import {faFile, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'app-upload-input',
  templateUrl: './upload-input.component.html',
  styleUrls: ['./upload-input.component.scss']
})
export class UploadInputComponent extends InputFieldComponent implements OnInit {
  @Input() meta!: UploadControl
  @Input() form!: FormGroup
  @Input() indexRow: number = null
  @Input() keyformArrayName: any
  @Input() triggerMethodSubject!: Subject<any>

  textFile: string = null;
  iconType: IconProp;
  protected readonly faFile = faFile;
  protected readonly faFilePdf = faFilePdf;

  ngOnInit(): void {
    super.ngOnInit()
    this.setupIconUpload();

    this.triggerMethodSubject.subscribe({
      next: (obj) => {
        if (this.meta.key === obj.key) {
          this[obj.fun]()
        }
      }
    });
  }

  protected customActionInput(event, indexRow = null) {
    LoggerService.log('Input Changed!')
    const file = <File>event.target.files[0]
    this.textFile = file.name

    Registry.getInstance().get(this.meta.classToUse)[this.meta.customAction](event, indexRow)
  }

  protected resetFileName(): void {
    this.textFile = null;
  }

  private setupIconUpload() {
    console.log(this.meta.accept)

    switch (this.meta.accept) {
      case 'application/pdf':
        this.iconType = this.faFilePdf;
        break;
      default:
        this.iconType = this.faFile;
        break;
    }

    console.log(this.iconType)

  }
}
