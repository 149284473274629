import {Component, Input} from '@angular/core';
import {ButtonComponent} from '@components/button/button.component';
import {Router} from "@angular/router";
import {faExternalLinkSquare} from "@fortawesome/free-solid-svg-icons";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-button-open-link-menu-bi',
    templateUrl: '../button.component.html',
    styleUrls: ['../button.component.scss'],
})
export class ButtonOpenLinkMenuBiComponent extends ButtonComponent {
    private menuBi;
    @Input() rowData;
    faIcon = faExternalLinkSquare;

    constructor(protected router: Router, protected toastr: ToastrService) {
        super(router, toastr);

        this.showIcon = true
        this.additionalClass = 'py-0.5 px-2.5'
        this.toolTip = 'Apri link'
    }

    ngOnInit() {
        this.menuBi = this.rowData;
        this.rowData = null
    }

    public actionClick(): void {
        window.open(this.menuBi.url, '_blank');
    }
}
