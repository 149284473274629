import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {BrandGoalsModel} from "@/models/brand-goals.model";

@Injectable({
  providedIn: 'root'
})
export class BrandGoalsService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/brandGoals`);
  }

  saveBrandGoal(brandGoalsModel: BrandGoalsModel) {
    return this.http.post(this.baseUrl + `/brandGoals/save`, brandGoalsModel).pipe(map((response: any) => {
      LoggerService.log(response, 'saveBrandGoal')

      return response;
    }));
  }

  getAllBrands(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/brandGoals/brands`);
  }

  getBigBuyers(brandId: number): Observable<any> {
    return this.http.get(this.baseUrl + `/brandGoals/bigBuyers/${brandId}`).pipe(map((response: any) => {
      LoggerService.log(response, 'getBigBuyers')
      return response;
    }));
  }
}
