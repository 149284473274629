<div [formGroup]="form">
  <div *ngIf="indexRow !== null" [formArrayName]="keyformArrayName"
       [ngClass]="is_disabled() ? 'disabled-dropdown' : 'active'">
    <div [formGroupName]="indexRow">
      <mat-label class="label-form">{{meta.label}}:</mat-label>
      <mat-form-field appearance="fill" class="w-full additional-border-input mt-1"
                      [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
        <mat-label>Seleziona un valore</mat-label>
        <mat-select [multiple]="multi"
                    [compareWith]="meta?.compareFn ?? compareFunction"
                    [formControlName]="meta.key"
                    (selectionChange)="customActionInput($event, indexRow)"
                    [value]="meta.default" placeholder="Nessun dato disponibile"
        >
          <mat-option *ngFor="let option of meta.options" [value]="option.key">
            {{option.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="indexRow === null" [ngClass]="is_disabled() ? 'disabled-dropdown' : 'active'">
    <mat-label class="label-form">{{meta.label}}:</mat-label>
    <mat-form-field appearance="fill" class="w-full additional-border-input mt-1"
                    [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
      <mat-label>Seleziona un valore</mat-label>
      <mat-select
        [multiple]="multi"
        [compareWith]="meta?.compareFn ?? compareFunction"
        [formControlName]="meta.key"
        (selectionChange)="customActionInput($event)"
        [(value)]="meta.default" placeholder="Nessun dato disponibile"
      >
        <!-- TODO: refactor, 'key - value' should be inverted, the key should be set as text and the value as value -->
        <mat-option *ngFor="let option of meta.options" [value]="option.key">
          {{option.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>
</div>
