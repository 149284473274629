import {TableColumnsSetting} from "@/interfaces/table-setting";
import {UsedVehiclesTableModel} from "@/models/tables/used-vehicles-table.model";

export class CashMovementsAnalisisSettings {
  public static getColumnsSetting(): TableColumnsSetting<keyof UsedVehiclesTableModel> {
    return [
      {
        name: 'dataMovimento',
        type: "date",
        text: 'data movimento'
      },
      {
        name: "saldoInizialeContanti",
        text: "Saldo iniziale contanti",
        type: "currency"
      },
      {
        name: "saldoInizialeAssegni",
        text: "Saldo iniziale assegni",
        type: "currency"
      },
      {
        name: "sommaEntrataContanti",
        text: "Entrata contanti",
        type: "currency"
      },
      {
        name: "sommaUscitaContanti",
        text: "Uscita contanti",
        type: "currency"
      },
      {
        name: "sommaEntrataAssegni",
        text: "Entrata assegni",
        type: "currency"
      },
      {
        name: "sommaVersamentoContanti",
        text: "Versamento contanti",
        type: "currency"
      },
      {
        name: "sommaVersamentoAssegni",
        text: "Versamento assegni",
        type: "currency"
      },
      {
        name: "saldoFinaleContanti",
        text: "Saldo contanti",
        type: "currency"
      },
      {
        name: "saldoFinaleAssegni",
        text: "Saldo assegni",
        type: "currency"
      },
      {
        name: "saldoTotale",
        text: "Saldo totale",
        type: "currency"
      },
    ];
  }
}
