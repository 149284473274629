import {Component, Input, OnChanges} from '@angular/core';
import {FormArray, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {Tab} from "@/interfaces/tab";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {FormsService} from "@services/forms.service";
import {ToastrService} from "ngx-toastr";
import {UserService} from "@services/admin/user.service";
import moment from "moment";
import {Subject} from "rxjs";

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnChanges {
  @Input() meta: Array<any> = []
  @Input() data: any
  encType: string = 'application/x-www-form-urlencoded'
  form: FormGroup = new FormGroup({})
  tabs: Array<Tab> = []
  faIcon = faUser
  submitButtonVisible: boolean = true
  secondButton: string;
  thirdButton: string;
  primaryButton: string;
  secondaryBottomButton: string;
  submitTopButton: boolean = true;
  submitBottomButton: boolean = true;
  protected triggerMethodSubject = new Subject<any>();

  constructor(protected toastr: ToastrService, protected userService: UserService) {
    Registry.getInstance().set('formService', new FormsService())
    Registry.getInstance().set('saveInProgress', false)

    this.primaryButton = 'Salva'
  }

  public ngOnChanges() {
    this.refreshForm()
  }

  //Function run before submit form to standardize the date fields
  onSubmitForm() {
    if (!this.validateSubmitRequestValidateForm()) {
      return
    }

    for (let iMeta = 0; iMeta < this.meta.length; iMeta++) {
      let meta = this.meta[iMeta]
      for (let iRow = 0; iRow < meta.rows.length; iRow++) {
        let row = meta.rows[iRow]
        let controlKey = row.key
        let control = this.form.get(controlKey)

        if (row.repeaterRows) {
          for (let iRepeater = 0; iRepeater < row.repeaterRows?.length; iRepeater++) {
            let rowRepeater = row.repeaterRows[iRepeater]
            let arrayControl = this.form.get(controlKey) as FormArray
            let indexRow = rowRepeater.index
            let rowKey = rowRepeater.row.key

            control = arrayControl.at(indexRow);

            if (rowRepeater.row.controlType === 'date' && control?.value && control.value[rowKey] && control.value[rowKey] instanceof moment) {
              arrayControl.at(indexRow).value[rowKey].add(1, 'd')
            }
          }
        } else {
          if (row.controlType === 'date' && control.value && control.value instanceof moment) {
            this.form.get(controlKey).value.add(1, 'd')
          }
        }

      }
    }

    this.onSubmit()
  }

  protected setupMeta() {
  }

  protected validateSubmitRequestValidateForm() {
    Registry.getInstance().set('submittedForm', true)
    Registry.getInstance().get('formService').triggerUpdate()

    if (Registry.getInstance().get('saveInProgress')) {
      return false;
    }

    LoggerService.log(this.form.invalid, 'form status')

    if (this.form.invalid) {
      this.printValidationFormErrors()

      LoggerService.error(`Form ${self.constructor.name} not valid`)
      Registry.getInstance().set('saveInProgress', false)
      return false;
    }

    Registry.getInstance().set('saveInProgress', true)

    return true
  }

  protected refreshForm() {
    LoggerService.log('Refresh Form!')

    this.setupMeta()

    if (this.tabs.length !== 0) {
      this.form = this.toFormGroupTabs();
    } else {
      this.form = this.toFormGroup();
    }
  }

  protected generateFormControlByRowData(rowsData, formGroup) {
    rowsData.forEach((rowData) => {
      if (rowData.controlType !== 'label') {
        let formControlOptions = []
        let rowDataKey = rowData.key
        let disabled = false;

        if (rowData.required) {
          formControlOptions.push(Validators.required)
        }

        if (rowData.disabled) {
          disabled = true
        }

        if (rowData.additionalValidators) {
          rowData.additionalValidators.forEach((option) => {
            formControlOptions.push(option)
          })
        }

        if (rowData.controlType === 'repeater') {
          this.addFormArrayControls(rowDataKey, rowData.metaArray[0].rows, formGroup)
        } else {
          // TODO: GLOBAL REFACTOR, using any type is not absolutely recommended since it can cause typing issues and a very bad dev exp.
          const controlValue = this.data[rowDataKey] ?? rowData.default;
          const newControl = new FormControl({
            value: controlValue,
            disabled: disabled,
          }, formControlOptions)

          formGroup.addControl(rowDataKey, newControl);
        }
      }
    })

    return formGroup
  }

  protected addFormArrayControls(keyRow, rowDataRepeater, formGroup) {
    let dataRepeater = this.data[keyRow]

    if (dataRepeater && dataRepeater.length !== 0) {
      let formArray = new FormArray([], [])

      for (let i = 0; i < dataRepeater.length; i++) {
        let formGroupArray = new FormGroup({})

        rowDataRepeater.forEach((rowData) => {
          let formControlOptions = []
          let rowDataKey = rowData.key
          let disabled = false;
          let value = dataRepeater[i][rowDataKey] ?? rowData.default ?? null

          if (rowData.required) {
            formControlOptions.push(Validators.required)
          }

          if (rowData.disabled) {
            disabled = true
          }

          if (rowData.additionalValidators) {
            rowData.additionalValidators.forEach((option) => {
              formControlOptions.push(option)
            })
          }

          formGroupArray.addControl(rowDataKey, new FormControl({
            value: value,
            disabled: disabled,
          }, formControlOptions));
        })

        formArray.push(formGroupArray)
      }

      formGroup.addControl(keyRow, formArray)
    } else {
      formGroup.addControl(keyRow, new FormArray([]));
    }

    return formGroup
  }

  protected printValidationFormErrors() {
    Object.keys(this.form.controls).forEach(key => {
      const formGroup = this.form.get(key)
      const controls = []

      if (formGroup instanceof FormArray) {
        const formGroupArray = formGroup

        for (let controlsArray of formGroupArray.controls) {
          let keysControl = Object.keys(controlsArray.getRawValue())

          for (let keyControl of keysControl) {
            let childControl = controlsArray.get(keyControl)
            controls.push(childControl)
          }
        }
      } else {
        controls.push(this.form.get(key))
      }

      for (let control of controls) {
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            let error = ''
            switch (keyError) {
              case 'required':
                error = `Il campo ${key} è obbligatorio!`
                break;
              case 'minlength':
                error = `Il lunghezza del campo ${key} è errata!`
                break;
              default:
                error = `Il campo ${key} non è valido!`
            }

            this.toastr.error(error)

            LoggerService.error('Key control: ' + key + ', keyError: ' + keyError + ', err value: ')
            LoggerService.error(controlErrors[keyError])
          });
        }
      }
    });
  }

  protected secondButtonAction() {
    return
  }

  protected thirdButtonAction() {
    return
  }

  protected secondaryButtonAction() {
    return
  }

  protected convertDateToString(date) {
    const dateObj = new Date(date)
    const day = String(dateObj.getDate()).padStart(2, '0')
    const month = String(dateObj.getMonth() + 1).padStart(2, '0')
    const year = dateObj.getFullYear()

    return [day, month, year].join('-')
  }

  protected onSubmit() {
  }

  private toFormGroup() {
    const group: any = {}
    const formGroup = new FormGroup(group);

    return this.createFormGroup(this.meta, formGroup);
  }

  private toFormGroupTabs() {
    const group: any = {}
    let formGroup = new FormGroup(group);

    this.tabs.forEach((tab) => {
      formGroup = this.createFormGroup(tab.meta, formGroup)
    });

    return formGroup;
  }

  private createFormGroup(metas: Array<any>, formGroup: FormGroup) {
    for (let meta of metas) {
      formGroup = this.generateFormControlByRowData(meta.rows, formGroup)
    }

    return formGroup
  }
}
