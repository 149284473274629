<div>
  <mat-label class="label-form">{{ meta.label }}:</mat-label>
  <mat-form-field [ngClass]="control.invalid ? 'invalid-field' : ''" appearance="fill"
                  class="w-full additional-border-input text-white mt-1">
    <mat-label>Seleziona un valore</mat-label>
    <mat-select (selectionChange)="onSelectionChange($event)" [formControl]="control">
      <mat-option *ngFor="let option of meta.options" [value]="option.key">
        {{ option.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!--  <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>-->
</div>
