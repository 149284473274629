import {Component, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {OptionSelect} from "@/models/option-select.model";
import {SellersService} from "@services/tables/sellers.service";
import {SellerModel} from "@/models/tables/seller.model";
import {SellerFormSetting} from "@/form-settings/seller-form-setting";

@Component({
  selector: 'app-seller-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class SellerFormComponent extends DynamicFormComponent implements OnInit {

  private users: OptionSelect[];
  private brands: OptionSelect[] = [];
  private agencySubagent: OptionSelect[] = [];
  private sellerTypes: OptionSelect[] = [];

  constructor(protected router: Router, protected userService: UserService, protected sellersService: SellersService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())

    this.sellersService.getAllUsers().subscribe(users => {
      this.users = users.map(user => ({
        key: user,
        value: user.username
      }))
      this.setupMeta()
    })

    this.sellersService.getSubagentAgencies().subscribe({
      next: (agencySubagents) => {
        this.agencySubagent = agencySubagents

        this.setupMeta()
      },
      error: (error) => {
        LoggerService.error('getSubagentAgencies failed!');
        LoggerService.error(error);
      },
      complete: () => {
        LoggerService.log('Request done')
      },
    })

    this.sellersService.getSellerTypes().subscribe({
      next: (sellerTypes) => {
        for (const sellerType of sellerTypes) {
          this.sellerTypes.push({
            key: sellerType,
            value: sellerType
          });
        }

        this.setupMeta()
      },
      error: (error) => {
        LoggerService.error('getSellerTypes failed!');
        LoggerService.error(error);
      },
      complete: () => {
        LoggerService.log('Request done')
      },
    })

    this.sellersService.getAllBrands().subscribe({
      next: (brands) => {
        for (const brand of brands) {
          this.brands.push({
            key: brand,
            value: brand
          });
        }

        this.setupMeta()
      },
      error: (error) => {
        LoggerService.error('getAllSeller failed!');
        LoggerService.error(error);
      },
      complete: () => {
        LoggerService.log('Request done')
      },
    })
  }

  onSubmit() {
    let sellerModel = <SellerModel>this.form.getRawValue()

    this.sellersService.saveSeller(sellerModel).subscribe({
      next: () => {
        this.toastr.success('Venditore salvata correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveSeller failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio del Venditore.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = SellerFormSetting
      .set('brands', this.brands)
      .set('agencySubagent', this.agencySubagent)
      .set('sellerTypes', this.sellerTypes)
      .set('users', this.users)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogSellerComponentModify').close()
    } else {
      Registry.getInstance().get('DialogSellerComponentNew').close()
    }

    Registry.getInstance().get('SellerComponent').loadSellers()
  }

  protected secondaryButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('DialogSellerComponentModify').close()
    } else {
      Registry.getInstance().get('DialogSellerComponentNew').close()
    }
  }

}
