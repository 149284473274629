import {PracticeModel} from "@/models/practice.model";

export class PracticeCommunicationModel {
  public id: number;
  public titolo: string;
  public messaggio: string;
  public pratica: PracticeModel;
  public creator: string;
  public created: Date;
  public modifier: string;
  public modified: Date;
}
