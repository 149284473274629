import {Component, OnInit} from '@angular/core';
import {Tab} from "@/interfaces/tab";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {CashMovementsModel} from "@/models/cash-movements.model";
import {CashMovementsService} from "@services/cash-movements.service";
import {DropdownControl} from "@components/dynamic-form/dropdown/dropdown-control";
import {Subject} from "rxjs";
import {Role} from "@/models/role";
import {getCurrentUserRoles} from "@utils-functions";
import {ControlType} from "@components/dynamic-form/control-type";

@Component({
  selector: 'app-new-cash-movements',
  templateUrl: './new-cash-movements.component.html',
  styleUrls: ['./new-cash-movements.component.scss']
})
export class NewCashMovementsComponent implements OnInit {
  data: any = {}
  tabs: Array<Tab> = []
  faIcon: any
  department: Subject<any> = new Subject<any>()
  metaDepartments: DropdownControl
  isAccountingUser: boolean = false
  isNew: boolean = true

  constructor(private route: ActivatedRoute, protected router: Router, protected cashMovementsService: CashMovementsService,) {
    this.data = {} as CashMovementsModel

    this.isAccountingUser = getCurrentUserRoles().includes(Role.Accounting)

    this.route.params.subscribe((params: Params) => {
      if (params['id']) {
        this.isNew = false;
      }
    })

    this.cashMovementsService.getDepartments().subscribe((departments) => {
      let departmentOptions = []

      for (let department of departments) {
        departmentOptions.push({
          'key': department,
          'value': department
        })
      }

      this.metaDepartments = {
        additionalValidators: null,
        class: "",
        classToUse: "",
        controlType: ControlType.dropdown,
        key: "",
        order: 0,
        value: null,
        default: null,
        label: "Seleziona dipartimento",
        options: departmentOptions
      }

      this.department.next(departmentOptions)
    })
  }

  changeDepartments(event) {
    this.department.next(event)

    return null;
  }

  async ngOnInit() {
  }
}
