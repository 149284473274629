import {Component} from '@angular/core';
import {Subject} from "rxjs";

@Component({
  selector: 'app-bigger-discount',
  templateUrl: './bigger-discount.component.html',
  styleUrls: ['./bigger-discount.component.scss']
})
export class BiggerDiscountComponent {

  datePickerData: object = {
    "classToUse": "",
    "controlType": "date",
    "dateType": "MM/YYYY",
    "placeholder": "MM/YYYY",
    "maxDate": true,
    "label": "Periodo mese consegna",
    "class": "md:w-6/12 lg:w-3/12 w-full",
  };
  triggerDate$ = new Subject<any>();
  reloadTrigger$ = new Subject<any>();
  protected readonly data = {};

  constructor() {

  }
}
