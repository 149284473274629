import {FormSetting} from "@/form-settings/form-setting";

export class BrandFormSetting extends FormSetting {

  protected static classToCall: string

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static getMeta(): Array<any> {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "tipoVeicolo",
            "controlType": "dropdown",
            "label": "Tipo Veicolo",
            "order": 1,
            "class": "lg:w-5/12 w-full",
            "required": true,
            "options": [
              {'key': 'NUOVO', 'value': 'NUOVO'},
              {'key': 'USATO', 'value': 'USATO'}
            ]
          },
          {
            "classToUse": this.classToCall,
            "key": "codiceMarca",
            "label": "Codice Marca",
            "controlType": "textinput",
            "class": "lg:w-5/12 w-full",
            "order": 1,
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "position",
            "controlType": "number",
            "label": "Posizione",
            "order": 1,
            "class": "lg:w-2/12 w-full",
            "required": true,
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "descrizioneMarca",
            "label": "Descrizione Marca",
            "controlType": "textinput",
            "class": "lg:w-12/12 w-full",
            "order": 1,
            "required": true,
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "id",
            "controlType": "hidden",
            "label": "",
            "order": 1,
          },
          {
            "classToUse": this.classToCall,
            "key": "creator",
            "controlType": "hidden",
            "label": "",
            "order": 1,
          },
        ],
        "order": 1
      },
    ]
  }
}

