import {Component, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-pop-up',
  templateUrl: './dialog-pop-up.component.html',
  styleUrls: ['./dialog-pop-up.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogPopUpComponent {
  title: string;
  content: string;
  firstCTA: string = 'Si';
  secondCTA: string = 'No';
  closeResultFirst: any;
  closeResultSecond: any;
  classTitle: string;
  classContent: string;

  constructor(public dialogRef: MatDialogRef<DialogPopUpComponent>) {
  }


}
