<div [formGroup]="form">
  <div *ngIf="indexRow !== null" [formArrayName]="keyformArrayName">
    <div [formGroupName]="indexRow">
      <mat-label class="label-form">{{ meta.label }}:</mat-label>
      <mat-form-field appearance="outline" class="w-full"
                      [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
        <mat-label>{{ meta.label }}</mat-label>
        <input matInput placeholder="Inserisci {{meta.label}}"
               [formControlName]="meta.key"
               (change)="customActionInput($event)"
        >
      </mat-form-field>
      <span *ngIf="meta.additionalMessage"
            class="italic text-orange-200 tracking-wider">⚠ {{ meta.additionalMessage }}</span>
    </div>
  </div>
  <div *ngIf="indexRow === null">
    <mat-label class="label-form">{{ meta.label }}:</mat-label>
    <mat-form-field appearance="outline" class="w-full" [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
      <mat-label>{{ meta.label }}</mat-label>
      <input matInput placeholder="Inserisci {{meta.label}}"
             [formControlName]="meta.key"
             (change)="customActionInput($event)"
      >
    </mat-form-field>
    <span *ngIf="meta.additionalMessage"
          class="italic text-orange-200 tracking-wider">⚠ {{ meta.additionalMessage }}</span>
  </div>
  <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>
</div>
