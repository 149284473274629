import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Subject} from "rxjs";

@Component({
  selector: 'app-dialog-upload-multiple-document',
  templateUrl: './dialog-upload-multiple-document.component.html',
})
export class DialogUploadMultipleDocumentComponent {
  showProgressBar: boolean = false;
  protected progress: number = 0;
  protected triggerProgressBar = new Subject<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.triggerProgressBar.subscribe({
      next: (obj) => {
        this.showProgressBar = obj.showBar;
        this.progress = obj.progress;
      }
    });
  }


}
