<div [formGroup]="form" [class]="class">
  <mat-label class="label-form">{{meta.label}}:</mat-label>
  <mat-form-field appearance="fill" class="w-full additional-border-input">
    <mat-label>{{meta.label}}</mat-label>
    <input matInput
           [formControlName]="meta.key"
           [value]="meta.value"
           (change)="customActionInput($event)"
           type="number"
           placeholder="0001112222">
  </mat-form-field>
  <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>
</div>
