import { Injectable } from '@angular/core';
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataSourceBaseNew<T> extends DataSource<T> {

  protected _isLoading$ = new BehaviorSubject<boolean>(false);
  protected _totalElements$ = new BehaviorSubject<number>(0);
  protected elements$ = new BehaviorSubject<T[]>([])
  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.elements$.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.elements$.complete();
    this._isLoading$.complete();
  }

  loadData(..._): void {
    throw Error('method not implemented')
  }

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  get totalElements$() {
    return this._totalElements$.asObservable();
  }

}
