import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {User} from "@/models/user.model";

@Component({
  selector: 'app-slide-toggle-user',
  templateUrl: './slide-toggle-user.component.html',
  styleUrls: ['./slide-toggle-user.component.scss']
})
export class SlideToggleUserComponent implements OnInit {
  protected user: User;

  private rowData: any;

  constructor(protected router: Router, public dialog: MatDialog, private userService: UserService, protected toastr: ToastrService) {
  }

  ngOnInit() {
    this.user = this.rowData;
  }

  actionSlideToggle() {

  }
}
