import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-button-new-user',
    templateUrl: '../button.component.html',
    styleUrls: ['../button.component.scss'],
})
export class ButtonNewUserComponent extends ButtonComponent {
    constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
        super(router, toastr);

        this.showIcon = false
        this.additionalClass = 'ml-2'
        this.content = 'Nuovo utente'
    }

    public actionClick(): void {
        this.router.navigate(['admin/user/new']);
    }

}
