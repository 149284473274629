import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {ContactSourceSettings} from "@/table-settings/tables/contact-source-settings";
import {ContactSourceService} from "@services/tables/contact-source.service";

@Component({
  selector: 'app-contact-source',
  templateUrl: './contact-source.component.html',
  styleUrls: ['./contact-source.component.scss']
})
export class ContactSourceComponent {

  source: LocalDataSource;
  settings = ContactSourceSettings.gridSettings();

  constructor(private contactSourceService: ContactSourceService) {
    this.source = new LocalDataSource([]);
    const registry = Registry.getInstance()
    registry.get('userService').onUpdate().subscribe(() => {
      this.loadContactSource();
    });

    Registry.getInstance().set('ContactSourceComponent', this)

    this.loadContactSource();
  }

  loadContactSource() {
    this.contactSourceService.getAll().subscribe((contactSource) => {
      this.source.load(contactSource);
    });
  }
}
