import {PracticeModel} from "@/models/practice.model";
import {BrandModel} from "@/models/tables/brand.model";

export class UsedVehicleModel {
  public id: number;
  public pratica: PracticeModel;
  public marca: BrandModel;
  public modello: string;
  public targa: string;
  public importo: number;
  public autorizzato: string;
  public speseUsato: boolean;
  public dataPresuntoRitiro: moment.Moment;
  public dataRitiro: moment.Moment;
  public note: string;
  public chilometriPercorsi: number;
  public colore: string;
  public tipologiaFatturazione: string;
  public intestato: string;
  public destinazioneUsato: string;
  public scadenzaBollo: moment.Moment;
  public scadenzaRevisione: moment.Moment;
  public presenzaGravamiContratto: boolean;
  public presenzaGravamiRitiro: boolean;
  public valoreContabileUsato: number;
  public estinzioneFinanziamento: number;
  public codiceFornitore: string;
  public creator: string;
  public created: Date;
  public modifier: string;
  public modified: Date;

}
