import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {MsDiscountModel} from "@/models/tables/ms-discount-model";

@Component({
  selector: 'app-dialog-msupdate',
  templateUrl: './dialog-msupdate.component.html',
  styleUrls: ['./dialog-msupdate.component.scss']
})
export class DialogMSUpdateComponent {

  msData: MsDiscountModel
  modalTrigger: Subject<any>

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.msData = data.msData
    this.modalTrigger = data.modalTrigger
  }
}
