import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import * as Sentry from "@sentry/angular-ivy";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@/modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';
import {ButtonComponent} from '@components/button/button.component';
import {ButtonDetailsComponent} from "@components/button/button-details/button-details.component";

import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {MenuItemComponent} from '@components/menu-item/menu-item.component';
import {DropdownComponent} from '@components/dropdown/dropdown.component';
import {DropdownMenuComponent} from '@components/dropdown/dropdown-menu/dropdown-menu.component';
import {ControlSidebarComponent} from '@modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {SelectComponent} from '@components/select/select.component';
import {CheckboxComponent} from '@components/checkbox/checkbox.component';
import {AgGridModule} from 'ag-grid-angular';
import {UsersComponent} from '@pages/admin/users/users.component';
import {UserService} from "@services/admin/user.service";
import {LoggerService} from '@services/logger.service';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {ButtonDetailUserComponent} from '@components/button/users/button-detail-user.component';
import {ButtonDeleteUserComponent} from "@components/button/users/button-delete-user.component";
import {ButtonEnabledUserComponent} from "@components/button/users/button-enabled-user.component";
import {UserDetailsComponent} from '@pages/admin/users/details/user-details.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatIconModule} from "@angular/material/icon";
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DialogPopUpComponent} from '@components/dialog-pop-up/dialog-pop-up.component';
import {MatDialogModule} from "@angular/material/dialog";
import {DatePickerComponent} from '@components/date-picker/date-picker.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatNativeDateModule, MatOptionModule} from "@angular/material/core";
import {DynamicFormComponent} from '@components/dynamic-form/dynamic-form.component';
import {TextInputComponent} from '@components/dynamic-form/text-input/text-input.component';
import {DropdownComponent as DropdownComponentForm} from './components/dynamic-form/dropdown/dropdown.component';
import {LabelComponent} from '@components/dynamic-form/label/label.component';
import {ControlComponent} from '@components/control/control.component';
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {PasswordComponent} from '@components/dynamic-form/password/password.component';
import {TelephoneComponent} from '@components/dynamic-form/telephone/telephone.component';
import {EmailComponent} from '@components/dynamic-form/email/email.component';
import {UserFormDetailComponent} from '@pages/admin/users/details/user-form/user-form-detail.component';
import {AngularDualListBoxModule} from "angular-dual-listbox";
import {DualListComponent} from '@components/dynamic-form/dual-list/dual-list.component';
import {NewUserComponent} from '@pages/admin/users/new-user/new-user.component';
import {AuthInterceptor} from "@services/auth-interceptor.service";
import {UserFormNewComponent} from '@pages/admin/users/new-user/user-form-new/user-form-new.component';
import {Registry} from "@/registry.service";
import {BoxErrorsComponent} from "@components/dynamic-form/box-errors/box-errors.component";
import {CompanyComponent} from '@pages/admin/company/company.component';
import {AlertComponent} from '@components/alert/alert.component';
import {ButtonLogoutComponent} from "@components/button/auth/button-logout.component";
import {PracticesComponent} from '@pages/practices-management/practices/practices.component';
import {
  NewPracticeFormComponent
} from '@pages/practices-management/practices/new-practice/new-practice-form/new-practice-form.component';
import {NewPracticeComponent} from "@pages/practices-management/practices/new-practice/new-practice.component";
import {SlideToggleComponent} from '@components/dynamic-form/slide-toggle/slide-toggle.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {DateInputComponent} from '@components/dynamic-form/date-input/date-input.component';
import {RepeaterInputsComponent} from '@components/dynamic-form/repeater-inputs/repeater-inputs.component';
import {NumberInputComponent} from '@components/dynamic-form/number-input/number-input.component';
import {TextAreaComponent} from '@components/dynamic-form/text-area/text-area.component';
import {AutoCompleteComponent} from '@components/dynamic-form/auto-complete/auto-complete.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {StockComponent} from "@pages/stock-management/stock.component";
import {UsedVehicleComponent} from "@pages/used-vehicle-management/used-vehicle.component";
import {SiNoRendererComponent} from "@/components/table-column-renderer/sino-renderer.component";
import {BrandRendererComponent} from "@components/table-column-renderer/brand-renderer.component";
import {
  StockAutorizzatoRendererComponent
} from "@/components/table-column-renderer/stockautorizzato-renderer.component";
import {StatoUsatoRendererComponent} from "@/components/table-column-renderer/statousato-renderer.component";
import {HiddenInputComponent} from '@components/dynamic-form/hidden-input/hidden-input.component';
import {
  DetailPracticeFormComponent
} from '@pages/practices-management/practices/practice-details/detail-practice-form/detail-practice-form.component';
import {
  PracticeDetailsComponent
} from "@pages/practices-management/practices/practice-details/practice-details.component";
import {BrandComponent} from "@pages/tables/brand/brand.component";
import {
  InsuranceServiceTypeRendererComponent
} from "@components/table-column-renderer/insurance-service-type-renderer.component";
import {ActionPracticesComponent} from "@pages/tables/action-practices/action-practices.component";
import {BigBuyersComponent} from "@pages/tables/big-buyers/big-buyers.component";
import {InsuranceServiceTypeComponent} from "@pages/tables/insurance-service-type/insurance-service-type.component";
import {RentalPracticesComponent} from "@pages/tables/rental-practices/rental-practices.component";
import {SellerComponent} from "@pages/tables/seller/seller.component";
import {ServicePracticesComponent} from "@pages/tables/service-practices/service-practices.component";
import {RentalPracticesRendererComponent} from "@components/table-column-renderer/rental-practices-renderer.component";
import {RentalAgencyComponent} from "@pages/tables/rental-agency/rental-agency.component";
import {CustomerTypeRendererComponent} from "@components/table-column-renderer/customer-type-renderer.component";
import {BrandNewRendererComponent} from "@components/table-column-renderer/brand-new-renderer.component";
import {
  AutoCompleteStringComponent
} from '@components/dynamic-form/auto-complete/auto-complete-string/auto-complete-string.component';
import {
  AutoCompleteActionCodesComponent
} from '@components/dynamic-form/auto-complete/concrete/auto-complete-action-codes/auto-complete-action-codes.component';
import {
  AutoCompleteLoyaltyServicesComponent
} from '@components/dynamic-form/auto-complete/concrete/auto-complete-loyalty-services/auto-complete-loyalty-services.component';
import {
  AutoCompleteFinancialServicesComponent
} from '@components/dynamic-form/auto-complete/concrete/auto-complete-financial-services/auto-complete-financial-services.component';
import {
  DialogNewExchangeComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-new-exchange/dialog-new-exchange.component";
import {
  DialogDetailExchangeComponent
} from '@pages/practices-management/practices/practice-details/dialogs/dialog-detail-exchange/dialog-detail-exchange.component';
import {ButtonDetailExchangeComponent} from "@components/button/practices/button-detail-exchange.component";
import {ButtonDeleteExchangeComponent} from "@components/button/practices/button-delete-exchange.component";
import {
  DialogUploadDocumentComponent
} from '@pages/practices-management/practices/practice-details/dialogs/dialog-upload-document/dialog-upload-document.component';
import {
  DocumentFormComponent
} from '@pages/practices-management/practices/practice-details/dialogs/dialog-upload-document/document-form.component';
import {UploadInputComponent} from '@components/dynamic-form/upload-input/upload-input.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {SubagentAgencyComponent} from "@pages/tables/subagent-agency/subagent-agency.component";
import {ButtonDeleteDocumentComponent} from "@components/button/practices/button-delete-document.component";
import {ButtonDownloadDocumentComponent} from "@components/button/practices/button-download-document.component";
import {
  ButtonCreateNewSubAgentComponent
} from '@components/button/tables/subagent-agency/button-create-new-sub-agent.component';
import {
  DialogSubAgentComponent
} from '@pages/tables/subagent-agency/modals/dialog-sub-agent/dialog-sub-agent.component';
import {SubAgentFormComponent} from '@pages/tables/subagent-agency/modals/sub-agent-form.component';
import {
  ButtonModifySubAgentComponent
} from '@components/button/tables/subagent-agency/button-modify-sub-agent.component';
import {ButtonDetailSubAgentComponent} from "@components/button/practices/button-detail-subagent.component";
import {
  ButtonDeleteSubAgentComponent
} from "@components/button/tables/subagent-agency/button-delete-subagent.component";
import {VehicleAccessoryComponent} from "@pages/tables/vehicle-accessory/vehicle-accessory.component";
import {
  ButtonDeleteActionPracticeComponent
} from "@components/button/tables/action-practices/button-delete-action-practice.component";
import {
  ButtonDetailActionPracticeComponent
} from "@components/button/tables/action-practices/button-modify-action-practice.component";
import {
  DialogActionPracticeComponent
} from '@pages/tables/action-practices/dialog-action-practice/dialog-action-practice.component';
import {
  ActionPracticeFormComponent
} from '@pages/tables/action-practices/dialog-action-practice/form/action-practice-form.component';
import {
  ButtonNewActionPracticeComponent
} from "@components/button/tables/action-practices/button-new-action-practice.component";
import {
  DialogServicePracticeComponent
} from '@pages/tables/service-practices/dialog-service-practice/dialog-service-practice.component';
import {
  ServicePracticeFormComponent
} from "@pages/tables/service-practices/dialog-service-practice/form/service-practice-form.component";
import {
  ButtonDeleteServicePracticeComponent
} from "@components/button/tables/service-practices/button-delete-service-practice.component";
import {
  ButtonDetailServicePracticeComponent
} from "@components/button/tables/service-practices/button-modify-service-practice.component";
import {
  ButtonNewServicePracticeComponent
} from "@components/button/tables/service-practices/button-new-service-practice.component";
import {
  AutoCompleteTypeInsuranceServiceComponent
} from '@components/dynamic-form/auto-complete/concrete/auto-complete-type-insurance-service/auto-complete-type-insurance-service.component';
import {DialogBrandComponent} from '@pages/tables/brand/dialog-brand/dialog-brand.component';
import {BrandFormComponent} from "@pages/tables/brand/dialog-brand/form/brand-form.component";
import {ButtonDeleteBrandComponent} from "@components/button/tables/brands/button-delete-brand.component";
import {ButtonDetailBrandComponent} from "@components/button/tables/brands/button-modify-brand.component";
import {ButtonNewBrandComponent} from "@components/button/tables/brands/button-new-brand.component";
import {DialogSellerComponent} from '@pages/tables/seller/dialog-seller/dialog-seller.component';
import {SellerFormComponent} from "@pages/tables/seller/dialog-seller/form/seller-form.component";
import {ButtonDeleteSellerComponent} from "@components/button/tables/seller/button-delete-seller.component";
import {ButtonNewSellerComponent} from "@components/button/tables/seller/button-new-seller.component";
import {ButtonDetailSellerComponent} from "@components/button/tables/seller/button-modify-seller.component";
import {SellerRendererComponent} from "@components/table-column-renderer/seller-renderer.component";
import {DialogBigBuyersComponent} from '@pages/tables/big-buyers/dialog-big-buyers/dialog-big-buyers.component';
import {BigBuyersFormComponent} from "@pages/tables/big-buyers/dialog-big-buyers/form/big-buyers-form.component";
import {ButtonDeleteBigBuyersComponent} from "@components/button/tables/big-buyers/button-delete-big-buyer.component";
import {ButtonNewBigBuyersComponent} from "@components/button/tables/big-buyers/button-new-big-buyer.component";
import {ButtonDetailBigBuyersComponent} from "@components/button/tables/big-buyers/button-modify-big-buyer.component";
import {
  DialogRentalAgencyComponent
} from '@pages/tables/rental-agency/dialog-rental-agency/dialog-rental-agency.component';
import {
  ButtonDeleteRentalAgencyComponent
} from "@components/button/tables/rental-agency/button-delete-rental-agency.component";
import {
  ButtonNewRentalAgencyComponent
} from "@components/button/tables/rental-agency/button-new-rental-agency.component";
import {
  ButtonDetailRentalAgencyComponent
} from "@components/button/tables/rental-agency/button-modify-rental-agency.component";
import {
  RentalAgencyFormComponent
} from "@pages/tables/rental-agency/dialog-rental-agency/form/rental-agency-form.component";
import {
  DialogVehicleAccessoryComponent
} from '@pages/tables/vehicle-accessory/dialog-vehicle-accessory/dialog-vehicle-accessory.component';
import {
  VehicleAccessoryFormComponent
} from "@pages/tables/vehicle-accessory/dialog-vehicle-accessory/form/vehicle-accessory-form.component";
import {
  ButtonDeleteVehicleAccessoryComponent
} from "@components/button/tables/vehicle-accessory/button-delete-vehicle-accessory.component";
import {
  ButtonNewVehicleAccessoryComponent
} from "@components/button/tables/vehicle-accessory/button-new-vehicle-accessory.component";
import {
  ButtonDetailVehicleAccessoryComponent
} from "@components/button/tables/vehicle-accessory/button-modify-vehicle-accessory.component";
import {PercentageInputComponent} from '@components/dynamic-form/percentage-input/percentage-input.component';
import {
  DialogInsuranceServiceTypeComponent
} from '@pages/tables/insurance-service-type/dialog-insurance-service-type/dialog-insurance-service-type.component';
import {
  InsuranceServiceTypeFormComponent
} from "@pages/tables/insurance-service-type/dialog-insurance-service-type/form/insurance-service-type-form.component";
import {
  ButtonDeleteInsuranceServiceTypeComponent
} from "@components/button/tables/insurance-service-type/button-delete-insurance-service-type.component";
import {
  ButtonDetailInsuranceServiceTypeComponent
} from "@components/button/tables/insurance-service-type/button-modify-insurance-service-type.component";
import {
  ButtonNewInsuranceServiceTypeComponent
} from "@components/button/tables/insurance-service-type/button-new-insurance-service-type.component";
import {
  DialogRentalPracticesComponent
} from '@pages/tables/rental-practices/dialog-rental-practices/dialog-rental-practices.component';
import {
  RentalPracticesFormComponent
} from "@pages/tables/rental-practices/dialog-rental-practices/form/rental-practices-form.component";
import {StatusBarComponent} from '@components/status-bar/status-bar.component';
import {
  ButtonNewRentalPracticeComponent
} from "@components/button/tables/rental-practices/button-new-rental-practice.component";
import {
  ButtonDetailRentalPracticeComponent
} from "@components/button/tables/rental-practices/button-modify-rental-practice.component";
import {
  ButtonDeleteRentalPracticeComponent
} from "@components/button/tables/rental-practices/button-delete-rental-practice.component";
import {ModelBrandRendererComponent} from "@components/table-column-renderer/model-brand-renderer.component";
import {
  AutoCompleteModelComponent
} from '@components/dynamic-form/auto-complete/concrete/auto-complete-model/auto-complete-model.component';
import {ModelsComponent} from '@pages/tables/models/models.component';
import {DialogModelsComponent} from '@pages/tables/models/dialog-models/dialog-models.component';
import {ModelsFormComponent} from "@pages/tables/models/dialog-models/form/models-form.component";
import {ButtonNewModelsComponent} from "@components/button/tables/models/button-new-models.component";
import {ButtonDeleteModelsComponent} from "@components/button/tables/models/button-delete-models.component";
import {ButtonDetailModelsComponent} from "@components/button/tables/models/button-modify-models.component";
import {
  AutoCompleteBrandComponent
} from '@components/dynamic-form/auto-complete/concrete/auto-complete-brand/auto-complete-brand.component';
import {
  DialogNewAccessoryPracticeComponent
} from '@pages/practices-management/practices/practice-details/dialogs/dialog-new-accessory/dialog-new-accessory-practice.component';
import {
  AccessoryPracticeFormComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-new-accessory/form/accessory-practice-form.component";
import {
  ButtonDeletePracticeAccessoryComponent
} from "@components/button/practices/button-delete-practice-accessory.component";
import {
  ButtonDetailPracticeAccessoryComponent
} from "@components/button/practices/button-detail-practice-accessory.component";
import {TableHeaderComponent} from '@components/table-header/table-header.component';
import {
  DialogDuplicatePracticeComponent
} from '@pages/practices-management/practices/practice-details/dialogs/dialog-duplicate-practice/dialog-duplicate-practice.component';
import {
  DuplicatePracticeFormComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-duplicate-practice/form/duplicate-practice-form.component";
import {
  ExchangeFormComponent
} from "@pages/practices-management/practices/practice-details/dialogs/exchange-form.component";
import {CurrencyInputDirective} from "@/directives/currency-input.directive";
import {SubagentAgencyRendererComponent} from "@components/table-column-renderer/subagent-agency-renderer.component";
import {
  AutoCompleteSubagentAgencyComponent
} from "@components/dynamic-form/auto-complete/concrete/auto-complete-subagent-agency/auto-complete-subagent-agency.component";
import {NumberInputPipe} from './pipes/number-input.pipe';
import {CashMovementsComponent} from "@pages/cash-management/cash-movements/cash-movements.component";
import {MenuBiConfigComponent} from "@pages/tables/menu-bi-config/menu-bi-config.component";
import {MenuBiComponent} from "@pages/menu-bi/menu-bi.component";
import {ButtonOpenLinkMenuBiComponent} from "@components/button/menu-bi/button-open-link-menu-bi.component";
import {
  SubagentAgencyUsersRendererComponent
} from "@components/table-column-renderer/subagent-agency-users-renderer.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {DialogLoadingComponent} from "@components/dialog-loading/dialog-loading.component";
import {ButtonNewPracticeComponent} from '@components/button/practices/button-new-practice.component';
import {ButtonNewUserComponent} from "@components/button/users/button-new-user.component";
import {NewTableComponent} from '@components/new-table/new-table.component';
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {DecimalIntPipe} from './pipes/decimal-int.pipe';
import {ButtonDeleteComponent} from '@components/button/button-delete/button-delete.component';
import {ColumnsNamePipe} from './pipes/columns-name.pipe';
import {ButtonNewMenuBiComponent} from "@components/button/tables/menu-bi-config/button-new-menu-bi.component";
import {
  DialogMenuBiConfigComponent
} from '@pages/tables/menu-bi-config/dialog-menu-biconfig/dialog-menu-bi-config.component';
import {
  MenuBiConfigFormComponent
} from '@pages/tables/menu-bi-config/dialog-menu-biconfig/form/menu-bi-config-form.component';
import {ButtonDeleteMenuBIComponent} from "@components/button/tables/menu-bi-config/button-delete-menu-bi.component";
import {ButtonDetailMenuBIComponent} from "@components/button/tables/menu-bi-config/button-modify-menu-bi.component";
import {AreaBiRendererComponent} from "@components/table-column-renderer/area-bi-renderer.component";
import {NewDropdownComponent} from '@components/new-dropdown/new-dropdown.component';
import {ControlValueAccessorDirective} from './directives/control-value-accessor.directive';
import {
  DialogNewCommunicationPracticeComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-new-communication/dialog-new-communication-practice.component";
import {
  ComunicationPracticeFormComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-new-communication/form/comunication-practice-form.component";
import {
  ButtonDeletePracticeCommunicationComponent
} from "@components/button/practices/button-delete-practice-communication.component";
import {
  ButtonDetailPracticeCommunicationComponent
} from "@components/button/practices/button-detail-practice-communication.component";
import {
  DialogDetailCommunicationPracticeComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-detail-communication/dialog-detail-communication-practice.component";
import {
  DialogDetailAccessoryPracticeComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-detail-accessory/dialog-detail-accessory-practice.component";
import {ButtonNewCashMovementsComponent} from "@components/button/cash-movements/button-new-cash-movements.component";
import {
  DeliveryCardboardManagementComponent
} from '@pages/practices-management/delivery-cardboard-management/delivery-cardboard-management.component';
import {
  DialogDeliveryCardboardComponent
} from '@pages/practices-management/delivery-cardboard-management/dialog-delivery-cardboard/dialog-delivery-cardboard.component';
import {
  DeliveryCardboardFormComponent
} from "@pages/practices-management/delivery-cardboard-management/dialog-delivery-cardboard/form/delivery-cardboard-form.component";
import {
  NewCashMovementsComponent
} from '@pages/cash-management/cash-movements/new-cash-movements/new-cash-movements.component';
import {
  NewCashMovementsFormComponent
} from "@pages/cash-management/cash-movements/new-cash-movements/form/new-cash-movements-form.component";
import {InlineControlComponent} from "@components/inline-control/inline-control.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ParseCheckboxPipe} from './parse-checkbox.pipe';
import {
  AutoCompletePaymentTypeComponent
} from "@components/dynamic-form/auto-complete/concrete/auto-complete-payment-type/auto-complete-payment-type.component";
import {
  AutoCompleteContactSourceComponent
} from "@components/dynamic-form/auto-complete/concrete/auto-complete-contact-source/auto-complete-contact-source.component";
import {
  AutoCompleteWarrantyComponent
} from "@components/dynamic-form/auto-complete/concrete/auto-complete-warranty/auto-complete-warranty.component";
import {WarrantyFormComponent} from "@pages/tables/warranty/dialog-warranty/form/warranty-form.component";
import {DialogWarrantyComponent} from "@pages/tables/warranty/dialog-warranty/dialog-warranty.component";
import {WarrantyComponent} from "@pages/tables/warranty/warranty.component";
import {
  PaymentTypeFormComponent
} from "@pages/tables/payment-type/dialog-payment-type/form/payment-type-form.component";
import {DialogPaymentTypeComponent} from "@pages/tables/payment-type/dialog-payment-type/dialog-payment-type.component";
import {PaymentTypeComponent} from "@pages/tables/payment-type/payment-type.component";
import {
  ContactSourceFormComponent
} from "@pages/tables/contact-source/dialog-contact-source/form/contact-source-form.component";
import {
  DialogContactSourceComponent
} from "@pages/tables/contact-source/dialog-contact-source/dialog-contact-source.component";
import {ContactSourceComponent} from "@pages/tables/contact-source/contact-source.component";
import {
  ButtonNewContactSourceComponent
} from "@components/button/tables/contact-source/button-new-contact-source.component";
import {
  ButtonDeleteContactSourceComponent
} from "@components/button/tables/contact-source/button-delete-contact-source.component";
import {
  ButtonDetailContactSourceComponent
} from "@components/button/tables/contact-source/button-modify-contact-source.component";
import {ButtonNewWarrantyComponent} from "@components/button/tables/warranty/button-new-warranty.component";
import {ButtonDeleteWarrantyComponent} from "@components/button/tables/warranty/button-delete-warranty.component";
import {ButtonDetailWarrantyComponent} from "@components/button/tables/warranty/button-modify-warranty.component";
import {ButtonNewPaymentTypeComponent} from "@components/button/tables/payment-type/button-new-payment-type.component";
import {
  ButtonDetailPaymentTypeComponent
} from "@components/button/tables/payment-type/button-modify-payment-type.component";
import {
  ButtonDeletePaymentTypeComponent
} from "@components/button/tables/payment-type/button-delete-payment-type.component";
import {BrandGoalsComponent} from "@pages/practices-management/brand-goals/brand-goals.component";
import {
  DialogBrandGoalsComponent
} from "@pages/practices-management/brand-goals/dialog-brand-goals/dialog-brand-goals.component";
import {
  BrandGoalsFormComponent
} from "@pages/practices-management/brand-goals/dialog-brand-goals/form/brand-goals-form.component";
import {ButtonDetailBrandGoalsComponent} from "@components/button/brand-goals/button-modify-brand-goals.component";
import {ButtonDownloadExchangeComponent} from "@components/button/practices/button-download-exchange.component";
import {
  TableDeliveryCartonComponent
} from '@pages/practices-management/delivery-cardboard-management/table-delivery-carton/table-delivery-carton.component';
import {Router} from "@angular/router";
import {UsedVehicleDetailComponent} from '@pages/used-vehicle-management/details/used-vehicle-detail.component';
import {
  UsedVehicleDetailFormComponent
} from "@pages/used-vehicle-management/details/form/used-vehicle-detail-form.component";
import {BusinessMachinesComponent} from "@pages/practices-management/business-machines/business-machines.component";
import {SellersUsersRendererComponent} from "@components/table-column-renderer/sellers-users-renderer.component";
import {
  BigbuyerBrandGoalsRendererComponent
} from "@components/table-column-renderer/bigbuyer-brand-goals-renderer.component";
import {
  DetailPracticeVehicleFormComponent
} from "@pages/practices-management/practices/practice-details/detail-practice-vehicle-form/detail-practice-vehicle-form.component";
import {CurrencyInputComponent} from '@components/dynamic-form/currency-input/currency-input.component';
import {CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule} from "ng2-currency-mask";
import {
  DataVehicleNotMatchedFormComponent
} from "@pages/practices-management/practices/practice-details/data-vehicle-not-matched-form/data-vehicle-not-matched-form.component";
import {BackButtonComponent} from '@components/button/back-button/back-button.component';
import {SlideToggleUserComponent} from "@components/slide-toggle-user/slide-toggle-user.component";
import {
  ButtonDeleteBankAccountComponent
} from "@components/button/tables/bank-account/button-delete-bank-account.component";
import {
  ButtonDetailBankAccountComponent
} from "@components/button/tables/bank-account/button-modify-bank-account.component";
import {ButtonNewBankAccountComponent} from "@components/button/tables/bank-account/button-new-bank-account.component";
import {BankAccountComponent} from "@pages/tables/bank-account/bank-account.component";
import {
  BankAccountFormComponent
} from "@pages/tables/bank-account/dialog-bank-account/form/bank-account-form.component";
import {DialogBankAccountComponent} from "@pages/tables/bank-account/dialog-bank-account/dialog-bank-account.component";
import {
  ButtonRecalculateProgressionMonthComponent
} from '@components/button/practices/button-recalculate-progression-month.component';
import {
  ButtonExportExcelDeliveryCardBoardComponent
} from '@components/button/practices/button-export-excel-delivery-card-board.component';
import {
  AutoCompleteBankPaymentComponent
} from './components/dynamic-form/auto-complete/concrete/auto-complete-bank-payment/auto-complete-bank-payment.component';
import {BiggerDiscountComponent} from './pages/bigger-discount/bigger-discount.component';
import {FormUploadCircularComponent} from './pages/bigger-discount/form-upload-circular/form-upload-circular.component';
import {
  TableBiggerDiscountComponent
} from './pages/bigger-discount/table-bigger-discount/table-bigger-discount.component';
import {ImportStatusTableComponent} from '@pages/imports/import-status-table/import-status-table.component';
import {CrossF7TableComponent} from '@pages/imports/cross-f7-table/cross-f7-table.component';
import {CrossF8TableComponent} from '@pages/imports/cross-f8-table/cross-f8-table.component';
import {VwfsDashboardTableComponent} from "@pages/imports/vwfs-dashboard-table/vwfs-dashboard-table.component";
import {VwfsCommissionTableComponent} from "@pages/imports/vwfs-commission-table/vwfs-commission-table.component";
import {
  AccountingTabComponentComponent
} from "@pages/practices-management/practices/practice-details/accounting-tab-component/accounting-tab-component.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {DynamicTableComponent} from "@components/dynamic-table-component/dynamic-table.component";
import {
  AccountingPracticeFormComponent
} from "@pages/practices-management/practices/practice-details/accounting-tab-component/accounting-forms/accounting-practice-form.component";
import {LoyaltyTableComponent} from "@pages/imports/loyalty-table/loyalty-table.component";
import {VwbankCommissionTableComponent} from "@pages/imports/vwbank-commission-table/vwbank-commission-table.component";
import {TableMSComponent} from "@pages/bigger-discount/table-ms/table-ms.component";
import {DialogMSUpdateComponent} from "@pages/bigger-discount/table-ms/dialog-msupdate/dialog-msupdate.component";
import {MSUpdatesFormComponent} from "@pages/bigger-discount/table-ms/dialog-msupdate/form/ms-updates-form.component";
import {
  YearDatePickerInputComponent
} from "@components/dynamic-form/year-date-picker-input/year-date-picker-input.component";
import {
  MonthDatePickerInputComponent
} from "@components/dynamic-form/month-date-picker-input/month-date-picker-input.component";
import {NewButtonMSDiscountComponent} from "@components/button/ms-discounts/table-ms/new-button-m-s-discount.component";
import {SellerTypesRendererComponent} from "@components/table-column-renderer/seller-types-renderer.component";
import {
  DialogUploadMultipleDocumentComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-upload-multiple-document/dialog-upload-multiple-document.component";
import {
  DocumentMultipleFormComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-upload-multiple-document/document-multiple-form.component";
import {ProgressBarComponent} from "@components/progress-bar/progress-bar.component";
import {PracticeDeletedComponent} from "@pages/imports/practice-deleted/practice-deleted.component";
import {
  FinancialDataAnalysisComponent
} from "@pages/practices-management/practices/practice-details/financial-data-analysis/financial-data-analysis.component";
import {
  CashMovementsAnalisisComponent
} from "@pages/cash-management/cash-movements-analisis/cash-movements-analisis.component";

registerLocaleData(localeIt, 'it-IT');

export let InjectorInstance: Injector;
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: " €",
  thousands: "."
};

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesComponent,
    NotificationsComponent,
    ButtonComponent,
    UserComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    LanguageComponent,
    PrivacyPolicyComponent,
    MenuItemComponent,
    DropdownComponent,
    DropdownMenuComponent,
    ControlSidebarComponent,
    SelectComponent,
    ProgressBarComponent,
    CheckboxComponent,
    UsersComponent,
    ButtonDetailUserComponent,
    ButtonDeleteUserComponent,
    ButtonEnabledUserComponent,
    ButtonNewUserComponent,
    ButtonLogoutComponent,
    UserDetailsComponent,
    DialogPopUpComponent,
    DatePickerComponent,
    DynamicFormComponent,
    TextInputComponent,
    DropdownComponentForm,
    LabelComponent,
    ControlComponent,
    PasswordComponent,
    TelephoneComponent,
    EmailComponent,
    UserFormDetailComponent,
    DualListComponent,
    NewUserComponent,
    UserFormNewComponent,
    BoxErrorsComponent,
    CompanyComponent,
    AlertComponent,
    SiNoRendererComponent,
    BrandRendererComponent,
    BrandNewRendererComponent,
    StockAutorizzatoRendererComponent,
    StatoUsatoRendererComponent,
    SubagentAgencyUsersRendererComponent,
    PracticesComponent,
    ButtonDetailSubAgentComponent,
    ButtonDeleteSubAgentComponent,
    ButtonDeleteActionPracticeComponent,
    ButtonDetailActionPracticeComponent,
    ButtonNewActionPracticeComponent,
    ButtonDetailExchangeComponent,
    ButtonDeleteDocumentComponent,
    ButtonDownloadDocumentComponent,
    ButtonDeleteExchangeComponent,
    NewPracticeComponent,
    NewPracticeFormComponent,
    SlideToggleComponent,
    DateInputComponent,
    YearDatePickerInputComponent,
    MonthDatePickerInputComponent,
    RepeaterInputsComponent,
    NumberInputComponent,
    TextAreaComponent,
    AutoCompleteComponent,
    StockComponent,
    UsedVehicleComponent,
    HiddenInputComponent,
    PracticeDetailsComponent,
    DetailPracticeFormComponent,
    DeliveryCardboardFormComponent,
    PracticeDetailsComponent,
    BrandComponent,
    InsuranceServiceTypeRendererComponent,
    ActionPracticesComponent,
    BigBuyersComponent,
    InsuranceServiceTypeComponent,
    RentalPracticesComponent,
    SellerComponent,
    ServicePracticesComponent,
    RentalPracticesRendererComponent,
    RentalAgencyComponent,
    CustomerTypeRendererComponent,
    AutoCompleteStringComponent,
    AutoCompleteActionCodesComponent,
    AutoCompleteLoyaltyServicesComponent,
    AutoCompleteFinancialServicesComponent,
    DialogNewExchangeComponent,
    ExchangeFormComponent,
    DialogDetailExchangeComponent,
    DialogUploadDocumentComponent,
    DialogUploadMultipleDocumentComponent,
    DocumentFormComponent,
    DocumentMultipleFormComponent,
    UploadInputComponent,
    SubagentAgencyComponent,
    ButtonCreateNewSubAgentComponent,
    DialogSubAgentComponent,
    SubAgentFormComponent,
    ButtonModifySubAgentComponent,
    VehicleAccessoryComponent,
    DialogActionPracticeComponent,
    ActionPracticeFormComponent,
    DialogServicePracticeComponent,
    ServicePracticeFormComponent,
    ButtonDeleteServicePracticeComponent,
    ButtonDetailServicePracticeComponent,
    ButtonNewServicePracticeComponent,
    AutoCompleteTypeInsuranceServiceComponent,
    DialogBrandComponent,
    BrandFormComponent,
    ButtonDeleteBrandComponent,
    ButtonDetailBrandComponent,
    ButtonNewBrandComponent,
    ButtonDetailsComponent,
    DialogSellerComponent,
    SellerFormComponent,
    ButtonDeleteSellerComponent,
    ButtonNewSellerComponent,
    ButtonDetailSellerComponent,
    SellerRendererComponent,
    SellerTypesRendererComponent,
    DialogBigBuyersComponent,
    BigBuyersFormComponent,
    ButtonDeleteBigBuyersComponent,
    ButtonDetailBigBuyersComponent,
    ButtonNewBigBuyersComponent,
    DialogRentalAgencyComponent,
    ButtonDeleteRentalAgencyComponent,
    ButtonNewRentalAgencyComponent,
    ButtonDetailRentalAgencyComponent,
    RentalAgencyFormComponent,
    DialogVehicleAccessoryComponent,
    VehicleAccessoryFormComponent,
    ButtonDeleteVehicleAccessoryComponent,
    ButtonNewVehicleAccessoryComponent,
    ButtonDetailVehicleAccessoryComponent,
    PercentageInputComponent,
    DialogInsuranceServiceTypeComponent,
    InsuranceServiceTypeFormComponent,
    ButtonDeleteInsuranceServiceTypeComponent,
    ButtonDetailInsuranceServiceTypeComponent,
    ButtonNewInsuranceServiceTypeComponent,
    DialogRentalPracticesComponent,
    RentalPracticesFormComponent,
    StatusBarComponent,
    ButtonDeleteRentalPracticeComponent,
    ButtonNewRentalPracticeComponent,
    ButtonDetailRentalPracticeComponent,
    ModelBrandRendererComponent,
    AutoCompleteModelComponent,
    ModelsComponent,
    DialogModelsComponent,
    ModelsFormComponent,
    ButtonNewModelsComponent,
    ButtonDeleteModelsComponent,
    ButtonDetailModelsComponent,
    AutoCompleteBrandComponent,
    DialogNewAccessoryPracticeComponent,
    AccessoryPracticeFormComponent,
    ButtonDeletePracticeAccessoryComponent,
    ButtonDetailPracticeAccessoryComponent,
    TableHeaderComponent,
    AccountingPracticeFormComponent,
    DialogDuplicatePracticeComponent,
    DuplicatePracticeFormComponent,
    CurrencyInputDirective,
    SubagentAgencyRendererComponent,
    AutoCompleteSubagentAgencyComponent,
    NumberInputPipe,
    CashMovementsComponent,
    MenuBiConfigComponent,
    MenuBiComponent,
    ButtonOpenLinkMenuBiComponent,
    DialogLoadingComponent,
    ButtonNewPracticeComponent,
    NewTableComponent,
    DecimalIntPipe,
    ButtonDeleteComponent,
    ColumnsNamePipe,
    ButtonNewMenuBiComponent,
    DialogMenuBiConfigComponent,
    MenuBiConfigFormComponent,
    ButtonDeleteMenuBIComponent,
    ButtonDetailMenuBIComponent,
    AreaBiRendererComponent,
    NewDropdownComponent,
    ControlValueAccessorDirective,
    DialogNewCommunicationPracticeComponent,
    DialogDetailCommunicationPracticeComponent,
    ComunicationPracticeFormComponent,
    ButtonDeletePracticeCommunicationComponent,
    ButtonDetailPracticeCommunicationComponent,
    DialogDetailAccessoryPracticeComponent,
    ButtonNewCashMovementsComponent,
    DeliveryCardboardManagementComponent,
    DialogDeliveryCardboardComponent,
    NewCashMovementsComponent,
    NewCashMovementsFormComponent,
    InlineControlComponent,
    ParseCheckboxPipe,
    AutoCompletePaymentTypeComponent,
    AutoCompleteContactSourceComponent,
    AutoCompleteWarrantyComponent,
    WarrantyFormComponent,
    DialogWarrantyComponent,
    WarrantyComponent,
    PaymentTypeFormComponent,
    DialogPaymentTypeComponent,
    PaymentTypeComponent,
    ContactSourceFormComponent,
    DialogContactSourceComponent,
    ContactSourceComponent,
    ButtonNewContactSourceComponent,
    ButtonDeleteContactSourceComponent,
    ButtonDetailContactSourceComponent,
    ButtonNewWarrantyComponent,
    ButtonDeleteWarrantyComponent,
    ButtonDetailWarrantyComponent,
    ButtonNewPaymentTypeComponent,
    ButtonDetailPaymentTypeComponent,
    ButtonDetailPaymentTypeComponent,
    ButtonDeletePaymentTypeComponent,
    BrandGoalsComponent,
    DialogBrandGoalsComponent,
    DialogMSUpdateComponent,
    BrandGoalsFormComponent,
    ButtonDetailBrandGoalsComponent,
    ButtonDownloadExchangeComponent,
    TableDeliveryCartonComponent,
    UsedVehicleDetailComponent,
    UsedVehicleDetailFormComponent,
    BusinessMachinesComponent,
    PracticeDeletedComponent,
    SellersUsersRendererComponent,
    BigbuyerBrandGoalsRendererComponent,
    DetailPracticeVehicleFormComponent,
    CurrencyInputComponent,
    DataVehicleNotMatchedFormComponent,
    BackButtonComponent,
    SlideToggleUserComponent,
    BankAccountComponent,
    ButtonDeleteBankAccountComponent,
    ButtonDetailBankAccountComponent,
    ButtonNewBankAccountComponent,
    BankAccountFormComponent,
    DialogBankAccountComponent,
    ButtonRecalculateProgressionMonthComponent,
    ButtonExportExcelDeliveryCardBoardComponent,
    AutoCompleteBankPaymentComponent,
    BiggerDiscountComponent,
    FormUploadCircularComponent,
    TableBiggerDiscountComponent,
    ImportStatusTableComponent,
    CrossF7TableComponent,
    CrossF8TableComponent,
    VwfsDashboardTableComponent,
    VwfsCommissionTableComponent,
    AccountingTabComponentComponent,
    DynamicTableComponent,
    LoyaltyTableComponent,
    VwbankCommissionTableComponent,
    TableMSComponent,
    NewButtonMSDiscountComponent,
    MSUpdatesFormComponent,
    FinancialDataAnalysisComponent,
    CashMovementsAnalisisComponent
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AgGridModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    Ng2SmartTableModule,
    MatTabsModule,
    MatIconModule,
    MatDialogModule,
    FontAwesomeModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    FormsModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    AngularDualListBoxModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    CurrencyMaskModule,
    MatExpansionModule,
  ],
  providers: [
    {provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    NumberInputPipe,
    UserService,
    LoggerService,
    MatDatepickerModule,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'it-IT'},
    Registry
  ],
  bootstrap: [AppComponent],
  exports: [
    AccountingPracticeFormComponent
  ]
})
export class AppModule {
  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
}
