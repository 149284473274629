import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {LoggerService} from "@services/logger.service";
import {User} from "@/models/user.model";

@Component({
  selector: 'app-new',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  error: string;
  submitted = false;
  meta!: Array<any>
  data: any = {}
  username: string

  constructor(private router: Router, private route: ActivatedRoute, private userService: UserService, private logger: LoggerService) {
    this.route.params.subscribe((params: Params) => {
      let username = params['username'];
      if (params['username'] === 'new') {
        username = ''
      }
      this.username = username;
    });
  }

  async ngOnInit() {
    this.data = new User();
  }

  protected create() {
    this.router.navigate(['admin/user/new']);
  }

}
