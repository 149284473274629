import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {InsuranceServiceTypeModel} from "@/models/tables/insurance-service-type.model";

@Injectable({
  providedIn: 'root'
})
export class InsuranceServicesTypeService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/insuranceServiceType`);
  }

  deleteInsuranceServiceType(insuranceServiceTypeId: number) {
    return this.http.delete(this.baseUrl + `/tables/insuranceServiceType/${insuranceServiceTypeId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteInsuranceServicesType')

      return response;
    }));
  }

  saveInsuranceServiceType(insuranceServiceType: InsuranceServiceTypeModel) {
    return this.http.post(this.baseUrl + `/tables/insuranceServiceType/save`, insuranceServiceType).pipe(map((response: any) => {
      LoggerService.log(response, 'saveInsuranceServicesType')

      return response;
    }));
  }
}
