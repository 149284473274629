import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";

@Injectable({
  providedIn: 'root'
})
export class MenuBiService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/menuBi`);
  }

  getAreas(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/menuBi/areas`);
  }
}
