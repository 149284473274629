import {AppState} from '@/store/state';
import {ToggleControlSidebar, ToggleSidebarMenu} from '@/store/ui/actions';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AuthService} from '@services/auth.service';
import {UserService} from "@services/admin/user.service";
import {environment} from "../../../../environments/environment";

const BASE_CLASSES = 'main-header navbar navbar-expand';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public searchForm: UntypedFormGroup;
  public linkDocumentation: string;
  public environment: string = environment.env;

  constructor(private authService: AuthService, private store: Store<AppState>, private userService: UserService) {
  }

  ngOnInit() {
    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES}`;
    });
    this.searchForm = new UntypedFormGroup({
      search: new UntypedFormControl(null),
    });
    this.getLinkDocumentation()
  }

  logout() {
    this.authService.logout();
  }

  onToggleMenuSidebar() {
    this.store.dispatch(new ToggleSidebarMenu());
  }

  onToggleControlSidebar() {
    this.store.dispatch(new ToggleControlSidebar());
  }

  private getLinkDocumentation(): void {
    const userRoles = this.userService.getUserRoles();

    switch (true) {
      case userRoles.includes('ROLE_ADMINISTRATOR'):
        this.linkDocumentation = 'https://informa-crespi.notion.site/Documentazione-Amministratore-579f0f85d0764ba3a52d98deb1ae0f68?pvs=4';
        break;
      case userRoles.includes('ROLE_CONTABILITA'):
        this.linkDocumentation = 'https://informa-crespi.notion.site/Documentazione-Contabilit-4256938b35074767862fd7d84b06942c?pvs=74';
        break;
      case userRoles.includes('ROLE_RESPONSABILE_VENDITORE'):
        this.linkDocumentation = 'https://informa-crespi.notion.site/Documentazione-Responsabile-Venditore-e0c203e7cfa040758bfecf6ad9f64783?pvs=74';
        break;
      case userRoles.includes('ROLE_BACKOFFICE'):
        this.linkDocumentation = 'https://informa-crespi.notion.site/Documentazione-Backoffice-8e05bf135b3346789a33bfaf4412ff94?pvs=4';
        break;
      case userRoles.includes('ROLE_MAGAZZINO'):
        this.linkDocumentation = 'https://informa-crespi.notion.site/Documentazione-Magazzino-b5b302ea541c4524ba5d2cbf3018b322?pvs=4';
        break;
      case userRoles.includes('ROLE_OFFICINA'):
        this.linkDocumentation = 'https://informa-crespi.notion.site/Documentazione-Officina-510eefceacb44d18847205602a6effb1?pvs=4';
        break;
      case userRoles.includes('ROLE_RESPONSABILE_MAGAZZINO'):
        this.linkDocumentation = 'https://informa-crespi.notion.site/Documentazione-Responsabile-Magazzino-6d4d4342a4a446b6ab78bc7969c9d872?pvs=4';
        break;
      case userRoles.includes('ROLE_RESPONSABILE_OFFICINA'):
        this.linkDocumentation = 'https://informa-crespi.notion.site/Documentazione-Responsabile-Officina-5d292b51b21747e09f9474b947a9f474?pvs=4';
        break;
      case userRoles.includes('ROLE_RESPONSABILE_SUBAGENTE'):
        this.linkDocumentation = 'https://informa-crespi.notion.site/Documentazione-Responsabile-Subagente-acf2f59acbce4dc49bbb56a2965ee2e6?pvs=4';
        break;
      case userRoles.includes('ROLE_GESTORE_TABELLE'):
        this.linkDocumentation = 'https://informa-crespi.notion.site/Documentazione-Gestore-tabelle-a1e0aafc195741d8bc8ccd9deb5b61f4?pvs=4';
        break;
      case userRoles.includes('ROLE_SUBAGENTE'):
        this.linkDocumentation = 'https://informa-crespi.notion.site/Documentazione-Subagente-ddfb555b7d52456a97dea8686594b9a6?pvs=4';
        break;
      case userRoles.includes('ROLE_VENDITORE'):
        this.linkDocumentation = 'https://informa-crespi.notion.site/Documentazione-Venditore-bae484cfe9f2495b9a3a1955025fe330?pvs=4';
        break;
      default:
        this.linkDocumentation = '';
        break;
    }
  }
}
