import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-sub-agent',
  templateUrl: './dialog-sub-agent.component.html',
})
export class DialogSubAgentComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }
}
