<div class="p-4 flex flex-wrap justify-center items-center gap-4 sm:justify-between">
    <label class="text-lg font-bold m-0">{{ tableTitle }}</label>
    <div class="inline-flex gap-2">
        <button class="btn btn-primary inline-grid items-center" (click)="btnUpdateClick.emit($event)">
            <i class="fa fa-refresh"></i>
            Aggiorna
        </button>
        <ng-content select="[action-button]"></ng-content>
    </div>
</div>
