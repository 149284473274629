import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map, tap} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {TableDataApiParams} from "@services/practices.service";
import {TablePaginationRespModel} from "@/models/table-pagination-resp.model";
import {TableRespModel, TableStockModel} from "@/models/tables/stock-table.model";

@Injectable({
    providedIn: 'root'
})
export class StockService extends ApiService {

    getTableData(params?: TableDataApiParams): Observable<TablePaginationRespModel<TableStockModel>> {
        return this.http.get<TablePaginationRespModel<TableRespModel>>(this.baseUrl + `/stock/page`, {
            params: {...params}
        }).pipe(
            map(({content, ...rest}) => ({
                ...rest,
                content: content.map(({marca, stockAutorizzato, ...other}) => ({
                    ...other,
                    marca: marca?.descrizioneMarca,
                    stockAutorizzato: stockAutorizzato ? 'si' : 'no'
                })),
            })),
            tap(r => LoggerService.log(r, 'stock page'))
        )
    }

    getAll(): Observable<any> {
        return this.http.get(this.baseUrl + `/stock/page`).pipe(
            tap(r => LoggerService.log(r, 'stock page'))
        )
    }

    getAllBrands(): Observable<any> {
        return this.http.get(this.baseUrl + `/stock/brands`).pipe(map((response: any) => {
            LoggerService.log(response, 'usedVehicleBrands')
            return response;
        }));
    }

    updateSubagentVisibility(stockId: number, visibility: boolean): Observable<any> {
        return this.http.post<any>(this.baseUrl + `/stock/updateSubagentVisibility/${stockId}`, {'visibility': visibility})
    }

  getPracticeTypes(): Observable<any> {
    return this.http.get(this.baseUrl + '/stock/practicalTypes/').pipe(map((response: any) => {
      LoggerService.log(response, 'getPracticeTypes')
      return response;
    }));
  }
}
