import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-seller',
  templateUrl: './dialog-seller.component.html',
  styleUrls: ['./dialog-seller.component.scss']
})
export class DialogSellerComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }
}
