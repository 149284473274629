import {Injectable, isDevMode} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {

  public static log(msg: any, message: string = '') {
    if (isDevMode()) {
      console.log(message, msg);
    }
  }

  public static error(msg: any, message: string = '') {
    if (isDevMode()) {
      console.error(message, msg);
    }
  }

  public static warn(msg: any, message: string = '') {
    if (isDevMode()) {
      console.warn(message, msg);
    }
  }
}
