import {Role} from "@/models/role";
import {AbstractControl, FormControl, Validators} from "@angular/forms";

export function getCurrentUserRoles() {
  return JSON.parse(localStorage.getItem('cassa.userRoles')) as Array<Role>
}

export function isControlRequired(control: FormControl | AbstractControl) {
  return control.hasValidator(Validators.required);
}

export function isControlDisabled(control: FormControl | AbstractControl) {
  return !!control?.disabled;
}
