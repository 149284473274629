import {Component, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";

@Component({
    selector: 'app-dialog-loading',
    templateUrl: './dialog-loading.component.html',
    styleUrls: ['./dialog-loading.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogLoadingComponent {
    constructor(public dialogRef: MatDialogRef<DialogPopUpComponent>) {
    }
}
