<div
  *ngIf="control.invalid &&  registry.get('submittedForm')"
  class="errors-box">
  <div *ngIf="control.errors?.['required']">
    Il campo {{meta.label}} è obbligatorio!
  </div>
  <div *ngIf="control.errors?.['minlength']">
    Il campo {{meta.label}} deve essere di almeno
  </div>
  <div *ngIf="control.errors?.['additionalValidator']">
    {{control.errors?.['message']}}
  </div>
  <div *ngIf="control.errors?.['pattern']">
    Il campo {{meta.label}} non è valido!
  </div>
</div>
