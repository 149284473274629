import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {ControlValueAccessorDirective} from "@/directives/control-value-accessor.directive";
import {Subject} from "rxjs";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-new-dropdown',
  templateUrl: './new-dropdown.component.html',
  styleUrls: ['./new-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewDropdownComponent),
      multi: true
    }
  ]
})
export class NewDropdownComponent<T> extends ControlValueAccessorDirective<T> implements OnInit {
  @Input() multi: boolean = false;
  @Input() meta!: any;
  @Input() triggerSelection$: Subject<any>
  @Input() triggerUpdateComponent$ = new Subject<any>();

  ngOnInit() {
    // Set the default value here. Assume 'meta.options[0].key' is the default value.
    //this.control = new FormControl(this.meta.options[0].key);
    this.triggerUpdateComponent$.subscribe({
      next: () => {
        const brandDefault = this.meta.options.find(obj => obj.value === 'Volkswagen')

        this.setupDefaultValue(brandDefault);
      }
    });
  }


  setupDefaultValue(defaultOption): void {
    if (defaultOption) {
      this.control.setValue(defaultOption.key);
    }
  }

  onSelectionChange($event: MatSelectChange) {
    this.triggerSelection$.next({
        'brandId': $event.value,
      }
    )
  }
}
