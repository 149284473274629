import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ControlBase} from "@components/dynamic-form/control-base";
import {Subject} from "rxjs";

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss']
})
export class ControlComponent implements OnInit {
  @Input() control!: ControlBase
  @Input() form!: FormGroup
  @Input() source = [];
  @Input() destination = [];
  @Input() format: any;
  @Input() triggerMethodSubject!: Subject<any>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
