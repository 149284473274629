import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SubAgent} from "@/models/sub-agent";
import {
  DialogSubAgentComponent
} from "@pages/tables/subagent-agency/modals/dialog-sub-agent/dialog-sub-agent.component";
import {Registry} from "@/registry.service";

@Component({
  selector: 'app-button-create-new-sub-agent',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonCreateNewSubAgentComponent extends ButtonComponent {
  private dialogOpened: MatDialogRef<any>

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'py-0.5 px-2.5 ml-3'
    this.content = 'Nuova agenzia Sub-Agente'
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    const subAgent = new SubAgent();

    this.dialogOpened = this.dialog.open(DialogSubAgentComponent, {
      data: subAgent,
    });

    Registry.getInstance().set('DialogSubAgentComponentNew', this.dialogOpened)
  }

}
