import {TableColumnsSetting} from "@/interfaces/table-setting";
import {of} from "rxjs";
import {CashMovementsTableModel} from "@/models/tables/cash-movements-table.model";
import {map} from "rxjs/operators";
import {CashMovementsService} from "@services/cash-movements.service";

export class CashMovementsSettings {
  public static getColumnsSetting(cashMovementsService: CashMovementsService): TableColumnsSetting<keyof CashMovementsTableModel> {
    return [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "btnDelete",
        customComponent: true,
        unsortable: true,
      },
      {
        name: 'id',
        text: 'Id',
        filter: {
          type: 'input'
        }
      },
      {
        name: 'reparto',
        text: 'reparto',
        filter: {
          type: "select",
          options: cashMovementsService.getDepartments().pipe(map(departments => departments.map((department) => ({
            [department]: department
          }))))
        }
      },
      {
        name: 'dataMovimento',
        type: "date",
        text: 'data movimento',
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: 'numeroPratica',
        text: 'Numero pratica',
        filter: {
          type: 'input'
        }
      },
      {
        name: 'tipoMovimento',
        text: 'tipo movimento',
        filter: {
          type: "select",
          options: cashMovementsService.getMovementTypes().pipe(map(movementTypes => movementTypes.map((movementType) => ({
            [movementType]: movementType
          }))))
        }
      },
      {
        name: "codice",
        text: 'codice cliente',
        filter: {
          type: 'input'
        }
      },
      {
        name: "descrizioneMovimento",
        text: "descrizione",
        filter: {
          type: "input"
        }
      },
      {
        name: "fatturaRicevuta",
        text: "fat/ric",
        filter: {
          type: "input"
        }
      },
      {
        name: "importo",
        text: "importo",
        type: "currency",
        filter: {
          type: "number"
        }
      },
      {
        name: "tipoPagamento",
        text: "tipo pag.",
        filter: {
          type: "select",
          options: of([
            {
              CONTANTI: 'CONTANTI',
            },
            {
              BONIFICO: 'BONIFICO',
            },
            {
              ASSEGNO: 'ASSEGNO',
            },
            {
              POS: 'POS',
            },
            {
              CONTO_MEKKANO: 'CONTO MEKKANO'
            }
          ])
        }
      },
      {
        name: "numeroAssegno",
        text: "assegno",
        filter: {
          type: "input"
        }
      },
      {
        name: "banca",
        text: "banca",
        filter: {
          type: "input"
        }
      },
      {
        name: "targa",
        text: "targa",
        filter: {
          type: "input"
        }
      },
      {
        name: "note",
        text: "note",
        filter: {
          type: "input"
        }
      },
      {
        name: "creator",
        text: "creato da",
        filter: {
          type: "input"
        }
      },
      {
        name: "created",
        type: "datetime",
        text: "creato il",
        filter: {
          type: 'dateRange'
        }
      },
    ]
  }
}
