export class PaymentTypeModel {
  public id: string;
  public tipoVeicolo: string;
  public descrizione: string;
  public enabled: boolean;
  public creator: string;
  public created: Date;
  public modifier: string;
  public modified: Date;
}
