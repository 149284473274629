import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {MenuBiService} from "@services/menu-bi.service";
import {MenuBiSettings} from "@/table-settings/menu-bi-settings";
import {LoggerService} from "@services/logger.service";

@Component({
  selector: 'app-menu-bi',
  templateUrl: './menu-bi.component.html',
  styleUrls: ['./menu-bi.component.scss']
})
export class MenuBiComponent {

  source: LocalDataSource = new LocalDataSource([]);
  settings = MenuBiSettings.gridSettings();

  constructor(private menuBiService: MenuBiService) {
    this.loadMenuBi();

    Registry.getInstance().set('MenuBiComponent', this)
  }

  loadMenuBi() {
    this.menuBiService.getAll().subscribe((menuBis) => {
      LoggerService.log(menuBis, 'menuBis')
      this.source.load(menuBis);
    });

    this.menuBiService.getAreas().subscribe(areas => {
      const options = [];
      for (const area of areas) {
        options.push({
          value: area,
          title: area
        });
      }

      this.settings.columns.areaBi.filter.config.list = options;
      this.settings = Object.assign({}, this.settings);
    });
  }
}
