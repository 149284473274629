<div [formGroup]="form" class="daul-list">
  <dual-list
    [key]="meta.key"
    [display]="meta.label"
    [source]="meta.source"
    [(destination)]="meta.destination"
    [format]="meta.format"
    (destinationChange)="destinationChange($event)"
    height="200">
  </dual-list>
</div>
