import {Component, Input} from '@angular/core';
import {AutocompleteControl} from "@components/dynamic-form/auto-complete/autocomplete-control";
import {FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {AutoCompleteComponent} from "@components/dynamic-form/auto-complete/auto-complete.component";

@Component({
  selector: 'app-auto-complete-string',
  templateUrl: './auto-complete-string.component.html',
  styleUrls: ['./auto-complete-string.component.scss']
})
export class AutoCompleteStringComponent extends AutoCompleteComponent {
  @Input() meta!: AutocompleteControl
  @Input() form!: FormGroup
  @Input() indexRow: number = null
  @Input() keyformArrayName: any
  control = new FormControl('')
  filteredOptions: Observable<string[]>

  ngOnInit(): void {
    this.setControl()

    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  protected _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.meta.options.filter(function (option) {
      return option.toLowerCase().includes(filterValue)
    });
  }

}
