<div class="dialog-popup">
  <h1 [class]="classTitle" mat-dialog-title>{{title}}</h1>
  <div [class]="classContent" mat-dialog-content>
    {{content}}
  </div>
  <div class="buttons-box">
    <app-button *ngIf="firstCTA !== null" [mat-dialog-close]="closeResultFirst" [content]="firstCTA"></app-button>
    <app-button *ngIf="firstCTA !== null" [mat-dialog-close]="closeResultSecond" [color]="'secondary'"
                [content]="secondCTA"></app-button>
  </div>
</div>
