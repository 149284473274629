import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'app-dialog-duplicate-practice',
    templateUrl: './dialog-duplicate-practice.component.html',
})
export class DialogDuplicatePracticeComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data) {
    }

}
