import {Component} from '@angular/core';
import {ButtonComponent} from '@components/button/button.component';
import {Router} from "@angular/router";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {MatDialog} from "@angular/material/dialog";
import {
    DialogDetailExchangeComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-detail-exchange/dialog-detail-exchange.component";
import {Registry} from "@/registry.service";
import {ToastrService} from "ngx-toastr";
import {UsedVehicleModel} from "@/models/usedVehicleModel";

@Component({
    selector: 'app-button-exchange-detail',
    templateUrl: '../button.component.html',
    styleUrls: ['../button.component.scss'],
})
export class ButtonDetailExchangeComponent extends ButtonComponent {
    private exchange: UsedVehicleModel;
    private rowData: any;
    faIcon = faPenToSquare;

    constructor(protected router: Router, private dialog: MatDialog, protected toastr: ToastrService) {
        super(router, toastr);

        this.showIcon = true
        this.additionalClass = 'py-0.5 px-2.5'
        this.smallBtn = true;
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnInit() {
        this.exchange = <UsedVehicleModel>this.rowData;
    }

    /**
     * Open practice detail page
     */
    public actionClick(): void {
        const dialogDetail = this.dialog.open(DialogDetailExchangeComponent, {
            data: this.exchange,
        });

        Registry.getInstance().set('DialogDetailExchangeComponent', dialogDetail)
    }
}
