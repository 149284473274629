import {FormSetting} from "@/form-settings/form-setting";

export class VehicleDataPracticeForm extends FormSetting {

  protected static classToCall: string

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static getMeta(): Array<any> {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "annoModello",
            "controlType": "textinput",
            "label": "Anno modello",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "tipoAlimentazione",
            "controlType": "textinput",
            "label": "Tipo alimentazione",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "cilindrata",
            "controlType": "number",
            "label": "Cilindrata",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "potenzaCV",
            "controlType": "number",
            "label": "Potenza CV",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "potenzaKW",
            "controlType": "number",
            "label": "Potenza KW",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "dataPrimaImmatricolazione",
            "controlType": "date",
            "label": "Data prima immatricolazione",
            "order": 1,
            "class": "lg:w-4/12 w-full",
            "disabled": true
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "prezzoVenditaListinoLordo",
            "controlType": "number",
            "label": "Prezzo vendita listino lordo",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "prezzoVenditaListinoNetto",
            "controlType": "number",
            "label": "Prezzo vendita listino netto",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "prezzoColoreNetto",
            "controlType": "number",
            "label": "Prezzo colore netto",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "prezzoColoreLordo",
            "controlType": "number",
            "label": "Prezzo colore lordo",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "disabled": true
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "prezzoTotaleEquipaggiamentiLordo",
            "controlType": "number",
            "label": "Prezzo totale equipaggiamenti lordo",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "prezzoTotaleEquipaggiamentiNetto",
            "controlType": "number",
            "label": "Prezzo totale equipaggiamenti netto",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "prezzoTotaleVeicoloLordo",
            "controlType": "number",
            "label": "Prezzo totale lordo",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "prezzoTotaleVeicoloNetto",
            "controlType": "number",
            "label": "Prezzo totale netto",
            "order": 1,
            "class": "lg:w-3/12 w-full",
            "disabled": true
          }
        ],
        "order": 1
      }
    ]
  }
}
