import {Component} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {ModelsService} from "@services/tables/models-service";
import {ModelsFormSetting} from "@/form-settings/models-form-setting";
import {ModelsModel} from "@/models/tables/models.model";

@Component({
  selector: 'app-models-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class ModelsFormComponent extends DynamicFormComponent {
  private brands: Array<any> = [];

  constructor(protected router: Router, protected userService: UserService, protected modelsService: ModelsService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'

    this.modelsService.getAllBrands().subscribe(brands => {
      this.brands = brands

      this.setupMeta()
    });
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())
  }

  onSubmit() {
    let modelsModel = <ModelsModel>this.form.getRawValue()

    this.modelsService.saveModel(modelsModel).subscribe({
      next: () => {
        this.toastr.success('Sigla modello salvata correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveModels failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio della Sigla modello.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected setupMeta() {
    this.meta = ModelsFormSetting
      .set('brands', this.brands)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogModelsComponentModify').close()
    } else {
      Registry.getInstance().get('DialogModelsComponentNew').close()
    }

    Registry.getInstance().get('ModelsComponent').loadModels()
  }

  protected secondaryButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('DialogModelsComponentModify').close()
    } else {
      Registry.getInstance().get('DialogModelsComponentNew').close()
    }
  }

}
