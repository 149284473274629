import {Component, inject} from '@angular/core';
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import {BusinessMachinesTableSettings} from "@/table-settings/practices/business-machines-table.settings";
import {BusinessMachinesService} from "@services/business-machines.service";
import {BusinessMachinesDataSource} from "@components/new-table/data-sources/business-machines.dataSource";

@Component({
    selector: 'app-practices',
    templateUrl: './business-machines.component.html',
    styleUrls: ['./business-machines.component.scss']
})
export class BusinessMachinesComponent {

    displayedColumns = BusinessMachinesTableSettings.getColumnsSetting(this.businessMachinesService);

    dataSource = new BusinessMachinesDataSource(this.businessMachinesService);

    toastr = inject(ToastrService);
    dialog = inject(MatDialog);

    reloadTrigger$ = new Subject<any>();

    constructor(private businessMachinesService: BusinessMachinesService, private router: Router) {
        Sentry.captureMessage("Page business machines list");
    }

    btnDetailsClickFunction({id}: TablePracticeModel) {
        this.router.navigate([`/practices-management/practices/details/${id}`])
    }
}
