import {Component} from '@angular/core';
import {AutoCompleteComponent} from "@components/dynamic-form/auto-complete/auto-complete.component";

@Component({
  selector: 'app-auto-complete-financial-services',
  templateUrl: '../../auto-complete.component.html',
  styleUrls: ['../../auto-complete.component.scss']
})
export class AutoCompleteFinancialServicesComponent extends AutoCompleteComponent {

  protected getValue() {
    return 'descrizione';
  }

  displayFn(selectedoption: any): string {
    return selectedoption && selectedoption.descrizione ? selectedoption.descrizione : '';
  }
}
