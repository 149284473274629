import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {BankAccountModel} from "@/models/tables/bank-account.model";

@Injectable({
  providedIn: 'root'
})
export class BankAccountService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/bankAccount`);
  }

  deleteBankAccount(bankAccountId: number) {
    return this.http.delete(this.baseUrl + `/tables/bankAccount/${bankAccountId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteBankAccount')

      return response;
    }));
  }

  saveBankAccount(bankAccount: BankAccountModel) {
    return this.http.post(this.baseUrl + `/tables/bankAccount/save`, bankAccount).pipe(map((response: any) => {
      LoggerService.log(response, 'saveBankAccount')

      return response;
    }));
  }
}
