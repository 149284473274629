import {Injectable} from '@angular/core';
import {ApiService} from "@services/api.service";
import {Observable} from "rxjs";
import {User} from "@/models/user.model";

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends ApiService {

  getProfile(): Observable<User> {
    return this.http.get<User>(this.baseUrl + '/admin/profile/userInfo', {headers: this.headers});
  }
}
