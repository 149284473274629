import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {WarrantyModel} from "@/models/tables/warranty.model";
import {WarrantyService} from "@services/tables/warranty.service";
import {DialogWarrantyComponent} from "@pages/tables/warranty/dialog-warranty/dialog-warranty.component";

@Component({
  selector: 'app-button-detail-warranty',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDetailWarrantyComponent extends ButtonComponent {
  private dialogOpened: MatDialogRef<any>
  private warrantyModel: WarrantyModel;
  private rowData: any;
  faIcon = faPenToSquare;

  constructor(protected router: Router, protected toastr: ToastrService, protected warrantyService: WarrantyService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio Garanzia'
    this.smallBtn = true;
  }

  ngOnInit() {
    this.warrantyModel = <WarrantyModel>this.rowData;
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogWarrantyComponent, {
      data: this.warrantyModel,
    });

    Registry.getInstance().set('DialogWarrantyComponentModify', this.dialogOpened)
  }

}
