import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Component} from "@angular/core";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {UsedVehicleService} from "@services/used-vehicle.service";
import {ToastrService} from "ngx-toastr";
import {UsedVehicleModel} from "@/models/usedVehicleModel";
import {UsedVehicleFormSetting} from "@/form-settings/used-vehicle-form-setting";
import {OptionSelect} from "@/models/option-select.model";
import {BrandModel} from "@/models/tables/brand.model";

@Component({
  selector: 'app-used-vehicle-form-detail',
  templateUrl: '../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class UsedVehicleDetailFormComponent extends DynamicFormComponent {

  private usedVehicleId: any;
  protected brands: OptionSelect[] = [];

  constructor(
    private route: ActivatedRoute,
    protected toastr: ToastrService,
    protected router: Router,
    protected userService: UserService,
    protected usedVehicleService: UsedVehicleService,
  ) {
    super(toastr, userService)
    this.data = {} as UsedVehicleModel
    this.primaryButton = `Salva veicolo usato`
    this.route.params.subscribe((params: Params) => {
      this.usedVehicleId = params['id'];
    })

    this.setupMeta()

    this.usedVehicleService.getUsedVehicleById(this.usedVehicleId).subscribe({
      next: (usedVehicle) => {
        this.data = <UsedVehicleModel>usedVehicle

        this.getBrands()
      },
      error: (error) => {
        LoggerService.error('getUsedVehicleById failed!');
        LoggerService.error(error);
      },
      complete: () => LoggerService.log('Request done'),
    });

    Registry.getInstance().set('UsedVehicleDetailForm', this)
  }

  private getBrands() {
    this.usedVehicleService.getBrands().subscribe((brands: BrandModel[]) => {
      this.brands = []

      for (let brand of brands) {
        this.brands.push({
          'key': brand,
          'value': brand.codiceMarca + ' - ' + brand.descrizioneMarca
        })
      }

      this.refreshForm()
    })
  }

  protected setupMeta() {
    this.meta = UsedVehicleFormSetting
      .setClassToCall('ExchangeFormComponent')
      .setBrands(this.brands)
      .activeClient()
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  onSubmit() {
    let usedVehicle = <UsedVehicleModel>this.form.getRawValue()

    this.usedVehicleService.saveUsedVehicle(usedVehicle).subscribe({
      next: (response) => {
        this.toastr.success('Veicolo Usato salvato correttamente!');

        this.router.navigate([`used-vehicle-management`])
      },
      error: (error) => {
        LoggerService.error('saveUsedVehicle failed!');
        LoggerService.error(error);

        this.toastr.error('Errore nel salvataggio!');

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }
}
