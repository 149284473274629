import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends ApiService {

  getAllCompany(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/admin/company/`);
  }
}
