<div>
  <div class="buttons-over-form mb-3">
    <app-back-button></app-back-button>
  </div>
  <div class="container-box">
    <div class="content">
      <app-new-cash-movements-form
        [data]="data"
        [departmentSelected]="department"
      ></app-new-cash-movements-form>
    </div>
  </div>
</div>
