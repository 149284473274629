import {ButtonDetailSubAgentComponent} from "@components/button/practices/button-detail-subagent.component";
import {
  ButtonDeleteSubAgentComponent
} from "@components/button/tables/subagent-agency/button-delete-subagent.component";
import {SellersUsersRendererComponent} from "@components/table-column-renderer/sellers-users-renderer.component";

export class SubagentAgencySettings {

  public static gridSettings() {
    return {
      actions: false,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager:
        {
          display: true,
          perPage: 25
        },
      columns: {
        detailButton: {
          type: 'custom',
          renderComponent: ButtonDetailSubAgentComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        deleteButton: {
          type: 'custom',
          renderComponent: ButtonDeleteSubAgentComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        id: {
          title: 'Id',
          width: '70px'
        },
        descrizione: {
          title: 'Descrizione'
        },
        users: {
          title: 'Utenti in agenzia',
          type: 'custom',
          renderComponent: SellersUsersRendererComponent,
          width: '120px',
          filter: {
            type: 'list',
            config: {
              list: []
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            if(cell.length > 0)
              return cell.find(u => u.username === search) != null;
            else
              return false;
          },
        },
        referenti: {
          title: 'Referenti in Paolo Crespi',
          type: 'custom',
          renderComponent: SellersUsersRendererComponent,
          width: '120px',
          filter: {
            type: 'list',
            config: {
              list: []
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            if(cell.length > 0)
              return cell.find(u => u.username === search) != null;
            else
              return false;
          },
        },
        enabled: {
          title: 'Abilitato',
          width: '130px',
          valuePrepareFunction: (abilitato) => abilitato ? 'Si' : 'No',
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
      },
      delete: null
    };
  }
}
