import {InsuranceServiceTypeModel} from "./insurance-service-type.model";

export class ServicePracticesModel {
  public id: number;
  public tipoVeicolo: string;
  public tipoServizio: string;
  public tipoServizioAssicurativo: InsuranceServiceTypeModel;
  public descrizione: string;
  public note: string;
  public punteggio: number;
  public provvigioneAccessorio: number;
  public enabled: boolean;
  public creator: string;
  public created: Date;
  public modifier: string;
  public modified: Date;
}
