import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-button-new-cash-movements',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss'],
})
export class ButtonNewCashMovementsComponent extends ButtonComponent {
  constructor(protected router: Router, protected toastr: ToastrService) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'ml-2'
    this.content = 'Nuovo movimento cassa'
  }

  public actionClick(): void {
    this.router.navigate(['cash-management/cash-movements/new']);
  }

}
